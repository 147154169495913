import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './AllServices.module.scss'
import ServicesCard from './ServicesCard/ServicesCard'
import { NavLink } from 'react-router-dom'

const AllServices = props => {

    const { t, i18n } = useTranslation('common')
    const servicesRef = useRef()

    return (
        <Container fluid className={style.container}>
            <Row>
                <Col xl={2}></Col>
                <Col xl={10}>
                    <Row className={[style.cardContainer, i18n.language === "en" ? style.cardContainerEn : style.cardContainerAr].join(' ')}>
                        <Col md={3}>
                            <p className={style.title}>{t('all_services')}</p>
                            <p className={style.subtitle}>{t('serviceSubTitle')}</p>
                        </Col>
                        <Col md={9}>
                            <p className={style.para}>{t('servicePara')}</p>
                        </Col>

                        <Col md={12} className='mb-5'>
                            <ServicesCard
                                homePage={props.homePage}
                                servicesRef={servicesRef}
                            />
                        </Col>

                        <Col md={6} className='d-flex align-items-center'>
                            <p className={style.endtitle}>{t('serviceEndTitle')}</p>
                        </Col>

                        <Col md={6} className='d-flex justify-content-md-end justify-content-center'>
                            <NavLink
                                className={[
                                    style.allServicesBtn,
                                    i18n.language === 'ar' ? style.allServicesBtnAr : null
                                ].join(' ')}
                                exact
                                to='/our_services'
                            >
                                <p>{t('all_services')}</p>
                                <span>
                                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333984 5.00004C0.333984 4.5398 0.70708 4.16671 1.16732 4.16671H12.834C13.2942 4.16671 13.6673 4.5398 13.6673 5.00004C13.6673 5.46028 13.2942 5.83337 12.834 5.83337H1.16732C0.70708 5.83337 0.333984 5.46028 0.333984 5.00004Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4232 4.41079C13.7487 4.73622 13.7487 5.26386 13.4232 5.5893L10.0899 8.92263C9.76447 9.24807 9.23683 9.24807 8.9114 8.92263C8.58596 8.59719 8.58596 8.06956 8.9114 7.74412L12.2447 4.41079C12.5702 4.08535 13.0978 4.08535 13.4232 4.41079Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9114 1.07745C9.23683 0.752015 9.76447 0.752015 10.0899 1.07745L13.4232 4.41079C13.7487 4.73622 13.7487 5.26386 13.4232 5.5893C13.0978 5.91473 12.5702 5.91473 12.2447 5.5893L8.9114 2.25596C8.58596 1.93053 8.58596 1.40289 8.9114 1.07745Z" fill="white" />
                                    </svg>
                                </span>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default AllServices

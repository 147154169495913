import React, { useState, useEffect } from "react";
import Header from "./../../UIComponents/Header/Header";
import Footer from "./../../UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import UserServices from "./UserServices/UserServices";
import SearchList from "./SearchList/SearchList";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import { useSelector } from "react-redux";
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";
import 'global/style/Card.scss';

const Services = () => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();

  const [dealers, setDealers] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage, setServicesPerPage] = useState(20);
  const [searchVal, setSearchVal] = useState("");
  const [activeTabName, setActiveTabName] = useState();
  const [activeTabId, setActiveTabId] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchDealersTypes = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_DEALERS_TYPES_LIST`
      );
      setDealers(res.data.Data);
      setLoading(false);
    };
    fetchDealersTypes();
  }, []);

  useEffect(() => {
    if (dealers.length > 0) {
      if (clientProfiles?.Dealing_Type_Id) {
        getServiceList(clientProfiles?.Dealing_Type_Id);
      } else {
        getServiceList(dealers[0].Dealing_Type_Id);
      }
    }
  }, [dealers]);

  const getServiceList = (id) => {
    if (id === false) return;
    const fetchserviceList = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_SERVICES_LIST`,
        {
          Dealing_Type_Id: id,
        },
        user?.access_token
          ? {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
            },
          }
          : false
      );
      setServiceList(res.data.Data);
      setLoading(false);
    };
    fetchserviceList();
    let tab = dealers.filter((dealer) =>
      dealer.Dealing_Type_Id === id ? dealer : null
    );
    setActiveTabName(
      i18n.language === "en"
        ? tab[0].Dealing_Type_Name_EN
        : tab[0].Dealing_Type_Name_AR
    );
    setActiveTabId(id);
    setCurrentPage(1)
  };

  const getSearchVal = (val) => {
    setSearchVal(val);
  };

  const indexOfLastPost = currentPage * servicesPerPage;
  const indexOfFirstPost = indexOfLastPost - servicesPerPage;
  const currentServices = serviceList.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <InnerTitleCont
        breadCrumbs={
          <>
            <span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
              </svg>
            </span>
            <span>
              <svg
                style={{
                  margin: '0 8px',
                  transform: i18n.language == 'ar' && 'scale(-1)'
                }}
                width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span>{t("all_services")}</span>
          </>
        }
        title={t("allServicesTitle")}
        startBtn={false}
        leaveBtn={false}
      />
      <Container fluid="lg" className="pageContainer">
        <Row className="mb-5">
          <Col md={4}>
            <div className="gCard">
              <UserServices
                dealers={dealers}
                getServiceList={getServiceList}
                activeTabId={activeTabId}
              />
            </div>
          </Col>
          <Col md={8}>
            <div className="gCard services">
              <SearchList
                serviceList={currentServices}
                searchVal={searchVal}
                getSearchVal={getSearchVal}
                activeTabName={activeTabName}
                activeTabId={activeTabId}
              />
            </div>

            <div className="col mt-5">
              <Pagination
                servicesPerPage={servicesPerPage}
                totalServices={serviceList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Services;

import React, { useState, useEffect } from "react";
import style from "./Municipal.module.scss";
import { useTranslation } from "react-i18next";
import PopUp from "./../../UIComponents/PopUp/PopUp";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";

const ServiceMunicipal = ({ municipalDetails, showMuicipls, requestNo = "0", supportTypeId = "0", inputsData = {}, isSupportEnabled = true, error }) => {
  const { t, i18n } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [img, setImg] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);
  const location = useLocation();
  const [isUploading, setIsUploading] = useState(false);

  const handlePopup = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      html2canvas(document.querySelector("#root"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        setImg(canvas.toDataURL("image/jpeg"));
      });
    }
  }, [show]);

  const handleOnSubmit = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    let parsedError;
    if (error) {
      parsedError = JSON.parse(error);
    }
    const data = new FormData();
    data.append(
      "PARAMETERS",
      JSON.stringify({
        department_Id: municipalDetails.Department_Id,
        department_Service_Version_Id: municipalDetails?.Department_Service_Version_Id,
        service_Id: municipalDetails?.Service_Id,
        support_Request_Type_Id: supportTypeId,
        client_Profile_Id: clientProfiles?.Client_Profile_Id,
        client_Notes: values.client_notes,
        page_Inputs_Data: JSON.stringify(inputsData),
        page_URL: location.pathname,
        error_Request_Posted_Data: JSON.stringify({
          method: parsedError?.method,
          url: parsedError?.url,
          headers: {
            Authorization: parsedError?.headers["Authorization"],
          },
        }),
        error_Request_Response_Data: JSON.stringify(parsedError?.data),
        error_Request_URL: parsedError?.url,
        error_Request_No: requestNo,
      })
    );

    if (img) {
      setIsUploading(true)
      data.append("Page_Image", dataURItoBlob(img));
      axios
        .post(`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/ADD_SUPPORT_REQUEST`, data, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        })
        .catch((err) => console.log(err))
        .finally(() => setIsUploading(false));
      setShow(false);
      resetForm();
    }
  };

  return (
    <>
      <div className={style.Municipls}>
        <Row>
          {municipalDetails ? (
            <Col>
              <p className={style.title}>{i18n.language === "en" ? municipalDetails.Department_Name_EN : municipalDetails.Department_Name_AR}</p>
              <p className={style.subtitle}>{i18n.language === "en" ? municipalDetails.Department_Section_Name_EN : municipalDetails.Department_Section_Name_AR}</p>
              <p className={style.para}>{i18n.language === "en" ? municipalDetails.Department_Section_Description_EN : municipalDetails.Department_Section_Description_AR}</p>

              <div className={style.border}></div>

              {municipalDetails.Department_Section_Phone ? (
                <>
                  <div className={style.contactData}>
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z" fill="#15479E" />
                        <path d="M21.9696 18.33C21.9696 18.61 21.9196 18.9 21.8196 19.18C21.7896 19.26 21.7596 19.34 21.7196 19.42C21.5496 19.78 21.3296 20.12 21.0396 20.44C20.5496 20.98 20.0096 21.37 19.3996 21.62C19.3896 21.62 19.3796 21.63 19.3696 21.63C18.7796 21.87 18.1396 22 17.4496 22C16.4296 22 15.3396 21.76 14.1896 21.27C13.0396 20.78 11.8896 20.12 10.7496 19.29C10.3596 19 9.96961 18.71 9.59961 18.4L12.8696 15.13C13.1496 15.34 13.3996 15.5 13.6096 15.61C13.6596 15.63 13.7196 15.66 13.7896 15.69C13.8696 15.72 13.9496 15.73 14.0396 15.73C14.2096 15.73 14.3396 15.67 14.4496 15.56L15.2096 14.81C15.4596 14.56 15.6996 14.37 15.9296 14.25C16.1596 14.11 16.3896 14.04 16.6396 14.04C16.8296 14.04 17.0296 14.08 17.2496 14.17C17.4696 14.26 17.6996 14.39 17.9496 14.56L21.2596 16.91C21.5196 17.09 21.6996 17.3 21.8096 17.55C21.9096 17.8 21.9696 18.05 21.9696 18.33Z" fill="#15479E" />
                      </svg>
                    </span>

                    <div className={[style.content, i18n.language === "en" ? style.contentEn : style.contentAr].join(" ")}>
                      <p className={style.header}>{t("contact_us")}</p>
                      <p className={style.data}>{municipalDetails.Department_Section_Phone}</p>
                    </div>
                  </div>
                </>
              ) : null}

              <div className={style.contactData}>
                {(i18n.language === "en" && municipalDetails.Department_Address_EN) || (i18n.language === "ar" && municipalDetails.Department_Address_AR) ? (
                  <span>
                    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.6191 7.45C16.5691 2.83 12.5391 0.75 8.99912 0.75C8.99912 0.75 8.99912 0.75 8.98912 0.75C5.45912 0.75 1.41912 2.82 0.369119 7.44C-0.800881 12.6 2.35912 16.97 5.21912 19.72C6.27912 20.74 7.63912 21.25 8.99912 21.25C10.3591 21.25 11.7191 20.74 12.7691 19.72C15.6291 16.97 18.7891 12.61 17.6191 7.45ZM8.99912 12.46C7.25912 12.46 5.84912 11.05 5.84912 9.31C5.84912 7.57 7.25912 6.16 8.99912 6.16C10.7391 6.16 12.1491 7.57 12.1491 9.31C12.1491 11.05 10.7391 12.46 8.99912 12.46Z" fill="#15479E" />
                    </svg>
                  </span>
                ) : null}

                <div className={[style.content, i18n.language === "en" ? style.contentEn : style.contentAr].join(" ")}>
                  <p className={style.header}>{i18n.language === "en" ? municipalDetails.Department_Address_EN : municipalDetails.Department_Address_AR}</p>
                  <p className={style.data}>{municipalDetails.Department_Website}</p>
                </div>
              </div>

              {/* <ul className={style.socialMedia}>
                {municipalDetails.Department_Instagram ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Instagram}>
                      <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.00179 3.8978C5.73162 3.8978 3.9005 5.72933 3.9005 8C3.9005 10.2707 5.73162 12.1022 8.00179 12.1022C10.2719 12.1022 12.1031 10.2707 12.1031 8C12.1031 5.72933 10.2719 3.8978 8.00179 3.8978ZM8.00179 10.667C6.53475 10.667 5.33542 9.47094 5.33542 8C5.33542 6.52906 6.53118 5.33304 8.00179 5.33304C9.47239 5.33304 10.6682 6.52906 10.6682 8C10.6682 9.47094 9.46882 10.667 8.00179 10.667ZM13.2274 3.73C13.2274 4.26197 12.7991 4.68682 12.2708 4.68682C11.739 4.68682 11.3142 4.2584 11.3142 3.73C11.3142 3.20161 11.7426 2.77318 12.2708 2.77318C12.7991 2.77318 13.2274 3.20161 13.2274 3.73ZM15.9438 4.7011C15.8831 3.41939 15.5904 2.28406 14.6516 1.34866C13.7165 0.413254 12.5814 0.120495 11.2999 0.0562312C9.97925 -0.0187437 6.02075 -0.0187437 4.70006 0.0562312C3.4222 0.116925 2.28712 0.409684 1.34835 1.34509C0.409593 2.28049 0.120468 3.41582 0.0562186 4.69753C-0.0187395 6.01852 -0.0187395 9.97791 0.0562186 11.2989C0.116899 12.5806 0.409593 13.7159 1.34835 14.6513C2.28712 15.5867 3.41863 15.8795 4.70006 15.9438C6.02075 16.0187 9.97925 16.0187 11.2999 15.9438C12.5814 15.8831 13.7165 15.5903 14.6516 14.6513C15.5868 13.7159 15.8795 12.5806 15.9438 11.2989C16.0187 9.97791 16.0187 6.02209 15.9438 4.7011ZM14.2376 12.7163C13.9592 13.416 13.4202 13.9551 12.717 14.2372C11.664 14.6549 9.16542 14.5585 8.00179 14.5585C6.83815 14.5585 4.33597 14.6513 3.28656 14.2372C2.58695 13.9587 2.04796 13.4196 1.76598 12.7163C1.34835 11.6631 1.44473 9.1639 1.44473 8C1.44473 6.8361 1.35192 4.33337 1.76598 3.28372C2.04439 2.58396 2.58338 2.04485 3.28656 1.7628C4.33954 1.34509 6.83815 1.44148 8.00179 1.44148C9.16542 1.44148 11.6676 1.34866 12.717 1.7628C13.4166 2.04128 13.9556 2.58039 14.2376 3.28372C14.6552 4.33694 14.5588 6.8361 14.5588 8C14.5588 9.1639 14.6552 11.6666 14.2376 12.7163Z" fill="#0F257B" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}

                {municipalDetails.Department_Twitter ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Twitter}>
                      <span>
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.3554 3.53296C14.3655 3.688 14.3655 3.84308 14.3655 3.99812C14.3655 8.72718 11.066 14.1762 5.03555 14.1762C3.17767 14.1762 1.45179 13.5892 0 12.5703C0.26397 12.6035 0.517752 12.6146 0.791878 12.6146C2.32485 12.6146 3.73605 12.0498 4.86296 11.0862C3.42133 11.053 2.2132 10.023 1.79694 8.60538C2.00001 8.63858 2.20304 8.66074 2.41626 8.66074C2.71067 8.66074 3.00511 8.61642 3.2792 8.53893C1.77666 8.20665 0.649721 6.7669 0.649721 5.0281V4.98382C1.08625 5.24963 1.59391 5.41575 2.13195 5.43787C1.24869 4.7955 0.670034 3.69908 0.670034 2.45866C0.670034 1.79416 0.832441 1.18503 1.11672 0.653416C2.73095 2.82414 5.15736 4.24173 7.87816 4.39681C7.82741 4.13101 7.79694 3.85416 7.79694 3.57727C7.79694 1.60588 9.25888 0 11.0761 0C12.0203 0 12.8731 0.431929 13.4721 1.12966C14.2132 0.974619 14.9238 0.675575 15.5533 0.265805C15.3096 1.09646 14.7919 1.79419 14.1117 2.23717C14.7716 2.15968 15.4112 1.96028 16 1.68343C15.5534 2.39221 14.995 3.02347 14.3554 3.53296Z" fill="#0F257B" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}

                {municipalDetails.Department_Facebook ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Facebook}>
                      <span>
                        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.15543 9L8.608 6.10437H5.77834V4.22531C5.77834 3.43313 6.17362 2.66094 7.44093 2.66094H8.72734V0.195625C8.72734 0.195625 7.55996 0 6.44382 0C4.11352 0 2.59032 1.38688 2.59032 3.8975V6.10437H0V9H2.59032V16H5.77834V9H8.15543Z" fill="#0F257B" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}
              </ul> */}

              {isSupportEnabled && (
                <span className={[style.technicalSupport, "mainBtn primarymainBtn d-none d-md-flex flex-nowrap text-center"].join(" ")} onClick={(showMuicipls, handlePopup)}>
                  {t("technical_support_request")}
                </span>
              )}
            </Col>
          ) : null}
        </Row>
      </div>

      <PopUp show={show} handleClose={handleClose} title={t("technical_support_request")} smallModal={true}>
        <Container>
          <Formik initialValues={{ client_Notes: "" }} onSubmit={(values, { setSubmitting, resetForm }) => handleOnSubmit(values, setSubmitting, resetForm)}>
            <Form>
              <Row>
                <Col>
                  <label className={style.label}>{t("notes")}</label>
                  <Field as="textarea" name="client_notes" type="text" />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <button type="submit" className={["mainBtn primarymainBtn", style.tSupportBtn].join(" ")}>
                    {t("confirm_technical_support_request")}
                  </button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </Container>
      </PopUp>

      {isUploading && <LoadingOverlay />}
    </>
  );
};

export default ServiceMunicipal;

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import style1 from "./../view.module.scss";
import style from "./../form.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import SharjahLogo from "./../../../../global/images/sharjah-municipality-logo.png";
import { Column } from "devextreme-react/data-grid";
import Header from "components/UIComponents/Header/Header";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import Footer from "components/UIComponents/Footer/Footer";
import Tahseel from "./../../../../global/images/Tahseel-logo-white.svg";
import Grid from "./../../../../components/UIComponents/Grid/index";

const PaymentsView = () => {
  const { t, i18n } = useTranslation("common");
  const [invoiceData, setInvoiceData] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);
  const { invoiceId } = useParams();
  const [lang, setLang] = useState("");

  useEffect(() => {
    const getInvoiceInfo = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/GET_CLIENTS_INVOICE_INFO`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Invoice_id: invoiceId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      if (res.data.RESULT_CODE == 200) {
        setInvoiceData(res.data.Data);
        console.log(res.data.Data);
      }
    };
    getInvoiceInfo();
    getLang();
  }, [invoiceId]);

  const getLang = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_LANGUAGUES`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
    setLang(res.data.Data);
  };

  const handleFileDownload = (code) => {
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/DOWNLOAD_ClIENTS_INVOICE_INFO`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Invoice_id: invoiceId,
          File_Code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        let a = document.createElement("a"); // Create <a>
        a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64
        a.download = res.data.Data.File_Name; // File name
        a.click(); // Download file
      });
  };

  let invoiceDetailTypeName = "Invoice_Detail_Type_Name_EN",
    invoiceCategoryName = "Invoice_Type_Category_Name_EN";
  if (i18n.language === "ar") {
    invoiceDetailTypeName = "Invoice_Detail_Type_Name_AR";
    invoiceCategoryName = "Invoice_Type_Category_Name_AR";
  }

  return (
    <>
      <Header />

      {invoiceData ? (
        <>
          <InnerTitleCont
            breadCrumbs={`${t("menu_home")} / ${t("paid_invoices")}`}
            title={invoiceData?.Payment_No}
            permissionId={{ dealingTypeId: clientProfiles?.Dealing_Type_Id }}
            startBtn={false}
            leaveBtn={true}
            leaveTrigger={() => window.history.back()}
          />

          <Container fluid="lg" className="pageContainer">
            <Formik
              initialValues={{
                request_no: invoiceData?.Request_No,
                payment_no: invoiceData?.Payment_No,
                invoice_date: moment(invoiceData?.Invoice_Date)
                  .lang("en")
                  .format("YYYY-MM-DD hh:mm:ss A"),
                department_name:
                  i18n.language === "en"
                    ? invoiceData?.Department_Name_EN
                    : invoiceData?.Department_Name_AR,
                client_name:
                  i18n.language === "en"
                    ? invoiceData?.Client_Name_EN
                    : invoiceData?.Client_Name_AR,
                owner_name:
                  i18n.language === "en"
                    ? invoiceData?.Client_Name_EN
                    : invoiceData?.Client_Name_AR,
                invoice_id: invoiceData?.Invoice_Id,
                service_name:
                  i18n.language === "en"
                    ? invoiceData?.Service_Name_EN
                    : invoiceData?.Service_Name_AR,
                invoice_no: invoiceData?.Invoice_No,
                invoice_amount: invoiceData?.Invoice_Value,
                owner_name_ar: invoiceData?.Invoice_Payer_Name_AR,
                owner_name_en: invoiceData?.Invoice_Payer_Name_EN,
                phone: invoiceData?.Invoice_Payer_Phone,
                email: invoiceData?.Invoice_Payer_Mail,
                lang: i18n.language == "en" ? 2 : 1,
                license_no: invoiceData?.Invoice_Payer_License_No,
                // owner_name: invoiceData?.Owner_Name,
                owner_name:
                  i18n.language === "en"
                    ? invoiceData?.Invoice_Payer_Name_EN
                    : invoiceData?.Invoice_Payer_Name_AR,
                plot_no: invoiceData?.Plot_No,
                area_name:
                  i18n.language === "en"
                    ? invoiceData?.Area_Name_EN
                    : invoiceData?.Area_Name_AR,
                project_no: invoiceData?.Project_No,
                online_reference_no: invoiceData?.Online_Reference_No,
                payment_date: moment(invoiceData?.Invoice_Paid_Date)
                  .lang("en")
                  .format("YYYY-MM-DD"),
              }}
            >
              <Form className={style.newContainer}>
                <Row className="mb-5">
                  <Col md={8}>
                    <div className="gCard">
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col className="mb-2" sm={12}>
                              <p className={style.title}>{t("owner_info")}</p>
                              <p
                                className={[style.data, style.msg, "m-0 w-100"].join(" ")}
                              >
                                {t("owner_info_Validation_Message")}
                              </p>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={6} sm={12}>
                          <Row>
                            <Col className="d-flex mb-2" sm={12}>
                              <label className={style.label}>
                                {t("owner_name_ar")}
                              </label>
                              <Field
                                className={style.data}
                                name="owner_name_ar"
                                type="text"
                                as="textarea"
                                readOnly
                              />
                            </Col>

                            <Col className="d-flex mb-2" sm={12}>
                              <label className={style.label}>
                                {t("license_no")}
                              </label>
                              <Field
                                className={style.data}
                                name="license_no"
                                type="text"
                                readOnly
                              />
                            </Col>

                            <Col
                              style={{ height: "44px" }}
                              className="d-flex mb-2"
                              sm={12}
                            >
                              <label className={style.label}>
                                {t("email")}
                              </label>
                              <Field
                                className={style.data}
                                name="email"
                                type="text"
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col md={6} sm={12}>
                          <Row>
                            <Col className="d-flex mb-2" sm={12}>
                              <label className={style.label}>
                                {t("owner_name_en")}
                              </label>
                              <Field
                                className={style.data}
                                name="owner_name_en"
                                type="text"
                                as="textarea"
                                readOnly
                              />
                            </Col>

                            <Col className="d-flex mb-2" sm={12}>
                              <label className={style.label}>
                                {t("phone")}
                              </label>
                              <Field
                                className={style.data}
                                name="phone"
                                type="text"
                                readOnly
                              />
                            </Col>

                            <Col className="d-flex mb-2" sm={12}>
                              <label className={style.label}>
                                {t("message_language")}
                              </label>
                              <Field
                                as="select"
                                name="lang"
                                className={style.data}
                                disabled
                              >
                                <option value="" disabled defaultValue>{`${t(
                                  "Select"
                                )}`}</option>
                                {lang &&
                                  lang.map((item) => (
                                    <option
                                      key={item?.Record_Id}
                                      value={item.Record_Id}
                                    >
                                      {i18n.language == "ar"
                                        ? item?.Record_Name_AR
                                        : item?.Record_Name_EN}
                                    </option>
                                  ))}
                              </Field>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={12} sm={12}>
                          <Row>
                            <Col className="mb-2" sm={12}>
                              {invoiceData?.Invoice_File_Code ? (
                                <>
                                  <label className={style.titleLabel}>
                                    {t("invoice_file")}
                                  </label>
                                  <div
                                    className={[
                                      style.file,
                                      style.data,
                                      "w-100 d-flex justify-content-between align-items-center m-0",
                                    ].join(" ")}
                                  >
                                    <div className="d-flex align-items-center">
                                      <FontAwesomeIcon
                                        className={style.fileSvg}
                                        icon={faFile}
                                        color="#58743A"
                                      />
                                      <div className="d-flex px-1">
                                        <p className="m-0">
                                          {
                                            invoiceData?.Invoice_File_Code_Info
                                              ?.File_Name
                                          }
                                        </p>
                                        <p className="m-0">
                                          {
                                            invoiceData?.Invoice_File_Code_Info
                                              ?.File_Size
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <span
                                      className={[
                                        style.download,
                                        i18n.language === "en"
                                          ? style.downloadEN
                                          : style.downloadAR,
                                      ].join(" ")}
                                      onClick={() =>
                                        handleFileDownload(
                                          invoiceData?.Invoice_File_Code
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faDownload}
                                        color="#58743A"
                                      />
                                    </span>
                                  </div>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="gCard h-100 d-flex">
                      <Row>
                        <Col md={6}>
                          <img src={Tahseel} />
                        </Col>
                        <Col md={6} className="text-center">
                          <p className={style.valueTitle}>
                            {t("invoice_paid_value")}
                          </p>
                          <p className={style.value}>
                            {invoiceData.Invoice_Paid_Value}
                          </p>
                        </Col>
                        <Col md={12} className="text-center my-2">
                          <p className={style.valuePaidBy}>
                            {t("payment_date")}
                          </p>
                          <Field
                            className={style.payer}
                            name="payment_date"
                            type="text"
                          />
                        </Col>
                        <Col md={12} className="text-center my-2">
                          <p className={style.valuePaidBy}>
                            {t("collection_payment_order_number")}
                          </p>
                          <Field
                            className={style.payer}
                            name="online_reference_no"
                            type="text"
                          />
                        </Col>
                        <Col md={12} className="text-center my-2">
                          {invoiceData?.Payment_File_Code ? (
                            <>
                              <div
                                className={[
                                  style.file,
                                  style.data,
                                  "w-100 d-flex justify-content-between align-items-center m-0",
                                ].join(" ")}
                              >
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    className={style.fileSvg}
                                    icon={faFile}
                                    color="#58743A"
                                  />
                                  <div className="d-flex px-1">
                                    <p className="m-0">
                                      {
                                        invoiceData?.Payment_File_Code_Info
                                          ?.File_Name
                                      }
                                    </p>
                                    <p className="m-0">
                                      {
                                        invoiceData?.Payment_File_Code_Info
                                          ?.File_Size
                                      }
                                    </p>
                                  </div>
                                </div>
                                <span
                                  className={[
                                    style.download,
                                    i18n.language === "en"
                                      ? style.downloadEN
                                      : style.downloadAR,
                                  ].join(" ")}
                                  onClick={() =>
                                    handleFileDownload(
                                      invoiceData?.Payment_File_Code
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    size="lg"
                                    icon={faDownload}
                                    color="#58743A"
                                  />
                                </span>
                              </div>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col md={12}>
                    <div className="gCard">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col className="mb-2" sm={12}>
                              <p className={style.title}>{t("bond_data")}</p>
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("client_name")}
                              </label>
                              <Field
                                className={style.data}
                                name="client_name"
                                type="text"
                                as="textarea"
                                readOnly
                              />
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("payment_no")}
                              </label>
                              <Field
                                className={style.data}
                                name="payment_no"
                                type="text"
                                readOnly
                              />
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("invoice_no")}
                              </label>
                              <Field
                                className={style.data}
                                name="invoice_no"
                                type="text"
                                readOnly
                              />
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("invoice_date")}
                              </label>
                              <Field
                                className={[
                                  style.data,
                                  i18n.language === "ar" ? style.dateAR : null,
                                ].join(" ")}
                                name="invoice_date"
                                type="text"
                                readOnly
                              />
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("invoice_amount")}
                              </label>
                              <Field
                                className={style.data}
                                name="invoice_amount"
                                type="text"
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col className="mb-2" sm={12}>
                              <p className={style.title}>{t("service_data")}</p>
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("owner")}
                              </label>
                              <Field
                                className={style.data}
                                name="owner_name"
                                type="text"
                                as="textarea"
                                readOnly
                              />
                            </Col>
                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("request_no")}
                              </label>
                              <Field
                                className={style.data}
                                name="request_no"
                                type="text"
                                readOnly
                              />
                            </Col>

                            <Col className="d-flex mb-2 px-2" sm={12}>
                              <label className={style.label}>
                                {t("service_name")}
                              </label>
                              <Field
                                className={style.data}
                                name="service_name"
                                type="text"
                                as="textarea"
                                rows={5}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row className="h-100 align-items-center">
                            <Col sm={12} className="text-center">
                              <img
                                className={style.departmentLogo}
                                src={invoiceData.Department_Logo_Url}
                              />
                              <p className={style.sector}>
                                {i18n.language == "ar"
                                  ? invoiceData.Department_Sector_Name_AR
                                  : invoiceData.Department_Sector_Name_EN}
                              </p>
                              <p className={style.section}>
                                {i18n.language == "ar"
                                  ? invoiceData.Department_Section_Name_AR
                                  : invoiceData.Department_Section_Name_EN}
                              </p>

                              <div className="d-flex justify-content-around h-100 w-75 mx-auto mt-1">
                                {invoiceData.Department_Instagram ? (
                                  <span className={style.icons}>
                                    <a
                                      target="_blank"
                                      href={`https://www.${invoiceData.Department_Instagram}`}
                                    >
                                      <span>
                                        <svg
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                          <title>instagram-fill</title>
                                          <g
                                            id="Page-1"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <g
                                              id="0"
                                              transform="translate(-633.000000, -4036.000000)"
                                            >
                                              <g
                                                id="Group"
                                                transform="translate(482.000000, 4036.000000)"
                                              >
                                                <g
                                                  id="instagram-fill"
                                                  transform="translate(151.000000, 0.000000)"
                                                >
                                                  <polygon
                                                    id="Path"
                                                    points="0 0 24 0 24 24 0 24"
                                                  ></polygon>
                                                  <path
                                                    d="M12,2 C14.717,2 15.056,2.01 16.122,2.06 C17.187,2.11 17.912,2.277 18.55,2.525 C19.21,2.779 19.766,3.123 20.322,3.678 C20.8304937,4.17789596 21.2239536,4.78258896 21.475,5.45 C21.722,6.087 21.89,6.813 21.94,7.878 C21.987,8.944 22,9.283 22,12 C22,14.717 21.99,15.056 21.94,16.122 C21.89,17.187 21.722,17.912 21.475,18.55 C21.2246766,19.2177765 20.8311207,19.822617 20.322,20.322 C19.8219615,20.8303192 19.2173093,21.2237526 18.55,21.475 C17.913,21.722 17.187,21.89 16.122,21.94 C15.056,21.987 14.717,22 12,22 C9.283,22 8.944,21.99 7.878,21.94 C6.813,21.89 6.088,21.722 5.45,21.475 C4.78232618,21.2244734 4.17752722,20.8309445 3.678,20.322 C3.16940644,19.8221857 2.77593123,19.2174693 2.525,18.55 C2.277,17.913 2.11,17.187 2.06,16.122 C2.013,15.056 2,14.717 2,12 C2,9.283 2.01,8.944 2.06,7.878 C2.11,6.812 2.277,6.088 2.525,5.45 C2.7752361,4.7821794 3.16880364,4.17732106 3.678,3.678 C4.17767098,3.16923123 4.78242858,2.77572924 5.45,2.525 C6.088,2.277 6.812,2.11 7.878,2.06 C8.944,2.013 9.283,2 12,2 Z M12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 L12,7 Z M18.5,6.75 C18.5,6.05964406 17.9403559,5.5 17.25,5.5 C16.5596441,5.5 16,6.05964406 16,6.75 C16,7.44035594 16.5596441,8 17.25,8 C17.9403559,8 18.5,7.44035594 18.5,6.75 Z M12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 Z"
                                                    id="Shape"
                                                    fill="$white"
                                                    fillRule="nonzero"
                                                    opacity="0.823446584"
                                                  ></path>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </span>
                                    </a>
                                  </span>
                                ) : null}

                                {invoiceData.Department_Telephone ? (
                                  <span className={style.icons}>
                                    <a
                                      target="_blank"
                                      href={`https://api.whatsapp.com/send?phone=${invoiceData.Department_Telephone}`}
                                    >
                                      <span>
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g>
                                            <path
                                              fill="#fff"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path
                                              fill-rule="nonzero"
                                              d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </a>
                                  </span>
                                ) : null}

                                {invoiceData.Department_Twitter ? (
                                  <span className={style.icons}>
                                    <a
                                      target="_blank"
                                      href={`https://www.${invoiceData.Department_Twitter}`}
                                    >
                                      <span>
                                        <svg
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                          <title>twitter-fill</title>
                                          <g
                                            id="Page-1"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <g
                                              id="0"
                                              transform="translate(-520.000000, -4036.000000)"
                                            >
                                              <g
                                                id="Group"
                                                transform="translate(482.000000, 4036.000000)"
                                              >
                                                <g
                                                  id="twitter-fill"
                                                  transform="translate(38.000000, 0.000000)"
                                                >
                                                  <polygon
                                                    id="Path"
                                                    points="0 0 24 0 24 24 0 24"
                                                  ></polygon>
                                                  <path
                                                    d="M22.162,5.656 C21.398557,5.99369373 20.5889601,6.21547339 19.76,6.314 C20.6337508,5.79143332 21.2877055,4.96901423 21.6,4 C20.78,4.488 19.881,4.83 18.944,5.015 C17.6498495,3.63031951 15.5862776,3.28908903 13.9152663,4.18345292 C12.244255,5.07781681 11.3836288,6.98414575 11.818,8.829 C8.45502254,8.66044327 5.32174389,7.07199458 3.198,4.459 C2.82629012,5.09745032 2.63095514,5.82322672 2.632,6.562 C2.632,8.012 3.37,9.293 4.492,10.043 C3.82800881,10.0220962 3.1786324,9.84278055 2.598,9.52 L2.598,9.572 C2.59841319,11.5630358 4.00151442,13.2781232 5.953,13.673 C5.33661503,13.8400369 4.69030669,13.8646582 4.063,13.745 C4.61012698,15.4491831 6.18045142,16.6167778 7.97,16.65 C6.21794158,18.0260194 3.99074,18.6491034 1.779,18.382 C3.69069779,19.6114328 5.91609682,20.2641239 8.189,20.2620052 C15.882,20.2620052 20.089,13.889 20.089,8.362 C20.089,8.182 20.084,8 20.076,7.822 C20.8948641,7.23016766 21.6015987,6.49701939 22.163,5.657 L22.162,5.656 Z"
                                                    id="Path"
                                                    fill="$white"
                                                    fillRule="nonzero"
                                                    opacity="0.823446584"
                                                  ></path>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </span>
                                    </a>
                                  </span>
                                ) : null}

                                {invoiceData.Department_Facebook ? (
                                  <span className={style.icons}>
                                    <a
                                      target="_blank"
                                      href={`https://www.${invoiceData.Department_Facebook}`}
                                    >
                                      <span>
                                        <svg
                                          width="24px"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                          <title>facebook-box-fill</title>
                                          <g
                                            id="Page-1"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <g
                                              id="0"
                                              transform="translate(-482.000000, -4036.000000)"
                                            >
                                              <g
                                                id="facebook-box-fill"
                                                transform="translate(482.000000, 4036.000000)"
                                              >
                                                <polygon
                                                  id="Path"
                                                  points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                  d="M15.402,21 L15.402,14.034 L17.735,14.034 L18.084,11.326 L15.402,11.326 L15.402,9.598 C15.402,8.814 15.62,8.279 16.744,8.279 L18.178,8.279 L18.178,5.857 C17.4837982,5.78334463 16.7860943,5.74762486 16.088,5.75 C14.021,5.75 12.606,7.012 12.606,9.33 L12.606,11.326 L10.268,11.326 L10.268,14.034 L12.606,14.034 L12.606,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L15.402,21 Z"
                                                  id="Path"
                                                  fill="$white"
                                                  fillRule="nonzero"
                                                  opacity="0.823446584"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </span>
                                    </a>
                                  </span>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        {invoiceData?.Invoice_Details?.length > 0 ? (
                          <Col md={12}>
                            <Col className="my-3 px-0" sm={12}>
                              <p className={style.title}>{t("invoice_data")}</p>
                              <Grid
                                width="100%"
                                sourceData={invoiceData?.Invoice_Details}
                                showBorders={false}
                                ShowHorizontalLines={false}
                                showColumnLines={false}
                                editEnabled={false}
                                showRowLines={true}
                              >
                                <Column
                                  caption={t("item_no")}
                                  alignment="center"
                                  dataField="Invoice_Detail_No"
                                  dataType="string"
                                  width={100}
                                />
                                <Column
                                  caption={t("item_name")}
                                  alignment="start"
                                  dataField={invoiceDetailTypeName}
                                  dataType="string"
                                  width={470}
                                />
                                <Column
                                  caption={t("item_category")}
                                  alignment="center"
                                  dataField={invoiceCategoryName}
                                  dataType="string"
                                  width={100}
                                />

                                <Column
                                  caption={t("item_value")}
                                  alignment="center"
                                  dataField="Invoice_Detail_Value"
                                  dataType="string"
                                  width={100}
                                />
                                <Column
                                  caption={t("invoice_detail_tax_value")}
                                  alignment="center"
                                  dataField="Invoice_Detail_Tax_Value"
                                  dataType="string"
                                  width={100}
                                />
                                <Column
                                  caption={t(
                                    "invoice_detail_grand_total_value"
                                  )}
                                  alignment="center"
                                  dataField="Invoice_Detail_Grand_Total_Value"
                                  dataType="string"
                                  width={100}
                                />
                              </Grid>
                            </Col>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </Container>

          <Footer />
        </>
      ) : null}
    </>
  );
};

export default React.memo(PaymentsView);

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./InnerTitleCont.module.scss";
import { NavLink } from "react-router-dom";

const InnerTitleCont = ({ startTrigger, leaveTrigger, leaveBtn = true, ...props }) => {
  const { t, i18n } = useTranslation("common");

  return (
    <Container fluid className={style.InnerTitleCont}>
      <Container fluid="lg" className={style.panelContainer}>
        <Row>
          <Col md={8}>
            <p className={style.breadCrumbs}>{props.breadCrumbs}</p>
            {/* <p className={style.title}>{props.title}</p> */}
            <p dangerouslySetInnerHTML={{ __html: props.title }} className={style.title}/>
          </Col>
          <Col sm={4} className={["justify-content-end align-items-center d-none d-md-flex", props.startBtn || leaveBtn ? style.show : style.hide].join(" ")}>
            {props.startBtn ? (
              <button type="button" className="mainBtn secondarymainBtn lgBtn" onClick={startTrigger}>
                {t("start_service")}
              </button>
            ) : 
              leaveBtn && (
              <NavLink to="/dashboard" className="mainBtn secondarymainBtn lgBtn" onClick={leaveTrigger}>
                {t("leave")}
              </NavLink>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default InnerTitleCont;

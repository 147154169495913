import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import style from "./Request.module.scss"
import { useTranslation } from "react-i18next"
import { Row, Col, Form, Container, Accordion } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
    saveAdditionalRequestInputNotes,
    saveAdditionalRequestAttachmentData,
    deleteAdditionalRequestAttachment,
    downloadAdditionalRequestAttachment,
    submitRequestLicenseName,
    suggestRequestNewLicenseName,
    downloadAttachment,
    textTranslation
} from "api/serviceAPI"
import moment from "moment"
import { setError } from "./updateRequestSlice"
import {
    Attachment,
    Csv,
    Doc,
    Excel,
    File,
    Jpg,
    Mp3,
    Mp4,
    Pdf,
    Png,
    Text,
    Zip,
} from "components/UIComponents/FileIcons"
import { useHistory } from "react-router-dom"
import LoadingOverlay from "components/UIComponents/LoadingOverlay"
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar"
import debounce from "lodash.debounce"

const ConfirmTradeName = () => {
    const { t, i18n } = useTranslation("common")
    const {
        serviceData,
        requestData,
        requestId,
        requiredChangesAttachments,
        requiredChangesNotes,
        currentTaskData,
        loading,
        oldRequiredChangesList
    } = useSelector((state) => state.service.updateRequest)
    const { clientProfiles } = useSelector((state) => state.user)
    const [isOpen, setIsOpen] = useState(false)
    const [isSubmiting, setIsSubmiting] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [highlightTerms, setHighlightTerms] = useState(false)
    const [hasSuggestions, setHasSuggestions] = useState(false)
    const [isEllipsis, setIsEllipsis] = useState(true)
    const termsRef = useRef()
    const [validationError, setValidationError] = useState(false)
    const [activeInput, setActiveInput] = useState('')
    const [formVal, setFormVal] = useState({
        suggestedNameInEnglishFirst: '',
        suggestedNameInArabicFirst: '',
        suggestedNameInEnglishSecond: '',
        suggestedNameInArabicSecond: '',
        suggestedNameInEnglishThird: '',
        suggestedNameInArabicThird: ''
    })

    useEffect(() => {
        if (termsRef && highlightTerms && !acceptedTerms) {
            termsRef.current.style.transform = "scale(1.01)"
            termsRef.current.style.textDecoration = "underline"
        } else {
            termsRef.current.style.transform = ""
            termsRef.current.style.textDecoration = ""
        }
    }, [termsRef, highlightTerms, acceptedTerms])

    const handleSave = () => history.push(`/dashboard`)

    const handleToggleEllipsis = () => setIsEllipsis(!isEllipsis)

    const handleTerms = () => setAcceptedTerms(Number(!acceptedTerms))

    const handleSubmit = (e) => {
        e.preventDefault();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setIsSubmiting(true);

        if (!hasSuggestions) {
            submitRequestLicenseName({
                Task_Id: currentTaskData?.Task_Id,
                Client_Profile_Id: clientProfiles.Client_Profile_Id,
                Request_Id: requestId,
            }, source
            ).then((response) => {
                if (response?.Status) {
                    history.push(`/dashboard`);
                }
            }).catch((err) => {
                dispatch(setError(err))
            }).finally(() => {
                setIsSubmiting(false)
            })
        } else {
            suggestRequestNewLicenseName({
                Task_Id: currentTaskData?.Task_Id,
                Client_Profile_Id: clientProfiles.Client_Profile_Id,
                Request_Id: requestId,
                License_Name_AR_1: formVal.suggestedNameInArabicFirst,
                License_Name_EN_1: formVal.suggestedNameInEnglishFirst,
                License_Name_AR_2: formVal.suggestedNameInArabicSecond,
                License_Name_EN_2: formVal.suggestedNameInEnglishSecond,
                License_Name_AR_3: formVal.suggestedNameInArabicThird,
                License_Name_EN_3: formVal.suggestedNameInEnglishThird
            }, source
            ).then((response) => {
                if (response?.Status) {
                    history.push(`/dashboard`);
                }
            }).catch((err) => {
                dispatch(setError(err))
            }).finally(() => {
                setIsSubmiting(false)
            })
        }
        return () => source.cancel()
    }

    const download = async (f) => {
        await downloadAttachment({
            File_Code: f.File_Code,
        })
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", f.File_Name);
                document.body.appendChild(link);
                link.click();
            })
            .catch((ex) => {
                console.error(ex)
            })
    }

    const onKeyPress = (e, lang, name) => {
        var ew = e.which;
        setActiveInput(e.target.name)

        if (lang == 1) {
            if (ew != 8) {
                if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
                    setValidationError(true);
                    e.preventDefault();
                    return false;
                }
            }
        } else if (lang == 2) {
            if (ew == 32) {
                return true;
            } else if (48 <= ew && ew <= 57) {
                return true;
            } else if (65 <= ew && ew <= 90) {
                return true;
            } else if (97 <= ew && ew <= 122) {
                return true;
            } else {
                setValidationError(true);
                e.preventDefault();
                return false;
            }
        }

        if (validationError) {
            setValidationError(false);
        }
        return true;
    }

    const handleTextTranslation = (val, lang, name) => {
        textTranslation({
            Input_Text: val,
            Input_Lang: lang
        }).then((response) => {
            if (name == 'suggestedNameInEnglishFirst') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInArabicFirst: response?.Data }))
            } else if (name == 'suggestedNameInArabicFirst') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishFirst: response?.Data }))
            } else if (name == 'suggestedNameInEnglishSecond') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInArabicSecond: response?.Data }))
            } else if (name == 'suggestedNameInArabicSecond') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishSecond: response?.Data }))
            } else if (name == 'suggestedNameInEnglishThird') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInArabicThird: response?.Data }))
            } else if (name == 'suggestedNameInArabicThird') {
                setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishThird: response?.Data }))
            }
        })
    }

    return (
        <>
            <Row className="align-items-center position-relative">
                <Col xs={6} className="d-flex justify-content-end mb-2">
                    <button className="mainBtn primarymainBtn smBtn" onClick={handleSave}>
                        {t("save")}
                    </button>
                </Col>
            </Row>

            <Row className="align-items-center position-relative">
                <Col xs={12} className={style.detailsContent}>
                    <Row>
                        <p className={style.details}>{t("Request Number")}</p>
                        <p className={[style.details, 'px-1'].join(' ')}>{requestData?.Request_No}</p>
                    </Row>
                    <Row>
                        <p className={style.details}>{t("Request Date")}</p>
                        <p className={[style.details, 'px-1'].join(' ')}>
                            {requestData?.Request_Date &&
                                moment(requestData.Request_Date)
                                    .lang("en")
                                    .format("YYYY-MM-DD")}
                        </p>
                    </Row>
                    <Row className="justify-content-between">
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("service_name")}:</p>
                                <p className={[style.details, 'px-1'].join(' ')}>
                                    {i18n.language === "ar"
                                        ? serviceData?.Service_Name_AR
                                        : serviceData?.Service_Name_EN}
                                </p>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("service_code")}:</p>
                                <p className={[style.details, 'px-1'].join(' ')}>{serviceData?.Service_Code}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("Service Version")}</p>
                                <p className={[style.details, 'px-1'].join(' ')}>
                                    {serviceData?.Department_Service_Version_Name}
                                </p>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("Service Category")}</p>
                                <p className={[style.details, 'px-1'].join(' ')}>
                                    {i18n.language === "ar"
                                        ? serviceData?.Service_Category_Name_AR
                                        : serviceData?.Service_Category_Name_EN}
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("department_name")}:</p>
                                <p className={[style.details, 'px-1'].join(' ')}>
                                    {i18n.language === "ar"
                                        ? serviceData?.Department_Name_AR
                                        : serviceData?.Department_Name_EN}
                                </p>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <p className={style.details}>{t("Section Name")}</p>
                                <p className={[style.details, 'px-1'].join(' ')}>
                                    {i18n.language === "ar"
                                        ? serviceData?.Department_Section_Name_AR
                                        : serviceData?.Department_Section_Name_EN}
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {oldRequiredChangesList?.length > 0 ?
                <Row>
                    <Col xl={12} className={style.step}>
                        <Accordion>
                            <Accordion.Toggle
                                className={style.accBtn}
                                eventKey='0'
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <div className={[style.stepHeader, 'd-flex justify-content-between align-items-baseline'].join(' ')}>
                                    <div className='d-flex'>
                                        <span className={style.number}>1</span>
                                        <p className={style.stepTitle}>
                                            {t("previous_modifications")}
                                        </p>
                                    </div>
                                    <span className={[style.accArrow, isOpen ? style.upArrow : null].join(' ')}>
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                                        >
                                            <g>
                                                <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey='0'>
                                <div className={[style.stepContent, 'py-2'].join(' ')}>
                                    {oldRequiredChangesList?.map(x => (
                                        <>
                                            {loading !== "pending" ? (
                                                <Row>
                                                    {x.Required_Changes_Date && (
                                                        <Col xs={12} className='d-flex mt-2'>
                                                            <p className={[style.details, 'mb-1'].join(' ')}>{t("inDate")}:</p>
                                                            <p className={[style.details, 'mb-1'].join(' ')}>
                                                                {x.Required_Changes_Date &&
                                                                    moment(x.Required_Changes_Date)
                                                                        .lang("en")
                                                                        .format("YYYY-MM-DD")}
                                                            </p>
                                                        </Col>
                                                    )}

                                                    {x.Required_Changes_Notes && (
                                                        <Col xs={12} className='d-flex'>
                                                            <p className={[style.details, 'mb-1'].join(' ')}>{x.Required_Changes_Notes}</p>
                                                        </Col>
                                                    )}

                                                    {x.Required_Changes_Attachments?.length > 0 && (
                                                        <Col xs={12} className='d-flex'>
                                                            <div className={[style.files, style.oldRequestFiles, 'w-100'].join(' ')}>
                                                                {x.Required_Changes_Attachments.map((uploadedFile) => (
                                                                    <div
                                                                        key={uploadedFile.File_Code}
                                                                        className="d-flex justify-content-between mb-1"
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            {getFileIcon(uploadedFile.File_Content)}
                                                                            <span
                                                                                onClick={handleToggleEllipsis}
                                                                                className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                                                                    } mx-1`}
                                                                            >
                                                                                {uploadedFile.File_Name}
                                                                            </span>
                                                                        </div>

                                                                        <div className="d-flex align-items-end">
                                                                            <span className={`${style.fileContent} mx-1`}>
                                                                                {uploadedFile.File_Size}
                                                                            </span>
                                                                            <span className={style.fileIcon} onClick={() => download(uploadedFile)}>
                                                                                <svg
                                                                                    width="17"
                                                                                    height="18"
                                                                                    viewBox="0 0 17 18"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                                                        stroke="#1B2124"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                                                        stroke="#1B2124"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M8.5 11.9847V1.23981"
                                                                                        stroke="#1B2124"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            ) : (<div>{t("Loading")}</div>)}
                                        </>
                                    ))}
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </Col >
                </Row>
                : null}

            <Row>
                <Col xl={12} className={style.step}>
                    <div className={style.stepHeader}>
                        <span className={style.number}>{oldRequiredChangesList?.length > 0 ? 2 : 1}</span>
                        <p className={style.stepTitle}>
                            {t("Modifications and Required Data")}
                        </p>
                    </div>
                    <div className={style.stepContent}>
                        {loading !== "pending" ? (
                            <Row>
                                <Col xs={12}>
                                    <Form.Label className={style.label}>{t("notes")}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className={style.customInput}
                                        defaultValue={requiredChangesNotes}
                                        disabled
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Form.Label className={style.label}>
                                        {t("attachments")}
                                    </Form.Label>
                                    <div className={style.fileContainer}>
                                        <Form.Label className={[style.fileLabel, 'py-0'].join(' ')}>
                                            يرجى تحميل المرفقات المعادة
                                        </Form.Label>

                                        {requiredChangesAttachments?.length > 0 && (
                                            <div className={style.files}>
                                                {requiredChangesAttachments.map((uploadedFile) => (
                                                    <div
                                                        key={uploadedFile.File_Code}
                                                        className="d-flex justify-content-between mb-1"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            {getFileIcon(uploadedFile.File_Content)}
                                                            <span
                                                                onClick={handleToggleEllipsis}
                                                                className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                                                    } mx-1`}
                                                            >
                                                                {uploadedFile.File_Name}
                                                            </span>
                                                        </div>

                                                        <div className="d-flex align-items-end">
                                                            <span className={`${style.fileContent} mx-1`}>
                                                                {uploadedFile.File_Size}
                                                            </span>
                                                            <span className={style.fileIcon} onClick={() => download(uploadedFile)}>
                                                                <svg
                                                                    width="17"
                                                                    height="18"
                                                                    viewBox="0 0 17 18"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                                        stroke="#1B2124"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                                        stroke="#1B2124"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M8.5 11.9847V1.23981"
                                                                        stroke="#1B2124"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <div>{t("Loading")}</div>
                        )}
                    </div>
                </Col>
            </Row>

            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xl={12} className={style.step}>
                        <div className={style.stepContent}>
                            <Row>
                                <Col md={6}>
                                    <Form.Label
                                        className={style.label}
                                    >
                                        {t('suggestedNameInEnglish')}
                                    </Form.Label>
                                    <Form.Control
                                        name='suggestedNameInEnglish'
                                        disabled={true}
                                        defaultValue={currentTaskData?.License_Name_EN}
                                        className={style.customInput}
                                    />
                                </Col>

                                <Col md={6}>
                                    <Form.Label
                                        className={style.label}
                                    >
                                        {t('suggestedNameInArabic')}
                                    </Form.Label>
                                    <Form.Control
                                        name='suggestedNameInArabic'
                                        disabled={true}
                                        defaultValue={currentTaskData?.License_Name_AR}
                                        className={style.customInput}
                                    />
                                </Col>

                                <Col md={6} className='mt-2'>
                                    <input
                                        className={[style.checkBox, 'mx-1 mt-1'].join(' ')}
                                        type="checkbox"
                                        checked={!hasSuggestions ? true : false}
                                        disabled={true}
                                    />

                                    <Form.Label
                                        className={style.label}
                                    >
                                        {t('acceptSuggested')}
                                    </Form.Label>
                                </Col>

                                <Col md={6} className='mt-2'>
                                    <input
                                        className={[style.checkBox, 'mx-1 mt-1'].join(' ')}
                                        type="checkbox"
                                        onChange={(e) => setHasSuggestions(e.target.checked)}
                                    />

                                    <Form.Label
                                        className={style.label}
                                    >
                                        {t('submitSuggestionsForCompanyName')}
                                    </Form.Label>
                                </Col>

                                {hasSuggestions && (
                                    <>
                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInEnglish')} {t('first')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInEnglishFirst'
                                                onKeyPress={(e) => onKeyPress(e, 2)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishFirst: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInEnglishFirst}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInEnglishFirst' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write English only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInEnglishFirst, 2, 'suggestedNameInEnglishFirst')}
                                            >
                                                {t("translateToAR")}
                                            </Form.Text>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInArabic')} {t('first')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInArabicFirst'
                                                onKeyPress={(e) => onKeyPress(e, 1)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInArabicFirst: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInArabicFirst}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInArabicFirst' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write Arabic only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInArabicFirst, 1, 'suggestedNameInArabicFirst')}
                                            >
                                                {t("translateToEN")}
                                            </Form.Text>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInEnglish')} {t('second')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInEnglishSecond'
                                                onKeyPress={(e) => onKeyPress(e, 2)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishSecond: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInEnglishSecond}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInEnglishSecond' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write English only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInEnglishSecond, 2, 'suggestedNameInEnglishSecond')}
                                            >
                                                {t("translateToAR")}
                                            </Form.Text>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInArabic')} {t('second')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInArabicSecond'
                                                onKeyPress={(e) => onKeyPress(e, 1)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInArabicSecond: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInArabicSecond}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInArabicSecond' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write Arabic only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInArabicSecond, 1, 'suggestedNameInArabicSecond')}
                                            >
                                                {t("translateToEN")}
                                            </Form.Text>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInEnglish')} {t('third')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInEnglishThird'
                                                onKeyPress={(e) => onKeyPress(e, 2)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInEnglishThird: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInEnglishThird}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInEnglishThird' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write English only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInEnglishThird, 2, 'suggestedNameInEnglishThird')}
                                            >
                                                {t("translateToAR")}
                                            </Form.Text>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Label
                                                className={style.label}
                                            >
                                                {t('suggestedNameInArabic')} {t('third')}<span className={style.required}>*</span>
                                            </Form.Label>

                                            <Form.Control
                                                name='suggestedNameInArabicThird'
                                                onKeyPress={(e) => onKeyPress(e, 1)}
                                                onChange={(e) => setFormVal(prevState => ({ ...prevState, suggestedNameInArabicThird: e.target.value }))}
                                                required={hasSuggestions && true}
                                                value={formVal.suggestedNameInArabicThird}
                                                className={style.customInput}
                                            />

                                            {
                                                validationError && activeInput == 'suggestedNameInArabicThird' && (
                                                    <Form.Text className={style.onError}>
                                                        {t("Write Arabic only")}
                                                    </Form.Text>
                                                )
                                            }

                                            <Form.Text
                                                className={style.translateBtn}
                                                onClick={() => handleTextTranslation(formVal.suggestedNameInArabicThird, 1, 'suggestedNameInArabicThird')}
                                            >
                                                {t("translateToEN")}
                                            </Form.Text>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12} className={style.step}>
                        <div className={style.stepHeader}>
                            <span className={style.number}>{oldRequiredChangesList?.length > 0 ? 3 : 2}</span>
                            <p className={style.stepTitle}>البيانات المضافة للطلب</p>
                        </div>
                        <div className={style.stepContent}>
                            <div className={style.addRequestNote}>{t('add_request_note')}</div>
                            {loading !== "pending" ? (
                                <Row>
                                    <Col xs={12}>
                                        <Form.Label className={style.label}>
                                            {t("notes")}
                                        </Form.Label>
                                        <Textarea defaultValue={currentTaskData?.Task_Notes} />
                                    </Col>
                                    <AddedFiles
                                        defaultValue={currentTaskData?.Task_Attachments}
                                    />
                                </Row>
                            ) : (
                                <div>{t("Loading")}</div>
                            )}
                        </div>
                    </Col>
                    <Col xl={12} className={style.step}>
                        <div className={style.terms}>
                            <input
                                className={style.checkBox}
                                type="checkbox"
                                defaultChecked={acceptedTerms}
                                onChange={handleTerms}
                            />
                            <p ref={termsRef}>{t("terms and conditions")}</p>
                        </div>
                        <div
                            className={style.termsPara}
                            dangerouslySetInnerHTML={{
                                __html:
                                    i18n.language === "ar"
                                        ? serviceData?.Service_Rules_AR
                                        : serviceData?.Service_Rules_EN,
                            }}
                        ></div>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-end">
                        <button
                            type="submit"
                            className="mainBtn primarymainBtn smBtn"
                            disabled={!acceptedTerms}
                            onPointerEnter={() => setHighlightTerms(true)}
                            onPointerLeave={() => setHighlightTerms(false)}
                            style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
                        >
                            {t("submit")}
                        </button>
                    </Col>
                </Row>
            </Form>

            {isSubmiting && <LoadingOverlay />}
        </>
    )
}

export default ConfirmTradeName;

const AddedFiles = ({ defaultValue }) => {
    const { t, i18n } = useTranslation("common");
    const { requestId, currentTaskData } = useSelector(
        (state) => state.service.updateRequest
    );
    const [uploadedFiles, setUploadedFiles] = useState(
        defaultValue ? defaultValue : []
    );
    const { clientProfiles } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [isEllipsis, setIsEllipsis] = useState(true);

    const onChange = async (e) => {
        var files = e.target.files[0];
        if (files) {
            const data = new FormData();
            data.append(
                "PARAMETERS",
                JSON.stringify({
                    Client_Profile_Id: clientProfiles?.Client_Profile_Id,
                    Request_Id: requestId,
                    Task_Id: currentTaskData?.Task_Id,
                })
            );
            setIsUploading(true);
            data.append("Attached_File", e.target.files[0]);
            const response = await saveAdditionalRequestAttachmentData(
                data,
                handleProgress
            )
                .catch((err) => {
                    dispatch(setError(err));
                })
                .finally(() => {
                    setIsUploading(false);
                    setProgressValue(0);
                });
            if (response?.Status) {
                setUploadedFiles((prev) => [...prev, response?.Data]);
            }
            e.target.value = null;
        }
    };

    const removeFile = async (f) => {
        const response = await deleteAdditionalRequestAttachment({
            Client_Profile_Id: clientProfiles?.Client_Profile_Id,
            Request_Id: requestId,
            Task_Id: currentTaskData?.Task_Id,
            Service_Detail_Id: f.Service_Detail_Id,
            File_Code: f.File_Code,
        });
        if (response?.Status) {
            setUploadedFiles((prev) => prev.filter((x) => x !== f));
        }
    };

    const download = async (f) => {
        await downloadAdditionalRequestAttachment({
            Client_Profile_Id: clientProfiles?.Client_Profile_Id,
            Request_Id: requestId,
            Task_Id: currentTaskData?.Task_Id,
            Service_Detail_Id: f.Service_Detail_Id,
            File_Code: f.File_Code,
        })
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", f.File_Name);
                document.body.appendChild(link);
                link.click();
            })
            .catch((ex) => {
                console.error(ex);
            });
    };

    const handleProgress = (progress) => {
        var progressValue = (progress.loaded / progress.total) * 100;
        setProgressValue(Math.trunc(progressValue));
    };

    const handleToggleEllipsis = () => {
        setIsEllipsis(!isEllipsis);
    };

    return (
        <>
            <Col xs={12}>
                <Form.Label className={style.label}>{t("attachments")}</Form.Label>
                {isUploading && <ProgressBar value={progressValue} color="#58743A" />}
                <div className={style.fileContainer}>
                    <div className={style.fileLabel}>
                        <span>{t('chooseFile')}</span>
                        <span className={[style.uploadIcon, i18n.language == 'en' ? style.uploadIconEn : style.uploadIconAr].join(' ')}>
                            <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <Form.Control
                        className={style.file}
                        // className={style.customInput}
                        type="file"
                        onChange={onChange}
                    // required={!uploadedFiles?.length}
                    />
                    {uploadedFiles?.length > 0 && (
                        <div className={style.files}>
                            {uploadedFiles.map((uploadedFile) => (
                                <div
                                    key={uploadedFile.File_Code}
                                    className="d-flex justify-content-between mb-1"
                                >
                                    <div className="d-flex align-items-center">
                                        {getFileIcon(uploadedFile.File_Content)}
                                        <span
                                            onClick={handleToggleEllipsis}
                                            className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                                } mx-1`}
                                        >
                                            {uploadedFile.File_Name}
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-end">
                                        <span className={`${style.fileContent} mx-1`}>
                                            {uploadedFile.File_Size}
                                        </span>
                                        <span className={style.fileIcon} onClick={() => download(uploadedFile)}>
                                            <svg
                                                width="17"
                                                height="18"
                                                viewBox="0 0 17 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.5 11.9847V1.23981"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span className={style.fileIcon} onClick={() => removeFile(uploadedFile)}>
                                            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.2513 15.7083C1.2513 16.7625 2.1138 17.625 3.16797 17.625H10.8346C11.8888 17.625 12.7513 16.7625 12.7513 15.7083V4.20833H1.2513V15.7083ZM13.7096 1.33333H10.3555L9.39714 0.375H4.60547L3.64714 1.33333H0.292969V3.25H13.7096V1.33333Z" fill="#E03137" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Col>

            {/* {isUploading && <LoadingOverlay />} */}
        </>
    );
};

const Textarea = ({ defaultValue }) => {
    const { clientProfiles } = useSelector((state) => state.user);
    const { requestId, currentTaskData } = useSelector(
        (state) => state.service.updateRequest
    );
    const dispatch = useDispatch();
    const [value, setValue] = useState(defaultValue ? defaultValue : "");
    const { t, i18n } = useTranslation("common");
    const [validationError, setValidationError] = useState(false);

    const onKeyPress = (e) => {
        var ew = e.which;
        if (ew != 8) {
            if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
                setValidationError(true);
                e.preventDefault();
                return false;
            }
        }
        if (validationError) {
            setValidationError(false);
        }
        return true;
    };

    const handleChangeWithDebounce = debounce(async (e) => {
        if (e.target.value) {
            await saveAdditionalRequestInputNotes({
                Client_Profile_Id: clientProfiles?.Client_Profile_Id,
                Request_Id: requestId,
                Task_Notes: e.target.value,
                Task_Id: currentTaskData?.Task_Id,
            }).catch((err) => {
                dispatch(setError(err));
            });
            setValue(e.target.value);
        }
    }, 500);

    return (
        <>
            <Form.Control
                name={"notes"}
                defaultValue={value}
                // onKeyPress={onKeyPress}
                onChange={handleChangeWithDebounce}
                // required={true}
                as="textarea"
                className={style.customInput}
                rows={3}
            />
            {/* {validationError && (
                <Form.Text className={style.onError}>
                    {t("Write Arabic only")}
                </Form.Text>
            )} */}
        </>
    );
}

const getFileIcon = (fileType) => {
    return (
        {
            "image/jpeg": <Jpg />,
            "image/png": <Png />,
            "video/mp4": <Mp4 />,
            "application/pdf": <Pdf />,
            "application/vnd.ms-powerpoint": <Doc />,
            "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                <Doc />,
            "application/msword": <Doc />,
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                <Doc />,
            "audio/mpeg": <Mp3 />,
            "application/zip": <Zip />,
            "text/csv": <Csv />,
            "text/plain": <Text />,
        }[fileType] ?? <Attachment />
    );
}

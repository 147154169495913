import React, { useState, useEffect } from 'react'
import ActiveLicensesGrid from './ActiveLicensesGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './License.module.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons'
import Grid from "components/UIComponents/Grid"
import { Column } from "devextreme-react/data-grid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { downloadAttachment } from "api/serviceAPI";

const ActiveLicenses = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [requestData, setRequestData] = useState()
    const [clickFix, setClickFix] = useState(false)
    const { user, clientProfiles } = useSelector((state) => state.user);

    const handleClose = () => setShow(false)

    const getRequestId = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (modalData?.License_Id) {
            const res = axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/GET_CLIENT_ACTIVE_LICENSE_INFO`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'License_Id': modalData?.License_Id
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            ).then(res => {
                setRequestData(res?.data?.Data)
                setShow(true)
            })
        }
    }, [clickFix])

    const customizeDownload = ({ data }) => {
        return (
            <a
                onClick={async () => {
                    await downloadAttachment({
                        File_Code: data.License_Attachment_File_Code
                    }).then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("target", "_blank")
                        // // link.setAttribute("download", i18n.lang == 'ar' ? data.License_Attachment_Type_Name_AR : data.License_Attachment_Type_Name_EN);
                        document.body.appendChild(link);
                        link.click();
                    })
                }}
            >
                <FontAwesomeIcon
                    size="sm"
                    icon={faDownload}
                    color="black"
                />
            </a>
        );
    };

    const handleFileDownload = code => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/DOWNLOAD_RESULT_FILE`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Request_Id': modalData?.Request_Id,
                'File_Code': code
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            let a = document.createElement("a"); // Create <a>
            a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
            a.download = res.data.Data.File_Name; // File name 
            a.click(); // Download file
        })
    }

    let LicenseAttachTypeName = "License_Attachment_Type_Name_EN",
        LicenseActivityName = "License_Activity_Name_EN",
        InvestorName = "Investor_Name_EN",
        LicenseInvestorTypeName = "License_Investor_Type_Name_EN",
        NationalityName = "Nationality_Name_EN",
        LicenseSignatureTypeName = "License_Signature_Type_Name_EN";

    if (i18n.language === "ar") {
        LicenseAttachTypeName = "License_Attachment_Type_Name_AR";
        LicenseActivityName = "License_Activity_Name_AR";
        InvestorName = "Investor_Name_AR";
        LicenseInvestorTypeName = "License_Investor_Type_Name_AR";
        NationalityName = "Nationality_Name_AR";
        LicenseSignatureTypeName = "License_Signature_Type_Name_AR";
    }

    return (
        <>
            <ActiveLicensesGrid
                getRequestId={getRequestId}
                dataIsReady={props.dataIsReady}
            />

            {requestData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('completed_requests')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                License_Id: requestData.License_Data?.License_Id,
                                License_No: requestData.License_Data?.License_No,
                                License_Name_AR: requestData.License_Data?.License_Name_AR,
                                License_Name_EN: requestData.License_Data?.License_Name_EN,
                                License_Legal_Form: i18n.language === 'en' ? requestData.License_Data?.License_Legal_Form_Name_EN : requestData.License_Data?.License_Legal_Form_Name_AR,
                                License_Legal_License_Offer_NameForm: i18n.language === 'en' ? requestData.License_Data?.License_Offer_Name_EN : requestData.License_Data?.License_Offer_Name_AR,
                                License_Offer_Registration_Year: requestData.License_Data?.License_Offer_Registration_Year,
                                License_Status: i18n.language === 'en' ? requestData.License_Data?.License_Status_Name_EN : requestData.License_Data?.License_Status_Name_AR,
                                License_Segment_Name: i18n.language === 'en' ? requestData.License_Data?.License_Segment_Name_EN : requestData.License_Data?.License_Segment_Name_AR,
                                Facility_Type_Name: i18n.language === 'en' ? requestData.License_Data?.Facility_Type_Name_EN : requestData.License_Data?.Facility_Type_Name_AR,
                                Investors_Count: requestData.License_Data?.Investors_Count,
                                Company_Capital: requestData.License_Data?.Company_Capital,
                                Company_Shares_Count: requestData.License_Data?.Company_Shares_Count,
                                Facility_Type_Name: requestData.License_Data?.Facility_Type_Name,
                                License_Office_No: requestData.License_Data?.License_Office_No,
                                License_Incorporation_Date: moment(requestData.License_Data?.License_Incorporation_Date).lang("en").format('YYYY-MM-DD'),
                                License_Issue_Date: moment(requestData.License_Data?.License_Issue_Date).lang("en").format('YYYY-MM-DD'),
                                License_Expire_Date: moment(requestData.License_Data?.License_Expire_Date).lang("en").format('YYYY-MM-DD'),
                                Company_Website: requestData.License_Data?.Company_Website,
                                Company_eMail: requestData.License_Data?.Company_eMail,
                                Company_Phone: requestData.License_Data?.Company_Phone,
                            }}
                        >
                            <Form>
                                <Row style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}>
                                    <Col md={12}>
                                        <Row>
                                            <p className={style.title}>{t('licenseDataScreen')}</p>
                                        </Row>

                                        <Row>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Id')}</label>
                                                <Field className={style.invoiceField} name="License_Id" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_No')}</label>
                                                <Field className={style.invoiceField} name="License_No" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6} sm={12}>
                                                <label className={style.name}>{t('License_Name_AR')}</label>
                                                <Field className={style.invoiceField} name="License_Name_AR" type="text" readOnly />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <label className={style.name}>{t('License_Name_EN')}</label>
                                                <Field className={style.invoiceField} name="License_Name_EN" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6} sm={12}>
                                                <label className={style.name}>{t('License_Legal_Form')}</label>
                                                <Field className={style.invoiceField} name="License_Legal_Form" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Offer_Name')}</label>
                                                <Field className={style.invoiceField} name="License_Offer_Name" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Offer_Registration_Year')}</label>
                                                <Field className={style.invoiceField} name="License_Offer_Registration_Year" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6} sm={12}>
                                                <label className={style.name}>{t('License_Status')}</label>
                                                <Field className={style.invoiceField} name="License_Status" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Segment_Name')}</label>
                                                <Field className={style.invoiceField} name="License_Segment_Name" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Facility_Type_Name')}</label>
                                                <Field className={style.invoiceField} name="Facility_Type_Name" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Investors_Count')}</label>
                                                <Field className={style.invoiceField} name="Investors_Count" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Company_Capital')}</label>
                                                <Field className={style.invoiceField} name="Company_Capital" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Company_Shares_Count')}</label>
                                                <Field className={style.invoiceField} name="Company_Shares_Count" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Facility_Type_Name')}</label>
                                                <Field className={style.invoiceField} name="Company_Share_Value" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Office_No')}</label>
                                                <Field className={style.invoiceField} name="License_Office_No" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Incorporation_Date')}</label>
                                                <Field className={style.invoiceField} name="License_Incorporation_Date" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Release_Date')}</label>
                                                <Field className={style.invoiceField} name="License_Issue_Date" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('License_Expire_Date')}</label>
                                                <Field className={style.invoiceField} name="License_Expire_Date" type="text" readOnly />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Company_Website')}</label>
                                                <Field className={style.invoiceField} name="Company_Website" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Company_eMail')}</label>
                                                <Field className={style.invoiceField} name="Company_eMail" type="text" readOnly />
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <label className={style.name}>{t('Company_Phone')}</label>
                                                <Field className={style.invoiceField} name="Company_Phone" type="text" readOnly />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row>
                                            <p className={[style.title, 'mb-0'].join(' ')}>{t('licenseِِActivitiesList')}</p>
                                            <Grid
                                                sourceData={requestData?.Activities_Data}
                                                addEnabled={false}
                                                editEnabled={false}
                                                deleteEnabled={false}
                                                viewEnabled={false}
                                                SearchPanelEnable={false}
                                                ColumnChooserEnable={false}
                                            >
                                                <Column caption="ISIC" alignment="center" dataField="License_Activity_ISIC" dataType="string" />
                                                <Column caption={t('activityName')} alignment="center" dataField={LicenseActivityName} dataType="string" />
                                            </Grid>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row>
                                            <p className={[style.title, 'mb-0'].join(' ')}>{t('licenseِِAttachmentsList')}</p>
                                            <Grid
                                                sourceData={requestData?.Attachments_Data}
                                                addEnabled={false}
                                                editEnabled={false}
                                                deleteEnabled={false}
                                                viewEnabled={false}
                                                SearchPanelEnable={false}
                                                ColumnChooserEnable={false}
                                            >
                                                <Column caption={t('attachmentFileType')} alignment="center" dataField={LicenseAttachTypeName} dataType="string" />
                                                <Column caption={t('download')} alignment="center" dataType="string" dataField="License_Attachment_File_Code" cellRender={customizeDownload} />
                                            </Grid>
                                        </Row>
                                    </Col>

                                    <Col md={12}>
                                        <Row>
                                            <p className={[style.title, 'mb-0'].join(' ')}>{t('investorsList')}</p>
                                            <Grid
                                                sourceData={requestData?.Investors_Data}
                                                addEnabled={false}
                                                editEnabled={false}
                                                deleteEnabled={false}
                                                viewEnabled={false}
                                                SearchPanelEnable={false}
                                                ColumnChooserEnable={false}
                                            >
                                                <Column caption={t('investorName')} alignment="center" dataField={InvestorName} dataType="string" />
                                                <Column caption={t('investorType')} alignment="center" dataField={LicenseInvestorTypeName} dataType="string" />
                                                <Column caption={t('nationality')} alignment="center" dataField={NationalityName} dataType="string" />
                                                <Column caption={t('partnerRatio')} alignment="center" dataField="Investor_Partner_Ratio" dataType="string" />
                                                <Column caption={t('sharesCount')} alignment="center" dataField="Investor_Shares_Count" dataType="string" />
                                                <Column caption={t('sharesValue')} alignment="center" dataField="Investor_Shares_Value" dataType="string" />
                                                <Column caption={t('signatureType')} alignment="center" dataField={LicenseSignatureTypeName} dataType="string" />
                                            </Grid>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>
                    </Container >
                </PopUp>
                : null}
        </>
    )
}

export default React.memo(ActiveLicenses)

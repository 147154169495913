const Inspection = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M4.14465 26.4169C4.52908 29.8553 7.32275 32.5821 10.7778 32.7492C13.1656 32.8638 15.6035 32.9235 18.2515 32.9235C20.8995 32.9235 23.3374 32.8638 25.7252 32.7492C29.1803 32.5821 31.9739 29.8529 32.3584 26.4169C32.6186 24.1008 32.8311 21.7297 32.8311 19.3133C32.8311 16.8945 32.6162 14.5235 32.3584 12.2074C31.9739 8.76901 29.1803 6.04219 25.7252 5.87505C23.3374 5.76044 20.8995 5.70074 18.2515 5.70074C15.6035 5.70074 13.1656 5.76044 10.7778 5.87505C7.32275 6.04219 4.52908 8.7714 4.14465 12.2074C3.88439 14.5235 3.67188 16.8945 3.67188 19.3133C3.67188 21.7297 3.88677 24.1032 4.14465 26.4169Z" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.4414 3.07657V9.04596" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.5664 3.07657V9.04596" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.8203 16.725H26.3713" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.8203 23.8882H19.2081" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8555 16.725H12.0493" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8555 23.8882H12.0493" stroke="#94AFD5" stroke-width="2.57143" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Inspection;

import React, { useEffect, useState, useRef } from "react";
import { Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import FormWrapper from "components/UIComponents/Login/FormWrapper";
import InputField from "components/UIComponents/Login/Input";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageUpload from "components/UIComponents/Form/ImageUpload";
import { updateProfileImage } from "api/profileAPI";
import { useSelector } from "react-redux";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import profileStyle from "./Profile.module.scss";
import { FormText } from "react-bootstrap";
import InputMask from "react-input-mask";
import SelectField from "components/UIComponents/Login/Select";
import * as Yup from "yup";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faUpload,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "features/user/userSlice";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DateBox from "devextreme-react/date-box";
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";

const EIDRegExp = /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/;
const EIDRegExp2 = /^784[0-9]{4}[0-9]{7}[0-9]{1}$/;
const arRegExp = /^[\u0621-\u064A0-9\-_.@,\s ]+$/;

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const Profile = () => {
  const { t, i18n } = useTranslation(["auth", "common"]);
  const { user, clientProfiles } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [notifLang, setNotifLang] = useState([]);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [banks, setBanks] = useState([]);
  const [userType, setUserType] = useState("");
  const { governmentEntities, emirates } = useSelector(
    (state) => state.login.register
  );
  const [clientEIDFile, setClientEIDFile] = useState("");
  const [governmentApprovalFile, setGovernmentApprovalFile] = useState("");
  const [businessCardFileCode, setBusinessCardFileCode] = useState("");
  const [licenseFile, setLicenseFile] = useState("");
  const [strDate, setStrDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [clientEIDVal, setClientEIDVal] = useState({});
  const [accountDataEID, setAccountDataEID] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [bankVal, setBankVal] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const submitButton = useRef();
  const EIDRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [accountDataEidValidation, setAccountDataEidValidation] =
    useState(true);
  const [dateOfBirthValidation, setdateOfBirthValidation] = useState(true);
  const [bankNameValidation, setBankNameValidation] = useState(true);
  const [passwordMsg, setPasswordMsg] = useState({
    type: "",
    msg: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [activeNav, setActiveNav] = useState("first");

  useEffect(() => {
    getCLientProfileData();
    getNotificationsLang();
    getBanks();
  }, []);

  useEffect(() => {
    setUserType(data?.Client_profile?.Dealing_Type_Id);
    setClientEIDVal({
      value: data?.Client_EID ? data?.Client_EID : "",
      mask: "784-9999-9999999-9",
    });
    setAccountDataEID({
      value: data?.Client_profile?.Collection_Identity_No
        ? data?.Client_profile?.Collection_Identity_No
        : "",
      mask: "784-9999-9999999-9",
    });
    setStrDate(
      data?.Client_profile?.License_Issue_Date
        ? moment(data?.Client_profile?.License_Issue_Date)
          .lang("en")
          .format("YYYY-MM-DD")
        : ""
    );
    setEndDate(
      data?.Client_profile?.License_Expired_Date
        ? moment(data?.Client_profile?.License_Expired_Date)
          .lang("en")
          .format("YYYY-MM-DD")
        : ""
    );
    setDateOfBirth(
      data?.Client_profile?.Collection_Birthdate
        ? moment(data?.Client_profile?.Collection_Birthdate)
          .lang("en")
          .format("YYYY-MM-DD")
        : ""
    );
  }, [data]);

  useEffect(() => {
    let bankInitialVal = banks?.find(
      (b) => b.Record_Id == data?.Client_profile?.Bank_Id
    );
    if (bankInitialVal != undefined) {
      setBankVal(bankInitialVal);
    }
  }, [data, banks]);

  const getCLientProfileData = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/PROFILES/GET_CLIENT_PROFILE_FULL_DATA`,
      {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
    setData(res.data.Data);
  };

  const getNotificationsLang = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_LANGUAGUES`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
    setNotifLang(res.data.Data);
  };

  const getBanks = async () => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_BANK`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((result) => setBanks(result?.data?.Data));
  };

  const onUserImageSubmit = async (file) => {
    const data = new FormData();
    setIsUploading(true);
    data.append("Profile_Image_File", file);
    const response = await updateProfileImage(data)
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsUploading(false);
      });
    if (response?.Status) {
      return response?.Data;
    }
  };

  const handleEIDMask = (val) => {
    let newState = {
      mask: "784-9999-9999999-9",
      value: val,
    };
    if (/^3[47]/.test(val)) {
      newState.mask = "784-9999-9999999-9";
    }
    setClientEIDVal(newState);
  };

  const handleAccountDataEIDMask = (val) => {
    let newState = {
      mask: "784-9999-9999999-9",
      value: val,
    };
    if (/^3[47]/.test(val)) {
      newState.mask = "784-9999-9999999-9";
    }
    setAccountDataEID(newState);
  };

  const handlePasswordChange = async (oldPassword, newPassword) => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/SYSTEM/USERS/PROFILES/CHANGE_PASSWORD`,
        {
          Current_Password: oldPassword,
          New_Password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.Status) {
          setPasswordMsg({
            msg:
              i18n.language == "ar"
                ? "تم تعديل كلمه السر و سوف يتم تسجيل الخروج من النظام"
                : "The password has been modified and you will be logged out of the system",
            type: "success",
          });
          setTimeout(() => {
            dispatch(logout());
            history.push("/homePage");
          }, 2000);
        } else {
          if (i18n.language == "ar") {
            setPasswordMsg({ msg: res.data.Error_AR, type: "error" });
          } else {
            setPasswordMsg({ msg: res.data.Error_EN, type: "error" });
          }
        }
      })
      .catch((err) => {
        if (i18n.language == "ar") {
          err.response.data.Data[0].Errors_AR.map((err) =>
            setPasswordMsg({ msg: err, type: "error" })
          );
        } else {
          err.response.data.Data[0].Errors_EN.map((err) =>
            setPasswordMsg({ msg: err, type: "error" })
          );
        }
      });
  };

  const validationSchema = Yup.object(
    userType == "1"
      ? {
        Client_Name_AR: Yup.string()
          .matches(arRegExp, "must be a arabic name")
          .required("This field is required")
          .nullable(),
        Client_Name_EN: Yup.string()
          .required("This field is required")
          .nullable(),
        Client_EID: Yup.string()
          .required("This field is required")
          .test("Client_EID", "Invalid EID", (value) => {
            if (value === EIDRegExp || value === EIDRegExp2) {
              return false;
            }
            return true;
          }),
        Client_Phone: Yup.string()
          .required("This field is required")
          .nullable(),
        Client_Mail: Yup.string()
          .required("This field is required")
          .email("must be a valid email")
          .nullable(),
        Lang_Id: Yup.string().required("This field is required").nullable(),

        // Client_EID_File: Yup.string().required("This field is required"),

        Bank_Id: Yup.string().required("This field is required").nullable(),
        iban: Yup.string().required("This field is required").nullable(),
        account_eng_name: Yup.string()
          .required("This field is required")
          .nullable(),
        num_linked_to_account: Yup.string()
          .required("This field is required")
          .nullable(),
        account_address: Yup.string()
          .required("This field is required")
          .nullable(),

        collection_account_num: Yup.string()
          .required("This field is required")
          .nullable(),
        account_data_eid: Yup.string()
          .required("This field is required")
          .test("account_data_eid", "Invalid EID", (value) => {
            if (
              value === EIDRegExp ||
              value === EIDRegExp2 ||
              value?.replace(/[^0-9]/g, "")?.length == 15
            ) {
              setAccountDataEidValidation(true);
              return false;
            }
            setAccountDataEidValidation(false);
            return true;
          }),
        english_name: Yup.string()
          .required("This field is required")
          .nullable(),
        arabic_name: Yup.string()
          .required("This field is required")
          .nullable(),
        account_data_email: Yup.string()
          .required("This field is required")
          .email("must be a valid email")
          .nullable(),
        account_data_phone: Yup.string()
          .required("This field is required")
          .nullable(),
        date_of_birth: Yup.string()
          .required("This field is required")
          .nullable(),
      }
      : userType == "2" || userType == "3"
        ? {
          License_Name_AR: Yup.string()
            .matches(arRegExp, "must be a arabic name")
            .required("This field is required")
            .nullable(),
          License_Name_EN: Yup.string()
            .required("This field is required")
            .nullable(),

          License_No: Yup.string()
            .required("This field is required")
            .nullable(),

          License_Type: Yup.string()
            .required("This field is required")
            .nullable(),
          Client_EID: Yup.string()
            .required("This field is required")
            .test("Client_EID", "Invalid EID", (value) => {
              if (value === EIDRegExp || value === EIDRegExp2) {
                return false;
              }
              return true;
            }),

          License_Issue_Date: Yup.string()
            .required("This field is required")
            .nullable(),
          License_Expired_Date: Yup.string()
            .required("This field is required")
            .nullable(),

          Official_Mobile: Yup.string()
            .required("This field is required")
            .nullable(),
          Official_Mail: Yup.string()
            .email("must be a valid email")
            .required("This field is required")
            .nullable(),

          Lang_Id: Yup.string().required("This field is required").nullable(),

          // Client_EID_File: Yup.string().required("This field is required"),
          // License_File: Yup.string().required("This field is required"),

          Bank_Id: Yup.string().required("This field is required").nullable(),
          iban: Yup.string().required("This field is required").nullable(),
          account_eng_name: Yup.string()
            .required("This field is required")
            .nullable(),
          num_linked_to_account: Yup.string()
            .required("This field is required")
            .nullable(),
          account_address: Yup.string()
            .required("This field is required")
            .nullable(),

          collection_account_num: Yup.string()
            .required("This field is required")
            .nullable(),
          account_data_eid: Yup.string()
            .required("This field is required")
            .test("account_data_eid", "Invalid EID", (value) => {
              if (
                value === EIDRegExp ||
                value === EIDRegExp2 ||
                value?.replace(/[^0-9]/g, "")?.length == 15
              ) {
                setAccountDataEidValidation(true);
                return false;
              }
              setAccountDataEidValidation(false);
              return true;
            }),
          english_name: Yup.string()
            .required("This field is required")
            .nullable(),
          arabic_name: Yup.string()
            .required("This field is required")
            .nullable(),
          account_data_email: Yup.string()
            .required("This field is required")
            .email("must be a valid email")
            .nullable(),
          account_data_phone: Yup.string()
            .required("This field is required")
            .nullable(),
          date_of_birth: Yup.string()
            .required("This field is required")
            .nullable(),
        }
        : userType == "4"
          ? {
            Client_Name_AR: Yup.string()
              .matches(arRegExp, "must be a arabic name")
              .required("This field is required")
              .nullable(),
            Client_Name_EN: Yup.string()
              .required("This field is required")
              .nullable(),
            Government_Entity_Id: Yup.string()
              .required("This field is required")
              .nullable(),
            Client_EID: Yup.string()
              .required("This field is required")
              .test("Client_EID", "Invalid EID", (value) => {
                if (value === EIDRegExp || value === EIDRegExp2) {
                  return false;
                }
                return true;
              }),
            Official_Mobile: Yup.string()
              .required("This field is required")
              .nullable(),
            Official_Mail: Yup.string()
              .required("This field is required")
              .email("must be a valid email")
              .nullable(),

            Lang_Id: Yup.string().required("This field is required").nullable(),

            // Business_Card_File: Yup.string().required("This field is required"),
            // Client_EID_File: Yup.string().required("This field is required"),

            Bank_Id: Yup.string().required("This field is required").nullable(),
            iban: Yup.string().required("This field is required").nullable(),
            account_eng_name: Yup.string()
              .required("This field is required")
              .nullable(),
            num_linked_to_account: Yup.string()
              .required("This field is required")
              .nullable(),
            account_address: Yup.string()
              .required("This field is required")
              .nullable(),

            collection_account_num: Yup.string()
              .required("This field is required")
              .nullable(),
            account_data_eid: Yup.string()
              .required("This field is required")
              .test("account_data_eid", "Invalid EID", (value) => {
                if (
                  value === EIDRegExp ||
                  value === EIDRegExp2 ||
                  value?.replace(/[^0-9]/g, "")?.length == 15
                ) {
                  setAccountDataEidValidation(true);
                  return false;
                }
                setAccountDataEidValidation(false);
                return true;
              }),
            english_name: Yup.string()
              .required("This field is required")
              .nullable(),
            arabic_name: Yup.string()
              .required("This field is required")
              .nullable(),
            account_data_email: Yup.string()
              .required("This field is required")
              .email("must be a valid email")
              .nullable(),
            account_data_phone: Yup.string()
              .required("This field is required")
              .nullable(),
            date_of_birth: Yup.string()
              .required("This field is required")
              .nullable(),
          }
          : userType == "5"
            ? {
              License_Name_AR: Yup.string()
                .matches(arRegExp, "must be a arabic name")
                .required("This field is required")
                .nullable(),
              License_Name_EN: Yup.string()
                .required("This field is required")
                .nullable(),
              License_No: Yup.string()
                .required("This field is required")
                .nullable(),

              License_Activities: Yup.string()
                .required("This field is required")
                .nullable(),
              Emirate_Id: Yup.string()
                .required("This field is required")
                .nullable(),
              Client_EID: Yup.string()
                .required("This field is required")
                .test("Client_EID", "Invalid EID", (value) => {
                  if (value === EIDRegExp || value === EIDRegExp2) {
                    return false;
                  }
                  return true;
                }),
              License_Issue_Date: Yup.string()
                .required("This field is required")
                .nullable(),
              License_Expired_Date: Yup.string()
                .required("This field is required")
                .nullable(),

              Official_Mobile: Yup.string()
                .required("This field is required")
                .nullable(),
              Official_Mail: Yup.string()
                .email("must be a valid email")
                .required("This field is required")
                .nullable(),

              Lang_Id: Yup.string().required("This field is required").nullable(),

              // License_File: Yup.string().required("This field is required"),
              // Client_EID_File: Yup.string().required("This field is required"),

              Bank_Id: Yup.string().required("This field is required").nullable(),
              iban: Yup.string().required("This field is required").nullable(),
              account_eng_name: Yup.string()
                .required("This field is required")
                .nullable(),
              num_linked_to_account: Yup.string()
                .required("This field is required")
                .nullable(),
              account_address: Yup.string()
                .required("This field is required")
                .nullable(),

              collection_account_num: Yup.string()
                .required("This field is required")
                .nullable(),
              account_data_eid: Yup.string()
                .required("This field is required")
                .test("account_data_eid", "Invalid EID", (value) => {
                  if (
                    value === EIDRegExp ||
                    value === EIDRegExp2 ||
                    value?.replace(/[^0-9]/g, "")?.length == 15
                  ) {
                    setAccountDataEidValidation(true);
                    return false;
                  }
                  setAccountDataEidValidation(false);
                  return true;
                }),
              english_name: Yup.string()
                .required("This field is required")
                .nullable(),
              arabic_name: Yup.string()
                .required("This field is required")
                .nullable(),
              account_data_email: Yup.string()
                .required("This field is required")
                .email("must be a valid email")
                .nullable(),
              account_data_phone: Yup.string()
                .required("This field is required")
                .nullable(),
              date_of_birth: Yup.string()
                .required("This field is required")
                .nullable(),
            }
            : false
  );

  const downloadFile = async (code) => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/PROFILES/DOWNLOAD_CLIENT_PROFILE_ATTACHMENT`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          File_Code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        let a = document.createElement("a"); // Create <a>
        a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64
        a.download = res.data.Data.File_Name; // File name
        a.click(); // Download file
      });
  };

  const onSubmit = async (values, setFieldValue, tabNum) => {
    let bodyData = new FormData();
    let URL =
      userType == "1"
        ? "PORTAL/CLIENTS/PROFILES/UPD_CLIENT_INDIVIDUAL_PROFILE"
        : userType == "2"
          ? "PORTAL/CLIENTS/PROFILES/UPD_CLIENT_CONSULTATIVE_PROFILE"
          : userType == "3"
            ? "PORTAL/CLIENTS/PROFILES/UPD_CLIENT_CONTRACTOR_PROFILE"
            : userType == "4"
              ? "PORTAL/CLIENTS/PROFILES/UPD_CLIENT_GOVERNMENTENTITY_PROFILE"
              : userType == "5"
                ? "PORTAL/CLIENTS/PROFILES/UPD_CLIENT_OTHERS_PROFILE"
                : false;

    setIsSubmiting(true);

    if (userType == "1") {
      let paramsData = {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Client_Name_AR: values.Client_Name_AR,
        Client_Name_EN: values.Client_Name_EN,
        client_Phone: values.Client_Phone,
        client_Mail: values.Client_Mail,
        client_EID: values.Client_EID,
        lang_Id: values.Lang_Id,
      };

      bodyData.append("PARAMETERS", JSON.stringify(paramsData));
      bodyData.append("Client_EID_File", clientEIDFile);
    }

    if (userType == "2" || userType == "3") {
      let paramsData = {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        lang_Id: values.Lang_Id,
        license_Name_AR: values.License_Name_AR,
        license_Name_En: values.License_Name_EN,
        license_Type: values.License_Type,
        license_Category: values.License_Type,
        official_Mobile: values.Official_Mobile,
        official_Land_Phone: values.Official_Land_Phone,
        post_Box_No: values.Post_Box_No,
        official_Mail: values.Official_Mail,
        client_EID: values.Client_EID,
        License_Issue_Date: strDate,
        License_Expired_Date: endDate,
        License_No: values.License_No,
      };

      bodyData.append("PARAMETERS", JSON.stringify(paramsData));
      bodyData.append("License_File", licenseFile);
      bodyData.append("Government_Approval_File", governmentApprovalFile);
      bodyData.append("Client_EID_File", clientEIDFile);
    }

    if (userType == "4") {
      let paramsData = {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        lang_Id: values.Lang_Id,
        Client_Name_AR: values.Client_Name_AR,
        Client_Name_EN: values.Client_Name_EN,
        Government_Entity_Id: values.Government_Entity_Id,
        official_Mobile: values.Official_Mobile,
        client_EID: values.Client_EID,
        official_Mail: values.Official_Mail,
      };

      bodyData.append("PARAMETERS", JSON.stringify(paramsData));
      bodyData.append("Government_Approval_File", governmentApprovalFile);
      bodyData.append("Business_Card_File", businessCardFileCode);
      bodyData.append("Client_EID_File", clientEIDFile);
    }

    if (userType == "5") {
      let paramsData = {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        lang_Id: values.Lang_Id,
        license_Name_AR: values.License_Name_AR,
        license_Name_En: values.License_Name_EN,
        Emirates_ID: values.Emirate_Id,
        License_Activities: values.License_Activities,
        official_Mobile: values.Official_Mobile,
        official_Land_Phone: values.Official_Land_Phone,
        post_Box_No: values.Post_Box_No,
        official_Mail: values.Official_Mail,
        client_EID: values.Client_EID,
        License_Issue_Date: strDate,
        License_Expired_Date: endDate,
        License_No: values.License_No,
      };

      bodyData.append("PARAMETERS", JSON.stringify(paramsData));
      bodyData.append("License_File", licenseFile);
      bodyData.append("Client_EID_File", clientEIDFile);
    }

    const res = await axios
      .post(`${process.env.REACT_APP_API_URL}/${URL}`, bodyData, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      })
      .finally(() => {
        setIsSubmiting(false);
        if (tabNum === "fourth") {
          setFieldValue("Client_EID_File", "");
          setFieldValue("License_File", "");
          setFieldValue("Government_Approval_File", "");
          setFieldValue("Business_Card_File", "");
          setClientEIDFile("");
          setLicenseFile("");
          setGovernmentApprovalFile("");
          setBusinessCardFileCode("");
        }
        setEditMode(false);
      });

    getCLientProfileData();
    return false;
  };

  const handleBankingInfo = async (values) => {
    let bodyData = new FormData();
    let URL = "PORTAL/CLIENTS/PROFILES/SET_CLIENT_PROFILE_BANKING_INFO";
    setIsSubmiting(true);

    let paramsData = {
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Bank_Id: values.Bank_Id,
      IBAN: values.iban,
      Account_Name_EN: values.account_eng_name,
      Account_Mobile_No: values.num_linked_to_account,
      Account_Address: values.account_address,
    };

    const res = await axios
      .post(`${process.env.REACT_APP_API_URL}/${URL}`, paramsData, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      })
      .finally(() => {
        setIsSubmiting(false);
        setEditMode(false);
      });

    return false;
  };

  const handleCollectionsData = async (values) => {
    let bodyData = new FormData();
    let URL = "PORTAL/CLIENTS/PROFILES/SET_CLIENT_PROFILE_COLLECTION_ACCOUNT";
    setIsSubmiting(true);

    let paramsData = {
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Collection_Identity_No: values.account_data_eid,
      Collection_Name_AR: values.arabic_name,
      Collection_Name_EN: values.english_name,
      Collection_Mobile: values.account_data_phone,
      Collection_Email: values.account_data_email,
      Collection_Birthdate: dateOfBirth,
      Collection_Account_No: values.collection_account_num,
    };

    const res = await axios
      .post(`${process.env.REACT_APP_API_URL}/${URL}`, paramsData, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      })
      .finally(() => {
        setIsSubmiting(false);
        setEditMode(false);
      });

    return false;
  };

  const handleFileValue = (item) => {
    // if (inputsDataValue?.length) {
    //   const fileValue = inputsDataValue.filter(
    //     (fileValue) =>
    //       fileValue?.Department_Service_Detail_Id ===
    //       item.Department_Service_Detail_Id
    //   );

    //   if (fileValue) return fileValue[0]?.Files_Data;
    //   else {
    //     return null;
    //   }
    // }
    // return null;
  };

  return (
    <>
      <Header />

      <InnerTitleCont
        breadCrumbs={
          <>
            <span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
              </svg>
            </span>
            <span>
              <svg
                style={{
                  margin: '0 8px',
                  transform: i18n.language == 'ar' && 'scale(-1)'
                }}
                width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span>{t("common:Client Porfile")}</span>
          </>
        }
        title={t("common:Client Porfile")}
        startBtn={false}
        leaveBtn={false}
      />

      <Container fluid="lg" className={profileStyle.profile}>
        <Row>
          {data?.Client_profile?.Profile_Notes ? (
            <div className={profileStyle.notes}>
              <p>{data?.Client_profile?.Profile_Notes}</p>
            </div>
          ) : (
            false
          )}
        </Row>

        <Row className="my-3">
          <Col md={8} className="d-flex align-items-center">
            {editMode ? (
              <p className={profileStyle.notice}>{t("edit notice")}</p>
            ) : (
              false
            )}
          </Col>

          <Col md={4} className="d-flex justify-content-end">
            <button
              className={profileStyle.editBtn}
              onClick={() => setEditMode(true)}
            >
              {t("editData")}
            </button>
          </Col>
        </Row>

        <Row className={[profileStyle.profileCont, "mb-5"]}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Col>
              <FormWrapper
                validation={validationSchema}
                initialValues={{
                  Client_Name_AR: data?.Client_Name_AR,
                  Client_Name_EN: data?.Client_Name_EN,
                  License_Name_AR: data?.Client_profile?.License_Name_AR,
                  License_Name_EN: data?.Client_profile?.License_Name_EN,
                  Client_EID: data?.Client_EID,
                  License_Type: data?.Client_profile?.License_Type,
                  License_No: data?.Client_profile?.License_No,
                  Government_Entity_Id:
                    data?.Client_profile?.Government_Entity_Id,
                  Lang_Id: data?.Lang_Id,
                  License_Issue_Date: moment(
                    data?.Client_profile?.License_Issue_Date
                  )
                    .lang("en")
                    .format("YYYY-MM-DD"),
                  License_Expired_Date: moment(
                    data?.Client_profile?.License_Expired_Date
                  )
                    .lang("en")
                    .format("YYYY-MM-DD"),
                  License_Activities: data?.Client_profile?.License_Activities,
                  Emirate_Id: data?.Client_profile?.Emirate_Id,
                  Client_Mail: data?.Client_Mail,
                  Client_Phone: data?.Client_Phone,
                  Official_Mail: data?.Client_profile?.Official_Mail,
                  Official_Mobile: data?.Client_profile?.Official_Mobile,
                  Official_Land_Phone:
                    data?.Client_profile?.Official_Land_Phone,
                  Post_Box_No: data?.Client_profile?.Post_Box_No,
                  Login_Name: data?.Login_Name,
                  Login_Password: data?.Login_Password,
                  User_Image: data?.Client_Image,
                  Old_Password: "",
                  New_Password: "",
                  Client_EID_File_Code: data?.Client_EID_File_Code,
                  Client_EID_File_Name: data?.Client_EID_File_Name,
                  License_File_Code: data?.Client_profile?.License_File_Code,
                  License_File_Name: data?.Client_profile?.License_File_Name,
                  Government_Approval_File_Code:
                    data?.Client_profile?.Government_Approval_File_Code,
                  Government_Approval_File_Name:
                    data?.Client_profile?.Government_Approval_File_Name,
                  Business_Card_File_Code:
                    data?.Client_profile?.Business_Card_File_Code,
                  Business_Card_File_Name:
                    data?.Client_profile?.Business_Card_File_Name,

                  collection_account_num:
                    data?.Client_profile?.Collection_Account_No,
                  account_data_eid:
                    data?.Client_profile?.Collection_Identity_No,
                  arabic_name: data?.Client_profile?.Collection_Name_AR,
                  english_name: data?.Client_profile?.Collection_Name_EN,
                  account_data_email: data?.Client_profile?.Collection_Email,
                  account_data_phone: data?.Client_profile?.Collection_Mobile,
                  date_of_birth: moment(
                    data?.Client_profile?.Collection_Birthdate
                  )
                    .lang("en")
                    .format("YYYY-MM-DD"),

                  Bank_Id: data?.Client_profile?.Bank_Id,
                  iban: data?.Client_profile?.IBAN,
                  account_eng_name: data?.Client_profile?.Account_Name_EN,
                  num_linked_to_account:
                    data?.Client_profile?.Account_Mobile_No,
                  account_address: data?.Client_profile?.Account_Address,
                }}
              >
                {({ setFieldValue, values, errors, resetForm, touched }) => (
                  <Form className="row">
                    <Col md={4} className="bg-white">
                      <Row
                        className={
                          i18n.language === "ar"
                            ? profileStyle.imgMainContAr
                            : profileStyle.imgMainContEn
                        }
                      >
                        <Col className="justify-content-center align-items-center">
                          <div className={profileStyle.imgCont}>
                            <Field
                              type="file"
                              id="User_Image"
                              name="User_Image"
                              setFieldValue={setFieldValue}
                              component={ImageUpload}
                              onSubmit={onUserImageSubmit}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ background: "#eee" }}>
                        <Nav
                          variant="pills"
                          className={[
                            i18n.language === "ar"
                              ? profileStyle.navAr
                              : profileStyle.navEn,
                            "flex-column w-100",
                          ]}
                        >
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => setActiveNav("first")}
                            >
                              {t("mainInfo")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => setActiveNav("second")}
                            >
                              {t("commInfo")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => setActiveNav("third")}
                            >
                              {t("loginInfo")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="fourth"
                              onClick={() => setActiveNav("fourth")}
                            >
                              {t("attatchments")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="fifth"
                              onClick={() => setActiveNav("fifth")}
                            >
                              {t("common:bank_account_info")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={profileStyle.nav}>
                            <Nav.Link
                              eventKey="sixth"
                              onClick={() => setActiveNav("sixth")}
                            >
                              {t("common:account_data_collection")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Row>
                    </Col>

                    <Col
                      md={8}
                      className={
                        i18n.language === "ar"
                          ? profileStyle.infoContAr
                          : profileStyle.infoContEn
                      }
                    >
                      <Row>
                        <Col>
                          <Tab.Content className={[profileStyle.tabs, "my-3"]}>
                            <Tab.Pane eventKey="first">
                              <Row>
                                {
                                  //Client_Name_AR
                                  userType == 1 || userType == 4 ? (
                                    <Col md={12}>
                                      <FormText className={profileStyle.label}>
                                        {t("NameAR")}
                                      </FormText>
                                      <InputField
                                        name="Client_Name_AR"
                                        id="NameAR"
                                        className={profileStyle.input}
                                        style={{ direction: "rtl" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Client_Name_EN
                                  userType == 1 || userType == 4 ? (
                                    <Col md={12}>
                                      <FormText className={profileStyle.label}>
                                        {t("NameEN")}
                                      </FormText>
                                      <InputField
                                        name="Client_Name_EN"
                                        id="NameEN"
                                        className={profileStyle.input}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //License_Name_AR
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 5 ? (
                                    <Col md={12}>
                                      <FormText className={profileStyle.label}>
                                        {t("businessnameAR")}
                                      </FormText>
                                      <InputField
                                        name="License_Name_AR"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "rtl" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //License_Name_EN
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 5 ? (
                                    <Col md={12}>
                                      <FormText className={profileStyle.label}>
                                        {t("businessNameEN")}
                                      </FormText>
                                      <InputField
                                        name="License_Name_EN"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Client_EID
                                  userType == 1 ||
                                    userType == 2 ||
                                    userType == 3 ||
                                    userType == 4 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("EID")}
                                      </FormText>
                                      <InputMask
                                        name="Client_EID"
                                        className={profileStyle.input}
                                        {...clientEIDVal}
                                        style={{ direction: "ltr" }}
                                        ref={EIDRef}
                                        onChange={(e) => {
                                          handleEIDMask(e.target.value);
                                          setFieldValue(
                                            "Client_EID",
                                            e.target.value
                                          );
                                        }}
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //License_Type
                                  userType == 2 || userType == 3 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("companyType")}
                                      </FormText>
                                      <InputField
                                        name="License_Type"
                                        className={profileStyle.input}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                <Row>
                                  <Col md={12}>
                                    <Row>
                                      {
                                        //License_No
                                        userType == 2 ||
                                          userType == 3 ||
                                          userType == 5 ? (
                                          <Col md={4}>
                                            <FormText
                                              className={profileStyle.label}
                                            >
                                              {t("licenseNumber")}
                                            </FormText>
                                            <InputField
                                              name="License_No"
                                              className={profileStyle.input}
                                              style={{ direction: "ltr" }}
                                              type="text"
                                              disabled
                                            />
                                          </Col>
                                        ) : (
                                          false
                                        )
                                      }

                                      {
                                        //License_Issue_Date
                                        userType == 2 ||
                                          userType == 3 ||
                                          userType == 5 ? (
                                          <Col md={4}>
                                            <FormText
                                              className={profileStyle.label}
                                              htmlFor="licenseStartDate"
                                            >
                                              {t("licenseStartDate")}
                                            </FormText>
                                            <DateBox
                                              className={
                                                editMode
                                                  ? profileStyle.datePicker
                                                  : profileStyle.datePickerDisabled
                                              }
                                              acceptCustomValue={false}
                                              defaultValue={
                                                strDate ? strDate : ""
                                              }
                                              dateSerializationFormat="yyyy-MM-dd"
                                              displayFormat="yyyy-MM-dd"
                                              disabled={!editMode}
                                              type="date"
                                              name="License_Issue_Date"
                                              openOnFieldClick={true}
                                              onValueChanged={(e) =>
                                                setStrDate(e.value)
                                              }
                                              onOpened={(e) => console.log(e)}
                                            />
                                          </Col>
                                        ) : (
                                          false
                                        )
                                      }

                                      {
                                        //License_Expired_Date
                                        userType == 2 ||
                                          userType == 3 ||
                                          userType == 5 ? (
                                          <Col md={4}>
                                            <FormText
                                              className={profileStyle.label}
                                              htmlFor="licenseEndtDate"
                                            >
                                              {t("licenseEndtDate")}
                                            </FormText>
                                            <DateBox
                                              className={
                                                editMode
                                                  ? profileStyle.datePicker
                                                  : profileStyle.datePickerDisabled
                                              }
                                              acceptCustomValue={false}
                                              value={endDate ? endDate : ""}
                                              dateSerializationFormat="yyyy-MM-dd"
                                              displayFormat="yyyy-MM-dd"
                                              disabled={!editMode}
                                              type="date"
                                              name="License_Expired_Date"
                                              onValueChanged={(e) =>
                                                setEndDate(e.value)
                                              }
                                            />
                                          </Col>
                                        ) : (
                                          false
                                        )
                                      }
                                    </Row>
                                  </Col>
                                </Row>

                                {
                                  //Government_Entity_Id
                                  userType == 4 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("governmentNameAR")}
                                      </FormText>
                                      <SelectField
                                        name="Government_Entity_Id"
                                        className={`${profileStyle.select}`}
                                        type="text"
                                        disabled={!editMode}
                                      >
                                        <option value="">{t("Select")}</option>
                                        {governmentEntities &&
                                          governmentEntities.map((item) => (
                                            <option
                                              key={item?.Record_Id}
                                              value={item.Record_Id}
                                            >
                                              {i18n.language == "ar"
                                                ? item?.Record_Name_AR
                                                : item?.Record_Name_EN}
                                            </option>
                                          ))}
                                      </SelectField>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //License_Activities
                                  userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("comapnyActivity")}
                                      </FormText>
                                      <InputField
                                        name="License_Activities"
                                        className={profileStyle.input}
                                        type="text"
                                        disabled={!editMode}
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Emirate_Id
                                  userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("emara")}
                                      </FormText>
                                      <SelectField
                                        name="Emirate_Id"
                                        className={profileStyle.select}
                                        type="text"
                                        disabled={!editMode}
                                      >
                                        <option value="">{t("Select")}</option>
                                        {emirates &&
                                          emirates.map((item) => (
                                            <option
                                              key={item?.Record_Id}
                                              value={item.Record_Id}
                                            >
                                              {i18n.language == "ar"
                                                ? item?.Record_Name_AR
                                                : item?.Record_Name_EN}
                                            </option>
                                          ))}
                                      </SelectField>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }
                              </Row>

                              {editMode ? (
                                <Row>
                                  <Col className="d-flex justify-content-end mt-4 mb-2">
                                    <button
                                      ref={submitButton}
                                      className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                      disabled={
                                        userType == 1
                                          ? errors.Client_Name_AR ||
                                          !values.Client_Name_AR ||
                                          errors.Client_Name_EN ||
                                          !values.Client_Name_EN ||
                                          errors.Client_EID ||
                                          !values.Client_EID ||
                                          clientEIDVal.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          ).length != 15
                                          : userType == 2
                                            ? errors.License_Name_AR ||
                                            !values.License_Name_AR ||
                                            errors.License_Name_EN ||
                                            !values.License_Name_EN ||
                                            errors.License_No ||
                                            !values.License_No ||
                                            errors.License_Issue_Date ||
                                            !values.License_Issue_Date ||
                                            errors.License_Expired_Date ||
                                            !values.License_Expired_Date ||
                                            errors.Client_EID ||
                                            !values.Client_EID ||
                                            errors.License_Type ||
                                            !values.License_Type ||
                                            !strDate ||
                                            !endDate ||
                                            clientEIDVal.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            ).length != 15
                                            : userType == 3
                                              ? errors.License_Name_AR ||
                                              !values.License_Name_AR ||
                                              errors.License_Name_EN ||
                                              !values.License_Name_EN ||
                                              errors.License_No ||
                                              !values.License_No ||
                                              errors.License_Issue_Date ||
                                              !values.License_Issue_Date ||
                                              errors.License_Expired_Date ||
                                              !values.License_Expired_Date ||
                                              errors.Client_EID ||
                                              !values.Client_EID ||
                                              errors.License_Type ||
                                              !values.License_Type ||
                                              !strDate ||
                                              !endDate ||
                                              clientEIDVal.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              ).length != 15
                                              : userType == 4
                                                ? errors.Client_Name_AR ||
                                                !values.Client_Name_AR ||
                                                errors.Client_Name_EN ||
                                                !values.Client_Name_EN ||
                                                errors.Client_EID ||
                                                !values.Client_EID ||
                                                errors.Government_Entity_Id ||
                                                !values.Government_Entity_Id ||
                                                clientEIDVal.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                ).length != 15
                                                : userType == 5
                                                  ? errors.License_Name_AR ||
                                                  !values.License_Name_AR ||
                                                  errors.License_Name_EN ||
                                                  !values.License_Name_EN ||
                                                  errors.License_No ||
                                                  !values.License_No ||
                                                  errors.License_Issue_Date ||
                                                  !values.License_Issue_Date ||
                                                  errors.License_Expired_Date ||
                                                  !values.License_Expired_Date ||
                                                  errors.Client_EID ||
                                                  !values.Client_EID ||
                                                  errors.License_Activities ||
                                                  !values.License_Activities ||
                                                  errors.Emirate_Id ||
                                                  !values.Emirate_Id ||
                                                  !strDate ||
                                                  !endDate ||
                                                  clientEIDVal.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  ).length != 15
                                                  : false
                                      }
                                      onClick={() =>
                                        onSubmit(values, setFieldValue, "first")
                                      }
                                    >
                                      {t("save")}
                                    </button>

                                    <button
                                      className={["mainBtn", profileStyle.cancelBtn].join(' ')}
                                      onClick={() => {
                                        resetForm();
                                        setEditMode(false);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                  </Col>
                                </Row>
                              ) : (
                                false
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="second">
                              <Row>
                                {
                                  //Client_Mail
                                  userType == 1 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("Email")}
                                      </FormText>
                                      <InputField
                                        name="Client_Mail"
                                        id="Client_Mail"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Client_Phone
                                  userType == 1 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("phone-number")}
                                      </FormText>
                                      <InputField
                                        name="Client_Phone"
                                        id="Client_Phone"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Official_Mail
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 4 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("Email")}
                                      </FormText>
                                      <InputField
                                        name="Official_Mail"
                                        id="Official_Mail"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Official_Mobile
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 4 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("mobleNumber")}
                                      </FormText>
                                      <InputField
                                        name="Official_Mobile"
                                        id="official_Mobile"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Official_Land_Phone
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("phoneNumber")}
                                      </FormText>
                                      <InputField
                                        name="Official_Land_Phone"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Post_Box_No
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("zipCode")}
                                      </FormText>
                                      <InputField
                                        name="Post_Box_No"
                                        className={`${profileStyle.input}`}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled={!editMode}
                                        autoComplete="off"
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Notifications_Lang
                                  userType == 1 ||
                                    userType == 2 ||
                                    userType == 3 ||
                                    userType == 4 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("common:notifications_lang")}
                                      </FormText>
                                      <SelectField
                                        name="Lang_Id"
                                        className={`${profileStyle.select}`}
                                        type="text"
                                        disabled={!editMode}
                                      >
                                        <option value="">{t("Select")}</option>
                                        {notifLang &&
                                          notifLang.map((item) => (
                                            <option
                                              key={item?.Record_Id}
                                              value={item.Record_Id}
                                            >
                                              {i18n.language == "ar"
                                                ? item?.Record_Name_AR
                                                : item?.Record_Name_EN}
                                            </option>
                                          ))}
                                      </SelectField>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }
                              </Row>

                              {editMode ? (
                                <Row>
                                  <Col className="d-flex justify-content-end mt-4 mb-2">
                                    <button
                                      ref={submitButton}
                                      className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                      disabled={
                                        userType == 1
                                          ? errors.Client_Mail ||
                                          !values.Client_Mail ||
                                          errors.Client_Phone ||
                                          !values.Client_Phone ||
                                          errors.Lang_Id ||
                                          !values.Lang_Id
                                          : userType == 2
                                            ? errors.Official_Mail ||
                                            !values.Official_Mail ||
                                            errors.Official_Mobile ||
                                            !values.Official_Mobile ||
                                            errors.Official_Land_Phone ||
                                            !values.Official_Land_Phone ||
                                            errors.Post_Box_No ||
                                            !values.Post_Box_No ||
                                            errors.Lang_Id ||
                                            !values.Lang_Id
                                            : userType == 3
                                              ? errors.Official_Mail ||
                                              !values.Official_Mail ||
                                              errors.Official_Mobile ||
                                              !values.Official_Mobile ||
                                              errors.Official_Land_Phone ||
                                              !values.Official_Land_Phone ||
                                              errors.Post_Box_No ||
                                              !values.Post_Box_No ||
                                              errors.Lang_Id ||
                                              !values.Lang_Id
                                              : userType == 4
                                                ? errors.Official_Mail ||
                                                !values.Official_Mail ||
                                                errors.Official_Mobile ||
                                                !values.Official_Mobile ||
                                                errors.Lang_Id ||
                                                !values.Lang_Id
                                                : userType == 5
                                                  ? errors.Official_Mail ||
                                                  !values.Official_Mail ||
                                                  errors.Official_Mobile ||
                                                  !values.Official_Mobile ||
                                                  errors.Official_Land_Phone ||
                                                  !values.Official_Land_Phone ||
                                                  errors.Post_Box_No ||
                                                  !values.Post_Box_No ||
                                                  errors.Lang_Id ||
                                                  !values.Lang_Id
                                                  : false
                                      }
                                      onClick={() =>
                                        onSubmit(
                                          values,
                                          setFieldValue,
                                          "second"
                                        )
                                      }
                                    >
                                      {t("save")}
                                    </button>

                                    <button
                                      className={["mainBtn", profileStyle.cancelBtn].join(' ')}
                                      onClick={() => {
                                        resetForm();
                                        setEditMode(false);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                  </Col>
                                </Row>
                              ) : (
                                false
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="third">
                              <Row>
                                {
                                  //Login_Name
                                  userType == 1 ? (
                                    <Col md={6}>
                                      <FormText
                                        className={profileStyle.label}
                                        htmlFor="loginName"
                                      >
                                        {t("loginName")}
                                      </FormText>
                                      <InputField
                                        name="Login_Name"
                                        id="loginName"
                                        className={profileStyle.input}
                                        style={{ direction: "ltr" }}
                                        type="text"
                                        disabled
                                      />
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }
                              </Row>

                              {changePassword ? (
                                <>
                                  <Row>
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("old-password")}
                                      </FormText>
                                      <InputField
                                        name="Old_Password"
                                        className={profileStyle.input}
                                        style={{ direction: "ltr" }}
                                        autoComplete="off"
                                        type={
                                          showOldPassword ? "text" : "password"
                                        }
                                      />
                                      {values.Old_Password ? (
                                        <span
                                          className={[
                                            profileStyle.passwordIcon,
                                            i18n.language === "ar"
                                              ? profileStyle.passwordIconAR
                                              : profileStyle.passwordIconEN,
                                          ].join(" ")}
                                          onClick={() =>
                                            setShowOldPassword(!showOldPassword)
                                          }
                                        >
                                          {showOldPassword ? (
                                            <svg
                                              className={`${profileStyle.eyeSvg}`}
                                              version="1.0"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="512.000000pt"
                                              height="512.000000pt"
                                              viewBox="0 0 512.000000 512.000000"
                                              preserveAspectRatio="xMidYMid meet"
                                            >
                                              <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000"
                                                stroke="none"
                                              >
                                                <path
                                                  d="M2275 4194 c-631 -96 -1266 -455 -1865 -1054 -197 -197 -345 -370
                          -379 -440 -22 -46 -26 -69 -26 -140 0 -113 24 -162 151 -309 438 -508 1011
                          -946 1508 -1154 344 -143 604 -193 960 -184 339 8 607 75 951 237 401 187 882
                          553 1252 950 261 281 288 324 288 460 0 116 -23 162 -165 325 -384 440 -832
                          803 -1271 1029 -300 154 -557 241 -839 281 -131 18 -441 18 -565 -1z m477
                          -549 c146 -26 292 -84 423 -170 77 -50 250 -223 300 -300 125 -191 184 -386
                          185 -611 1 -357 -157 -673 -445 -889 -190 -143 -410 -215 -657 -215 -610 0
                          -1099 490 -1098 1099 1 296 100 545 300 755 119 125 246 212 393 270 66 26
                          192 61 252 69 70 10 276 5 347 -8z"
                                                />
                                                <path
                                                  d="M2445 3340 c-193 -31 -373 -133 -495 -283 -62 -76 -133 -217 -157
                          -312 -24 -101 -22 -286 5 -386 75 -276 288 -487 572 -565 97 -27 290 -25 393
                          4 418 118 661 530 562 952 -21 89 -93 232 -155 307 -101 125 -271 233 -420
                          267 -84 20 -229 27 -305 16z"
                                                />
                                              </g>
                                            </svg>
                                          ) : (
                                            <svg
                                              className={`${profileStyle.eyeSvg}`}
                                              version="1.0"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="512.000000pt"
                                              height="512.000000pt"
                                              viewBox="0 0 512.000000 512.000000"
                                              preserveAspectRatio="xMidYMid meet"
                                            >
                                              <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000"
                                                stroke="none"
                                              >
                                                <path
                                                  d="M4905 5104 c-16 -9 -298 -283 -625 -610 l-595 -594 -110 54 c-847
                            418 -1660 321 -2530 -305 -333 -239 -745 -642 -994 -972 -52 -68 -63 -124 -36
                            -178 24 -47 181 -240 312 -384 229 -250 528 -516 770 -682 l72 -50 -565 -564
                            c-311 -310 -574 -581 -585 -601 -24 -48 -24 -89 1 -138 23 -45 80 -80 130 -80
                            17 0 47 9 68 19 46 25 4864 4844 4886 4888 61 123 -78 260 -199 197z m-2097
                            -1524 c46 -12 116 -35 155 -51 73 -30 217 -115 217 -127 0 -4 -47 -54 -104
                            -111 l-104 -104 -53 30 c-148 84 -337 112 -507 77 -394 -81 -656 -458 -592
                            -852 14 -82 33 -141 76 -226 l35 -70 -103 -103 c-56 -57 -106 -103 -110 -103
                            -12 0 -97 144 -127 217 -60 145 -76 232 -75 408 1 129 5 175 22 235 55 203
                            146 366 281 500 155 156 372 266 586 300 97 15 308 4 403 -20z"
                                                />
                                                <path
                                                  d="M3871 3236 l-323 -323 21 -66 c107 -348 0 -760 -268 -1028 -268 -268
                            -680 -375 -1028 -268 l-66 21 -241 -241 c-132 -132 -237 -243 -234 -246 12
                            -12 229 -85 326 -110 192 -48 283 -59 507 -59 172 1 232 5 330 23 357 65 706
                            212 1055 444 367 245 736 586 1050 972 159 195 159 215 0 410 -231 284 -582
                            633 -781 775 l-25 19 -323 -323z"
                                                />
                                                <path
                                                  d="M2876 2241 c-321 -321 -414 -420 -401 -425 55 -21 254 4 359 45 211
                            83 391 285 451 508 19 69 31 252 19 274 -8 14 -86 -60 -428 -402z"
                                                />
                                              </g>
                                            </svg>
                                          )}
                                        </span>
                                      ) : null}
                                    </Col>

                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("new-password")}
                                      </FormText>
                                      <InputField
                                        name="New_Password"
                                        className={profileStyle.input}
                                        style={{ direction: "ltr" }}
                                        autoComplete="off"
                                        type={
                                          showNewPassword ? "text" : "password"
                                        }
                                      />
                                      {values.New_Password ? (
                                        <span
                                          className={[
                                            profileStyle.passwordIcon,
                                            i18n.language === "ar"
                                              ? profileStyle.passwordIconAR
                                              : profileStyle.passwordIconEN,
                                          ].join(" ")}
                                          onClick={() =>
                                            setShowNewPassword(!showNewPassword)
                                          }
                                        >
                                          {showNewPassword ? (
                                            <svg
                                              className={`${profileStyle.eyeSvg}`}
                                              version="1.0"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="512.000000pt"
                                              height="512.000000pt"
                                              viewBox="0 0 512.000000 512.000000"
                                              preserveAspectRatio="xMidYMid meet"
                                            >
                                              <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000"
                                                stroke="none"
                                              >
                                                <path
                                                  d="M2275 4194 c-631 -96 -1266 -455 -1865 -1054 -197 -197 -345 -370
                          -379 -440 -22 -46 -26 -69 -26 -140 0 -113 24 -162 151 -309 438 -508 1011
                          -946 1508 -1154 344 -143 604 -193 960 -184 339 8 607 75 951 237 401 187 882
                          553 1252 950 261 281 288 324 288 460 0 116 -23 162 -165 325 -384 440 -832
                          803 -1271 1029 -300 154 -557 241 -839 281 -131 18 -441 18 -565 -1z m477
                          -549 c146 -26 292 -84 423 -170 77 -50 250 -223 300 -300 125 -191 184 -386
                          185 -611 1 -357 -157 -673 -445 -889 -190 -143 -410 -215 -657 -215 -610 0
                          -1099 490 -1098 1099 1 296 100 545 300 755 119 125 246 212 393 270 66 26
                          192 61 252 69 70 10 276 5 347 -8z"
                                                />
                                                <path
                                                  d="M2445 3340 c-193 -31 -373 -133 -495 -283 -62 -76 -133 -217 -157
                          -312 -24 -101 -22 -286 5 -386 75 -276 288 -487 572 -565 97 -27 290 -25 393
                          4 418 118 661 530 562 952 -21 89 -93 232 -155 307 -101 125 -271 233 -420
                          267 -84 20 -229 27 -305 16z"
                                                />
                                              </g>
                                            </svg>
                                          ) : (
                                            <svg
                                              className={`${profileStyle.eyeSvg}`}
                                              version="1.0"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="512.000000pt"
                                              height="512.000000pt"
                                              viewBox="0 0 512.000000 512.000000"
                                              preserveAspectRatio="xMidYMid meet"
                                            >
                                              <g
                                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000"
                                                stroke="none"
                                              >
                                                <path
                                                  d="M4905 5104 c-16 -9 -298 -283 -625 -610 l-595 -594 -110 54 c-847
                            418 -1660 321 -2530 -305 -333 -239 -745 -642 -994 -972 -52 -68 -63 -124 -36
                            -178 24 -47 181 -240 312 -384 229 -250 528 -516 770 -682 l72 -50 -565 -564
                            c-311 -310 -574 -581 -585 -601 -24 -48 -24 -89 1 -138 23 -45 80 -80 130 -80
                            17 0 47 9 68 19 46 25 4864 4844 4886 4888 61 123 -78 260 -199 197z m-2097
                            -1524 c46 -12 116 -35 155 -51 73 -30 217 -115 217 -127 0 -4 -47 -54 -104
                            -111 l-104 -104 -53 30 c-148 84 -337 112 -507 77 -394 -81 -656 -458 -592
                            -852 14 -82 33 -141 76 -226 l35 -70 -103 -103 c-56 -57 -106 -103 -110 -103
                            -12 0 -97 144 -127 217 -60 145 -76 232 -75 408 1 129 5 175 22 235 55 203
                            146 366 281 500 155 156 372 266 586 300 97 15 308 4 403 -20z"
                                                />
                                                <path
                                                  d="M3871 3236 l-323 -323 21 -66 c107 -348 0 -760 -268 -1028 -268 -268
                            -680 -375 -1028 -268 l-66 21 -241 -241 c-132 -132 -237 -243 -234 -246 12
                            -12 229 -85 326 -110 192 -48 283 -59 507 -59 172 1 232 5 330 23 357 65 706
                            212 1055 444 367 245 736 586 1050 972 159 195 159 215 0 410 -231 284 -582
                            633 -781 775 l-25 19 -323 -323z"
                                                />
                                                <path
                                                  d="M2876 2241 c-321 -321 -414 -420 -401 -425 55 -21 254 4 359 45 211
                            83 391 285 451 508 19 69 31 252 19 274 -8 14 -86 -60 -428 -402z"
                                                />
                                              </g>
                                            </svg>
                                          )}
                                        </span>
                                      ) : null}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col className="py-2">
                                      <small
                                        className={
                                          passwordMsg.type == "error"
                                            ? profileStyle.error
                                            : profileStyle.success
                                        }
                                      >
                                        {passwordMsg.msg}
                                      </small>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col className="d-flex justify-content-end mt-4">
                                      <button
                                        disabled={
                                          !values.New_Password ||
                                          !values.Old_Password
                                        }
                                        className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                        onClick={() =>
                                          handlePasswordChange(
                                            values.Old_Password,
                                            values.New_Password
                                          )
                                        }
                                      >
                                        {t("save")}
                                      </button>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <Row>
                                  <Col md={5}>
                                    <button
                                      onClick={() => setChangePassword(true)}
                                      className={[
                                        "mainBtn",
                                        profileStyle.changePassBtn,
                                      ].join(" ")}
                                    >
                                      {t("change password")}
                                    </button>
                                  </Col>
                                </Row>
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="fourth">
                              <Row>
                                {/* //Client_EID_File */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("IDPhoto")}
                                  </FormText>
                                  {editMode ? (
                                    <>
                                      <input
                                        name="Client_EID_File"
                                        id="Client_EID_File"
                                        accept="image/*"
                                        type="file"
                                        className={profileStyle.inputFile}
                                        disabled={!editMode}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "Client_EID_File",
                                            e.target.value
                                          );
                                          setClientEIDFile(e.target.files[0]);
                                        }}
                                      />

                                      <Row className="align-items-baseline">
                                        <Col>
                                          <input
                                            name="Client_EID_File"
                                            id="Client_EID_File"
                                            className={[profileStyle.fileUploader, i18n.language == 'en' ? profileStyle.fileUploaderEn : profileStyle.fileUploaderAr].join(' ')}
                                            value={clientEIDFile?.name || t('chooseFile')}
                                            data-file="file"
                                            disabled
                                          />

                                          <span className={[profileStyle.uploadIcon, i18n.language == 'en' ? profileStyle.uploadIconEn : profileStyle.uploadIconAr].join(' ')}>
                                            <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </span>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    false
                                  )}
                                  <Row>
                                    <Col>
                                      {values.Client_EID_File_Code ? (
                                        <div className={profileStyle.files}>
                                          <span className="d-flex align-items-center">
                                            <FontAwesomeIcon
                                              className="mx-1"
                                              size="sm"
                                              color={"#d3d3d3"}
                                              icon={faFile}
                                            />
                                            <p className={profileStyle.file}>
                                              {values.Client_EID_File_Name}
                                            </p>
                                          </span>
                                          <span
                                            className={profileStyle.downIcon}
                                          >
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="button-tooltip">
                                                  {t('download')}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                onClick={() =>
                                                  downloadFile(values.Client_EID_File_Code)
                                                }
                                              >
                                                <svg
                                                  width="17"
                                                  height="18"
                                                  viewBox="0 0 17 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M8.5 11.9847V1.23981"
                                                    stroke="#1B2124"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </div>
                                      ) : (
                                        <div className={profileStyle.files}>
                                          <p>{t("no file")}</p>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>

                                {
                                  //License_File
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 5 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("businessLicensePhoto")}
                                      </FormText>
                                      {editMode ? (
                                        <>
                                          <input
                                            name="License_File"
                                            id="License_File"
                                            accept="image/*"
                                            type="file"
                                            className={profileStyle.inputFile}
                                            disabled={!editMode}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "License_File",
                                                e.target.value
                                              );
                                              setLicenseFile(e.target.files[0]);
                                            }}
                                          />
                                          <Row className="align-items-baseline">
                                            <Col>
                                              <input
                                                name="License_File"
                                                id="License_File"
                                                value={licenseFile?.name || t('chooseFile')}
                                                data-file="file"
                                                className={[profileStyle.fileUploader, i18n.language == 'en' ? profileStyle.fileUploaderEn : profileStyle.fileUploaderAr].join(' ')}
                                                disabled
                                              />

                                              <span className={[profileStyle.uploadIcon, i18n.language == 'en' ? profileStyle.uploadIconEn : profileStyle.uploadIconAr].join(' ')}>
                                                <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                              </span>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        false
                                      )}
                                      <Row>
                                        <Col>
                                          {values.License_File_Code ? (
                                            <div className={profileStyle.files}>
                                              <span className="d-flex align-items-center">
                                                <FontAwesomeIcon
                                                  className="mx-1"
                                                  size="sm"
                                                  color={"#d3d3d3"}
                                                  icon={faFile}
                                                />
                                                <p
                                                  className={profileStyle.file}
                                                >
                                                  {values.License_File_Name}
                                                </p>
                                              </span>
                                              <span
                                                className={
                                                  profileStyle.downIcon
                                                }
                                              >
                                                {/* <FontAwesomeIcon
                                                  className="mx-1"
                                                  size="sm"
                                                  color={"#1e90ff"}
                                                  icon={faDownload}
                                                  onClick={() =>
                                                    downloadFile(
                                                      values.License_File_Code
                                                    )
                                                  }
                                                /> */}
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id="button-tooltip">
                                                      {t('download')}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span
                                                    onClick={() =>
                                                      downloadFile(values.Client_EID_File_Code)
                                                    }
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="18"
                                                      viewBox="0 0 17 18"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M8.5 11.9847V1.23981"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </span>
                                                </OverlayTrigger>
                                              </span>
                                            </div>
                                          ) : (
                                            <div className={profileStyle.files}>
                                              <p className={profileStyle.file}>
                                                {t("no file")}
                                              </p>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Government_Approval_File
                                  userType == 2 ||
                                    userType == 3 ||
                                    userType == 4 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("Employer approval letter")}
                                      </FormText>
                                      {editMode ? (
                                        <>
                                          <input
                                            name="Government_Approval_File"
                                            id="Government_Approval_File"
                                            accept="image/*"
                                            type="file"
                                            className={profileStyle.inputFile}
                                            disabled={!editMode}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "Government_Approval_File",
                                                e.target.value
                                              );
                                              setGovernmentApprovalFile(
                                                e.target.files[0]
                                              );
                                            }}
                                          />
                                          <Row className="align-items-baseline">
                                            <Col>
                                              <input
                                                name="Government_Approval_File"
                                                id="Government_Approval_File"
                                                className={[profileStyle.fileUploader, i18n.language == 'en' ? profileStyle.fileUploaderEn : profileStyle.fileUploaderAr].join(' ')}
                                                value={
                                                  governmentApprovalFile?.name ||
                                                  t('chooseFile')
                                                }
                                                data-file="file"
                                                disabled
                                              />

                                              <span className={[profileStyle.uploadIcon, i18n.language == 'en' ? profileStyle.uploadIconEn : profileStyle.uploadIconAr].join(' ')}>
                                                <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                              </span>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        false
                                      )}
                                      <Row>
                                        <Col>
                                          {values.Government_Approval_File_Code ? (
                                            <div className={profileStyle.files}>
                                              <span className="d-flex align-items-center">
                                                <FontAwesomeIcon
                                                  className="mx-1"
                                                  size="sm"
                                                  color={"#d3d3d3"}
                                                  icon={faFile}
                                                />
                                                <p
                                                  className={profileStyle.file}
                                                >
                                                  {
                                                    values.Government_Approval_File_Name
                                                  }
                                                </p>
                                              </span>
                                              <span
                                                className={
                                                  profileStyle.downIcon
                                                }
                                              >
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id="button-tooltip">
                                                      {t('download')}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span
                                                    onClick={() =>
                                                      downloadFile(values.Client_EID_File_Code)
                                                    }
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="18"
                                                      viewBox="0 0 17 18"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M5.51562 9L8.50032 11.9847L11.485 9"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M8.5 11.9847V1.23981"
                                                        stroke="#1B2124"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </span>
                                                </OverlayTrigger>
                                              </span>
                                            </div>
                                          ) : (
                                            <div className={profileStyle.files}>
                                              <p className={profileStyle.file}>
                                                {t("no file")}
                                              </p>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }

                                {
                                  //Business_Card_File
                                  userType == 4 ? (
                                    <Col md={6}>
                                      <FormText className={profileStyle.label}>
                                        {t("accountcreatorCard")}
                                      </FormText>
                                      {editMode ? (
                                        <>
                                          <input
                                            name="Business_Card_File"
                                            id="Business_Card_File"
                                            accept="image/*"
                                            type="file"
                                            className={profileStyle.inputFile}
                                            disabled={!editMode}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "Business_Card_File",
                                                e.target.value
                                              );
                                              setBusinessCardFileCode(
                                                e.target.files[0]
                                              );
                                            }}
                                          />
                                          <Row className="align-items-baseline">
                                            <Col>
                                              <input
                                                name="Business_Card_File"
                                                id="Business_Card_File"
                                                className={[profileStyle.fileUploader, i18n.language == 'en' ? profileStyle.fileUploaderEn : profileStyle.fileUploaderAr].join(' ')}
                                                value={
                                                  businessCardFileCode?.name ||
                                                  t('chooseFile')
                                                }
                                                disabled
                                              />

                                              <span className={[profileStyle.uploadIcon, i18n.language == 'en' ? profileStyle.uploadIconEn : profileStyle.uploadIconAr].join(' ')}>
                                                <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                              </span>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        false
                                      )}
                                      <Row>
                                        <Col>
                                          {values.Business_Card_File_Code ? (
                                            <div className={profileStyle.files}>
                                              <span className="d-flex align-items-center">
                                                <FontAwesomeIcon
                                                  className="mx-1"
                                                  size="sm"
                                                  color={"#d3d3d3"}
                                                  icon={faFile}
                                                />
                                                <p
                                                  className={profileStyle.file}
                                                >
                                                  {
                                                    values.Business_Card_File_Name
                                                  }
                                                </p>
                                              </span>
                                              <span
                                                className={
                                                  profileStyle.downIcon
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  className="mx-1"
                                                  size="sm"
                                                  color={"#1e90ff"}
                                                  icon={faDownload}
                                                  onClick={() =>
                                                    downloadFile(
                                                      values.Government_Approval_File_Code
                                                    )
                                                  }
                                                />
                                              </span>
                                            </div>
                                          ) : (
                                            <div className={profileStyle.files}>
                                              <p className={profileStyle.file}>
                                                {t("no file")}
                                              </p>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  ) : (
                                    false
                                  )
                                }
                              </Row>

                              {editMode ? (
                                <Row>
                                  <Col className="d-flex justify-content-end mt-4 mb-2">
                                    <button
                                      ref={submitButton}
                                      className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                      onClick={() =>
                                        onSubmit(
                                          values,
                                          setFieldValue,
                                          "fourth"
                                        )
                                      }
                                    >
                                      {t("save")}
                                    </button>

                                    <button
                                      className={["mainBtn", profileStyle.cancelBtn].join(' ')}
                                      onClick={() => {
                                        resetForm();
                                        setEditMode(false);
                                        setFieldValue("Client_EID_File", "");
                                        setFieldValue("License_File", "");
                                        setFieldValue(
                                          "Government_Approval_File",
                                          ""
                                        );
                                        setFieldValue("Business_Card_File", "");
                                        setClientEIDFile("");
                                        setLicenseFile("");
                                        setGovernmentApprovalFile("");
                                        setBusinessCardFileCode("");
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                  </Col>
                                </Row>
                              ) : (
                                false
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="fifth">
                              <Row>
                                <Col xs={12}>
                                  <p className={profileStyle.dataNote}>
                                    {t("common:data_note")}
                                  </p>
                                </Col>

                                {/* Bank_Name */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:bank_name")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <FormText
                                    className={profileStyle.error}
                                  ></FormText>
                                  <Autocomplete
                                    id="Bank_Id"
                                    name="Bank_Id"
                                    options={banks}
                                    getOptionLabel={(option) =>
                                      i18n.language == "en"
                                        ? option.Record_Name_EN
                                        : option.Record_Name_AR
                                    }
                                    className={[
                                      profileStyle.searchSele,
                                      !editMode
                                        ? profileStyle.selectDisabled
                                        : null,
                                    ].join(" ")}
                                    value={bankVal}
                                    onChange={(e, value) => {
                                      setBankVal(value);
                                      setFieldValue("Bank_Id", value.Record_Id);
                                      setBankNameValidation(
                                        !value.Record_Id && editMode
                                          ? false
                                          : true
                                      );
                                    }}
                                    onOpen={(e) => {
                                      setBankNameValidation(
                                        !e.target.value && editMode
                                          ? false
                                          : true
                                      );
                                    }}
                                    classes={classes}
                                    disabled={!editMode}
                                    disableClearable
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="Bank_Id"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <span
                                    style={{
                                      color: "#e53e3e",
                                      fontSize: "0.625rem",
                                      fontFamily: "Cairo-Regular",
                                      marginTop: "-2px",
                                      display: bankNameValidation
                                        ? "none"
                                        : "block",
                                    }}
                                  >
                                    {t("This field is required")}
                                  </span>
                                </Col>

                                {/* IBAN */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    IBAN &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="iban"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Account English Name */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:account_eng_name")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="account_eng_name"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* phone number linked to the account */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:num_linked_to_account")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="num_linked_to_account"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Account address */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:account_address")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="account_address"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>
                              </Row>

                              {editMode ? (
                                <Row>
                                  <Col className="d-flex justify-content-end mt-4 mb-2">
                                    <button
                                      className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                      disabled={
                                        errors.Bank_Id ||
                                        !values.Bank_Id ||
                                        errors.iban ||
                                        !values.iban ||
                                        errors.account_eng_name ||
                                        !values.account_eng_name ||
                                        errors.num_linked_to_account ||
                                        !values.num_linked_to_account ||
                                        errors.account_address ||
                                        !values.account_address
                                      }
                                      onClick={() => handleBankingInfo(values)}
                                    >
                                      {t("save")}
                                    </button>

                                    <button
                                      className={["mainBtn", profileStyle.cancelBtn].join(' ')}
                                      onClick={() => {
                                        // setEditMode(false)
                                        // setFieldValue("Bank_Id", '');
                                        // setFieldValue("iban", '');
                                        // setFieldValue("account_eng_name", '');
                                        // setFieldValue("num_linked_to_account", '');
                                        // setFieldValue("account_address", '');
                                        resetForm();
                                        setEditMode(false);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                  </Col>
                                </Row>
                              ) : null}
                            </Tab.Pane>

                            <Tab.Pane eventKey="sixth">
                              <Row>
                                <Col xs={12}>
                                  <p className={profileStyle.dataNote}>
                                    {t("common:data_note")}
                                  </p>
                                </Col>

                                {/* Collection Account Number */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:collection_account_num")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="collection_account_num"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Account Data Collection EID */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("EID")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputMask
                                    name="account_data_eid"
                                    className={profileStyle.input}
                                    {...accountDataEID}
                                    style={{ direction: "ltr" }}
                                    ref={EIDRef}
                                    onChange={(e) => {
                                      handleAccountDataEIDMask(e.target.value);
                                      setFieldValue(
                                        "account_data_eid",
                                        e.target.value
                                      );
                                    }}
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                  <span
                                    style={{
                                      color: "#e53e3e",
                                      fontSize: "0.625rem",
                                      fontFamily: "Cairo-Regular",
                                      marginTop: "-2px",
                                      display: accountDataEidValidation
                                        ? "none"
                                        : "block",
                                    }}
                                  >
                                    {t("This field is required")}
                                  </span>
                                </Col>

                                {/* Arabic Name */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:arabic_name")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="arabic_name"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* English Name */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:english_name")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="english_name"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Account Data Email */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:email")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="account_data_email"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Account Data Phone */}
                                <Col md={6}>
                                  <FormText className={profileStyle.label}>
                                    {t("common:phone")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <InputField
                                    name="account_data_phone"
                                    className={`${profileStyle.input}`}
                                    style={{ direction: "ltr" }}
                                    type="text"
                                    disabled={!editMode}
                                    autoComplete="off"
                                  />
                                </Col>

                                {/* Date of Birth */}
                                <Col md={6}>
                                  <FormText
                                    className={profileStyle.label}
                                    htmlFor="licenseStartDate"
                                  >
                                    {t("common:date_of_birth")}
                                    &nbsp;-&nbsp;
                                    <span className={"text-danger"}>
                                      {t("common:field_required")}*
                                    </span>
                                  </FormText>
                                  <DateBox
                                    className={
                                      editMode
                                        ? profileStyle.datePicker
                                        : profileStyle.datePickerDisabled
                                    }
                                    acceptCustomValue={false}
                                    // defaultValue={activeNav == 'sixth' ? dateOfBirth : ''}
                                    // defaultValue={dateOfBirth}
                                    value={dateOfBirth}
                                    dateSerializationFormat="yyyy-MM-dd"
                                    displayFormat="yyyy-MM-dd"
                                    disabled={!editMode}
                                    type="date"
                                    name="date_of_birth"
                                    onValueChanged={(e) => {
                                      setDateOfBirth(e.value);
                                      setdateOfBirthValidation(
                                        !e.value && editMode ? false : true
                                      );
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#e53e3e",
                                      fontSize: "0.625rem",
                                      fontFamily: "Cairo-Regular",
                                      marginTop: "-2px",
                                      display: dateOfBirthValidation
                                        ? "none"
                                        : "block",
                                    }}
                                  >
                                    {t("This field is required")}
                                  </span>
                                </Col>
                              </Row>

                              {editMode ? (
                                <Row>
                                  <Col className="d-flex justify-content-end mt-4 mb-2">
                                    <button
                                      className={["mainBtn mx-2", profileStyle.saveBtn].join(' ')}
                                      disabled={
                                        !values.collection_account_num ||
                                        !values.account_data_eid ||
                                        !values.arabic_name ||
                                        !values.english_name ||
                                        !values.account_data_email ||
                                        !values.account_data_phone ||
                                        !dateOfBirth ||
                                        accountDataEID.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ).length != 15
                                      }
                                      onClick={() =>
                                        handleCollectionsData(values)
                                      }
                                    >
                                      {t("save")}
                                    </button>

                                    <button
                                      className={["mainBtn", profileStyle.cancelBtn].join(' ')}
                                      onClick={() => {
                                        // setAccountDataEID({value: ''})
                                        // setDateOfBirth(null)
                                        // setFieldValue("collection_account_num", '');
                                        // setFieldValue("account_data_eid", '');
                                        // setFieldValue("arabic_name", '');
                                        // setFieldValue("english_name", '');
                                        // setFieldValue("account_data_email", '');
                                        // setFieldValue("account_data_phone", '');
                                        // setFieldValue("date_of_birth", '');
                                        // setEditMode(false)
                                        resetForm();
                                        setEditMode(false);
                                      }}
                                    >
                                      {t("cancel")}
                                    </button>
                                  </Col>
                                </Row>
                              ) : null}
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Col>
                  </Form>
                )}
              </FormWrapper>
            </Col>
          </Tab.Container>
        </Row>
      </Container>

      <Footer />

      {isUploading && <LoadingOverlay />}
      {isSubmitting && <LoadingOverlay />}
    </>
  );
};

export default Profile;

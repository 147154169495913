import React from "react";

function File() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#E9E9E0"
          d="M78.42 18.345v68.502A3.153 3.153 0 0175.267 90H14.733a3.153 3.153 0 01-3.153-3.153V3.153A3.153 3.153 0 0114.733 0h45.343c3.057 7.61 9.31 13.658 18.344 18.345z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#B83535"
          d="M68.193 33.319H41.808a1.5 1.5 0 110-3h26.385a1.5 1.5 0 110 3zM34.456 33.319H21.807a1.5 1.5 0 110-3h12.649a1.5 1.5 0 110 3zM42.298 20.733H21.807a1.5 1.5 0 110-3h20.492a1.5 1.5 0 11-.001 3zM68.193 44.319H21.807a1.5 1.5 0 110-3h46.387a1.5 1.5 0 11-.001 3zM48.191 55.319H21.807a1.5 1.5 0 110-3h26.385a1.5 1.5 0 01-.001 3zM68.193 55.319H55.544a1.5 1.5 0 010-3h12.649a1.5 1.5 0 010 3zM68.193 66.319H21.807a1.5 1.5 0 110-3h46.387a1.5 1.5 0 01-.001 3zM68.193 77.319H55.544a1.5 1.5 0 010-3h12.649a1.5 1.5 0 010 3z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default File;

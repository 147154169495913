import React, { useState, useEffect } from 'react'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import style from './../Dashboard.module.scss'
import { Row } from 'react-bootstrap'
import LoadingOverlay from "components/UIComponents/LoadingOverlay";

const DuePaymentsGrid = props => {

  const { t, i18n } = useTranslation('common')
  const { user, clientProfiles } = useSelector((state) => state.user);
  const [data, setData] = useState()
  const history = useHistory()

  useEffect(() => {
    const getDuePaymentsList = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_DUE_PAYMENTS_LIST`,
        {
          'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
          'PageIndex': '1',
          'PageCount': '1000'
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        }
      ).then(data => {
        setData(data.data.Data.Data)
        props.dataIsReady(false)
      })
    }
    getDuePaymentsList()
  }, [])

  let serviceName = 'Service_Name_EN',
    invoiceTypeName = 'Invoice_Type_Name_EN',
    departmentName = 'Department_Name_EN',
    areaName = "Area_Name_EN";

  if (i18n.language === 'ar') {
    serviceName = 'Service_Name_AR';
    invoiceTypeName = 'Invoice_Type_Name_AR';
    areaName = "Area_Name_AR";
    departmentName = 'Department_Name_AR';
  }

  return (
    <>
      <Row className='justify-content-center'>
        <p className={style.gridTitle}>{t("due_payment_requests")}</p>
      </Row>

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={false}
          deleteEnabled={false}
          viewEnabled={true}
          onView={({ data }) => {
            // props.handleShow(data)
            history.push({
              pathname: `/duepayment-view/${data?.Invoice_Id}`,
              state: { data: data }
            })
          }}
          onDelete={({ data }) => { }}
        >
          <Column caption={t('payment_no')} alignment="center" dataField="Payment_No" dataType="string" width="auto" />
          <Column caption={t('bond_date')} alignment="center" dataField="Invoice_Date" dataType="date" format="yyyy-MM-dd" width="auto" />
          <Column caption={t('invoice_value')} alignment="center" dataField="Invoice_Value" dataType="string" width="auto" />
          <Column caption={t('owner')} alignment="center" dataField="Owner_Name" dataType="string" width="auto" />
          <Column caption={t('department_name')} alignment="center" dataField={departmentName} dataType="string" width="auto" visible={false} />
          <Column caption={t('request_no')} alignment="center" dataField="Request_No" dataType="string" width="auto" />

          <Column caption={t('service_name')} alignment="center" dataField={serviceName} dataType="string" width="auto" visible={false} />
          <Column caption={t("project_no")} alignment="center" dataField='Project_No' dataType="string" width="auto" visible={false} />
          <Column caption={t("area_name")} alignment="center" dataField={areaName} dataType="string" width="auto" visible={false} />
          <Column caption={t("plot_no")} alignment="center" dataField='Plot_No' dataType="string" width="auto" visible={false} />
        </Grid>
      </Row>
    </>
  )
}

export default DuePaymentsGrid

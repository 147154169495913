import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "components/UIComponents/Header/Header";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import Footer from "components/UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Description from "components/pages/ServiceDetails/Description/ServiceDescription";
import ServiceMunicip from "./ServiceMunicip";
import ServiceRequest from "./ServiceRequest";
import { useParams } from "react-router-dom";
import { resetDepartment } from "./serviceMunicipSlice";
import { toState, authorizedState } from "./serviceMachineSlice";
import { fetchServiceCard, resetStates } from "./serviceCardSlice";
import { useDispatch, useSelector } from "react-redux";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import LoginModal from "components/UIComponents/Login/loginModal";
import style from 'components/UIComponents/Municipal/Municipal.module.scss'

const ServiceCard = (props) => {
  const { serviceId, requestDraftId, dealerId } = useParams();
  const [departmentId, setDepartmentId] = useState(1);
  // const { departmentId } = useSelector((state) => state.service.serviceMunicip);
  const { clientProfiles, isAuthenticated } = useSelector((state) => state.user);
  const {
    serviceData,
    serviceDepartmentsList,
    serviceDetailsData,
    serviceStepsData,
  } = useSelector((state) => state.service.serviceCard);
  const { currentState } = useSelector((state) => state.service.serviceMachine);
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const serviceMunicipRef = useRef();
  const helpTextRef = useRef();
  const [showHelp, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const modalClose = () => {
    setShowModal(false);
  };

  const modalShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (requestDraftId) {
      dispatch(authorizedState("Request"));
    } else if (!requestDraftId && currentState !== "Info") {
      dispatch(toState("Info"));
    }
  }, [requestDraftId, serviceData]);

  const handleHelp = (val) => {
    setShowHelp(val);
  };

  // useEffect(() => {
  //   if (showHelp) {
  //     serviceMunicipRef.current.style.border = "1px solid #dc3545";
  //     helpTextRef.current.style.display = "block";
  //   } else {
  //     serviceMunicipRef.current.style.border = "none";
  //     helpTextRef.current.style.display = "none";
  //   }
  // }, [showHelp]);

  useEffect(() => {
    if (serviceId && currentState !== "Request") {
      let activeDealerId = localStorage.getItem("activeDealerId");
      const promise = dispatch(
        fetchServiceCard({
          Dealing_Type_Id: clientProfiles?.Dealing_Type_Id ?? activeDealerId,
          Service_Id: serviceId,
          Department_Id: departmentId ? departmentId : 1,
        })
      );
      return () => {
        promise.abort();
      };
    }
  }, [departmentId, clientProfiles, serviceId, currentState]);

  useEffect(() => {
    return () => {
      dispatch(resetDepartment());
      dispatch(resetStates());
    };
  }, []);

  const handleRequestStart = useCallback(() => {
    if (departmentId) {
      dispatch(authorizedState("Request"));
    } else {
      serviceMunicipRef.current.style.transition =
        "transform 200ms ease-in-out";
      serviceMunicipRef.current.style.transform = "scale(1.02)";
      setShowHelp(true);
      setTimeout(() => {
        serviceMunicipRef.current.style.transform = "scale(1)";
      }, 200);
    }
  }, [serviceMunicipRef, departmentId, setShowHelp]);

  const handleDescriptionStart = () => {
    if (departmentId) {
      dispatch(authorizedState("Request"));
    } else {
      window.scrollTo(
        0,
        document.querySelector(".pageContainer").offsetTop - 100
      );
      serviceMunicipRef.current.style.transition =
        "transform 200ms ease-in-out";
      serviceMunicipRef.current.style.transform = "scale(1.02)";
      handleHelp(true);
      setTimeout(() => {
        serviceMunicipRef.current.style.transform = "scale(1)";
      }, 200);
    }
  };

  return (
    <>
      <Header />
      {currentState === "Info" && (
        <>
          <InnerTitleCont
            breadCrumbs={
              <>
                <span>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
                  </svg>
                </span>
                <span>
                  <svg
                    style={{
                      margin: '0 8px',
                      transform: i18n.language == 'ar' && 'scale(-1)'
                    }}
                    width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span>{t("all_services")}</span>
              </>
            }
            title={
              i18n.language === "en"
                ? serviceData?.Service_Name_EN ?? ""
                : serviceData?.Service_Name_AR ?? ""
            }
            permissionId={{ dealingTypeId: clientProfiles?.Dealing_Type_Id }}
            startBtn={true}
            leaveBtn={false}
            startTrigger={isAuthenticated ? handleRequestStart : modalShow}
            leaveTrigger={() => { }}
          />
          <Container fluid="lg" className="pageContainer">
            <Row className="mb-5">
              <Col md={8}>
                <div className="gCard services">
                  {serviceData && serviceDetailsData && serviceStepsData && (
                    <Description
                      serviceData={serviceData}
                      serviceDepartmentsList={serviceDepartmentsList}
                      serviceDetailsData={serviceDetailsData}
                      serviceStepsData={serviceStepsData}
                      onStart={
                        isAuthenticated ? handleDescriptionStart : modalShow
                      }
                    />
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div className="gCard" ref={serviceMunicipRef}>
                  {/* <span
                    ref={helpTextRef}
                    className={style.helpText}
                  >
                    {t("help_text")}
                  </span> */}
                  {/* <ServiceMunicip
                    // handleHelp={handleHelp}
                    municipalDetails={serviceData}
                    serviceDepartmentsList={serviceDepartmentsList}
                  > */}
                  <ServiceMunicipal
                    municipalDetails={serviceData}
                    isSupportEnabled={false}
                  />
                  {/* </ServiceMunicip> */}
                </div>
              </Col>
            </Row>
          </Container>
          <LoginModal show={showModal} onHide={modalClose} />
        </>
      )}
      {currentState === "Request" &&
        serviceData?.Dealing_Type_Id === clientProfiles?.Dealing_Type_Id && (
          <ServiceRequest />
        )}
      <Footer />
    </>
  );
};

export default ServiceCard;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import styleNew from "./applyService.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Container } from "react-bootstrap";
import PopUp from "components/UIComponents/PopUp/PopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUpdatRequestInputsData,
  submitUpdateRequest,
  saveUpdateRequestAttachmentData,
  deleteUpdateRequestAttachment,
  downloadUpdateRequestAttachment,
  downloadAttachment,
} from "api/serviceAPI";
import moment from "moment";
import { setError } from "./updateRequestSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faDownload,
  faUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Attachment,
  Csv,
  Doc,
  Excel,
  File,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import DynamicField from "components/UIComponents/Dynamics/DynamicField";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";
import * as icons from "./icons";

const RequestEdit = () => {
  const { t, i18n } = useTranslation("common");
  const {
    serviceData,
    requestData,
    requestDate,
    inputsDataValue,
    attachmentsDataValue,
    requestId,
    inputsData,
    attachmentsData,
    requiredChangesAttachments,
    requiredChangesNotes,
    currentTaskData,
    loading,
  } = useSelector((state) => state.service.updateRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [highlightTerms, setHighlightTerms] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const [changesActions, setChangesActions] = useState({});
  const [resetAction, setResetAction] = useState('');
  const termsRef = useRef();

  useEffect(() => {
    if (termsRef && highlightTerms && !acceptedTerms) {
      termsRef.current.style.transform = "scale(1.01)";
      termsRef.current.style.textDecoration = "underline";
    } else {
      termsRef.current.style.transform = "";
      termsRef.current.style.textDecoration = "";
    }
  }, [termsRef, highlightTerms, acceptedTerms]);

  const handleTerms = () => {
    setAcceptedTerms(Number(!acceptedTerms));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    submitUpdateRequest(
      {
        Department_Id: serviceData.Department_Id,
        Dealing_Type_Id: serviceData.Dealing_Type_Id,
        Service_Id: serviceData.Service_Id,
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          history.push(`/dashboard`);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const blob = new Blob([response]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        // document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleDefaultValue = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );
      if (dataValue) return dataValue[0]?.Service_Detail_Value;
      else {
        return null;
      }
    }
    return null;
  };

  const handleFileValue = (item) => {
    if (attachmentsDataValue?.length) {
      const fileValue = attachmentsDataValue.filter(
        (fileValue) =>
          fileValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );
      if (fileValue) return fileValue[0]?.Files_Data;
      else {
        return null;
      }
    }
    return null;
  };

  const handleSave = () => {
    history.push(`/dashboard`);
  };

  const onInputSave = async (e, item) => {
    await saveUpdatRequestInputsData({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Draft_Data: [
        {
          Service_Detail_Id: item.Service_Detail_Id,
          Service_Detail_Value: e.target.value,
        },
      ],
    }).then(data => setChangesActions(data?.Data?.Changes_Actions))
      .catch((err) => { dispatch(setError(err)) });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  const handleValidationMessage = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );

      if (dataValue) return dataValue[0];
      else {
        return null;
      }
    }
    return null;
  };

  const handleInputsDataValues = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );

      if (dataValue) return dataValue[0];
      else {
        return null;
      }
    }
  };

  const handleResetValCallback = (childData) => setResetAction(childData)

  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs={6}>
          <p className={style.title}>{t("Service Information")}</p>
        </Col>
        <Col xs={6} className="d-flex justify-content-end mb-2">
          <button className="mainBtn primarymainBtn smBtn" onClick={handleSave}>
            {t("save")}
          </button>
        </Col>
      </Row>

      <Row className="align-items-center position-relative">
        <Col xs={12} className={style.detailsContent}>
          <Row>
            <p className={style.details}>{t("Request Number")}</p>
            <p className={[style.details, 'px-1'].join(' ')}>{requestData?.Request_No}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Request Date")}</p>
            <p className={[style.details, 'px-1'].join(' ')}>
              {requestData?.Request_Date &&
                moment(requestDate.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD")}
            </p>
          </Row>
          <Row className="justify-content-between">
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_name")}:</p>
                <p className={[style.details, 'px-1'].join(' ')}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Name_AR
                    : serviceData?.Service_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_code")}:</p>
                <p className={[style.details, 'px-1'].join(' ')}>{serviceData?.Service_Code}</p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Version")}</p>
                <p className={[style.details, 'px-1'].join(' ')}>
                  {serviceData?.Department_Service_Version_Name}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Category")}</p>
                <p className={[style.details, 'px-1'].join(' ')}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Category_Name_AR
                    : serviceData?.Service_Category_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("department_name")}:</p>
                <p className={[style.details, 'px-1'].join(' ')}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Name_AR
                    : serviceData?.Department_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Section Name")}</p>
                <p className={[style.details, 'px-1'].join(' ')}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Section_Name_AR
                    : serviceData?.Department_Section_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xl={12}>
            <p className={style.title}>{t("steps")}</p>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>1</span>
              <p className={style.stepTitle}>{t("Information")}</p>
            </div>

            <div className={style.stepContent}>
              {loading !== "pending" ? (
                <Row>
                  {inputsData?.length > 0 &&
                    [...inputsData]
                      .sort(
                        (a, b) =>
                          Number(a.Department_Service_Detail_No) -
                          Number(b.Department_Service_Detail_No)
                      )
                      .map((item) => (
                        <Col
                          md={item?.Department_Service_Detail_Width == 12 ? 12 : 6}
                          className="mt-4"
                        >
                          <DynamicField
                            key={item.Department_Service_Detail_Id}
                            defaultValue={() => handleDefaultValue(item)}
                            validationMessage={handleValidationMessage(item)}
                            onSave={onInputSave}
                            item={item}
                            inputsData={() => handleInputsDataValues(item)}
                            autoSave={item.Department_Service_Detail_Type_Id === 10}
                            changesActions={changesActions}
                            handleResetValCallback={handleResetValCallback}
                            resetAction={resetAction}
                          />
                        </Col>
                      ))}
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>2</span>
              <p className={style.stepTitle}>
                {t("Modifications and Required Data")}
              </p>
            </div>

            <div className={style.stepContent}>
              {loading !== "pending" ? (
                <Row>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("notes")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      defaultValue={requiredChangesNotes}
                      disabled
                    />
                  </Col>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("attachments")}
                    </Form.Label>
                    <div className={style.fileContainer}>
                      <div className={style.fileLabel}>
                        <span>{t('chooseFile')}</span>
                        <span className={[style.uploadIcon, i18n.language == 'en' ? style.uploadIconEn : style.uploadIconAr].join(' ')}>
                          <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span>
                      </div>

                      {requiredChangesAttachments?.length > 0 && (
                        <div className={`${style.files} mt-2`}>
                          {requiredChangesAttachments.map((uploadedFile) => (
                            <div
                              key={uploadedFile.File_Code}
                              className="d-flex justify-content-between mb-1 p-1"
                            >
                              <div className="d-flex align-items-center">
                                {getFileIcon(uploadedFile.File_Content)}
                                <span
                                  onClick={handleToggleEllipsis}
                                  className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                    } mx-1`}
                                >
                                  {uploadedFile.File_Name}
                                </span>
                              </div>

                              <div className="d-flex align-items-end">
                                <span className={`${style.fileContent} mx-1`}>
                                  {uploadedFile.File_Size}
                                </span>
                                <span className={style.fileIcon} onClick={() => download(uploadedFile)}>
                                  <svg
                                    width="17"
                                    height="18"
                                    viewBox="0 0 17 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                                      stroke="#1B2124"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.51562 9L8.50032 11.9847L11.485 9"
                                      stroke="#1B2124"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8.5 11.9847V1.23981"
                                      stroke="#1B2124"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>3</span>
              <p className={style.stepTitle}>{t("Donwload Required")}</p>
            </div>

            <div className={style.stepContent}>
              <Row>
                {attachmentsData?.length > 0 &&
                  [...attachmentsData]
                    .sort(
                      (a, b) =>
                        Number(a.Department_Service_Detail_No) -
                        Number(b.Department_Service_Detail_No)
                    )
                    .map((item) => {
                      return (
                        <FileUpload
                          key={item.Department_Service_Detail_Id}
                          defaultValue={() => handleFileValue(item)}
                          item={item}
                        />
                      );
                    })}
              </Row>
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.terms}>
              <input
                className={style.checkBox}
                type="checkbox"
                defaultChecked={acceptedTerms}
                onChange={handleTerms}
              />
              <p ref={termsRef}>{t("terms and conditions")}</p>
            </div>
            <div
              className={style.terms}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? serviceData?.Service_Rules_AR
                    : serviceData?.Service_Rules_EN,
              }}
            ></div>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="submit"
              className="mainBtn primarymainBtn smBtn"
              disabled={!acceptedTerms}
              onPointerEnter={() => setHighlightTerms(true)}
              onPointerLeave={() => setHighlightTerms(false)}
              style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
            >
              {t("submit")}
            </button>
          </Col>
        </Row>
      </Form >
      {isSubmiting && <LoadingOverlay />}
    </>
  );
};

export default RequestEdit;

const FileUpload = ({ item, defaultValue }) => {
  const { t, i18n } = useTranslation("common");
  const [uploadedFiles, setUploadedFiles] = useState(defaultValue ? defaultValue : []);
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId } = useSelector((state) => state.service.updateRequest);
  const { currentTaskData } = useSelector((state) => state.service.updateRequest);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [showModal, setShowModal] = useState(false)
  const [fileUploaderType, setFileUploaderType] = useState('')
  const canvasRef = useRef(null)
  const contextRef = useRef(null)
  let context, canvas
  const [isDrawing, setIsDrawing] = useState(false)


  useEffect(() => {
    canvas = canvasRef?.current
    context = canvas?.getContext("2d")
  }, [showModal])

  useEffect(() => {
    if (canvas != null && context != null) {
      canvas.width = 400
      canvas.height = 300
      context.strokeStyle = "black"
      context.lineWidth = 1
      contextRef.current = context
    }
  }, [canvas, context, showModal])

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    setIsDrawing(true);
    nativeEvent.preventDefault();
  }

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) {
      return
    }
    const { offsetX, offsetY } = nativeEvent
    contextRef.current.lineTo(offsetX, offsetY)
    contextRef.current.stroke()
    nativeEvent.preventDefault()
  }

  const stopDrawing = () => {
    contextRef.current.closePath()
    setIsDrawing(false)
  }

  const setToErase = () => {
    let x = canvasRef?.current?.getContext("2d")
    x?.save();
    x?.setTransform(1, 0, 0, 1, 0, 0);
    x?.clearRect(0, 0, canvasRef?.current?.width, canvasRef?.current?.height);
    x?.restore();
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const saveImageToLocal = async (event) => {
    let image = canvasRef.current.toDataURL('image/png')
    let file = dataURLtoFile(image, 'signature.png');
    let newFile = new Blob([JSON.stringify(file)], { type: 'image/png' })
    setShowModal(false)

    if (file) {
      const data = new FormData();

      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId,
          Service_Detail_Id: item.Service_Detail_Id,
          Task_Id: currentTaskData?.Task_Id,
        })
      );

      setIsUploading(true);

      data.append("Attached_File", newFile);

      const response = await saveUpdateRequestAttachmentData(data, handleProgress)
        .catch((err) => {
          dispatch(setError(err));
        }).finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles(response?.Data?.Files_Data);
      }
    }
  }

  const onChange = async (e) => {
    var files = e.target.files[0];
    if (files) {
      const data = new FormData();
      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId,
          Service_Detail_Id: item.Service_Detail_Id,
          Task_Id: currentTaskData?.Task_Id,
        })
      );
      setIsUploading(true);
      data.append("Attached_File", e.target.files[0]);
      const response = await saveUpdateRequestAttachmentData(
        data,
        handleProgress
      )
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles(response?.Data?.Files_Data);
      }
      e.target.value = null;
    }
  };

  const removeFile = async (f) => {
    const response = await deleteUpdateRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Service_Detail_Id: item.Service_Detail_Id,
      Task_Id: currentTaskData?.Task_Id,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  };

  const download = async (f) => {
    await downloadUpdateRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: item.Service_Detail_Id,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  };

  const handleClose = () => setShowModal(false)

  console.log(uploadedFiles)
  console.log(item)

  return (
    <>
      <Col key={item.Department_Service_Detail_Id} xs={12}>
        <Form.Label className={style.label}>
          {i18n.language === "ar"
            ? item?.Department_Service_Detail_Title_AR
            : item?.Department_Service_Detail_Title_EN}
        </Form.Label>

        {isUploading && <ProgressBar value={progressValue} color="#58743A" />}
        <div className={style.fileContainer}>
          <div className={style.fileLabel}>
            <span>{t('chooseFile')}</span>

            {item.Department_Service_File_Signature == 1 && item.Department_Service_File_Signature_Attachment == 0 ?
              <span className={[style.uploadIcon, i18n.language == 'en' ? style.signatureEn : style.signatureAr].join(' ')}>{icons.pencil}</span>
              : item.Department_Service_File_Signature == 1 && item.Department_Service_File_Signature_Attachment == 1 ?
                <>
                  <span onClick={() => setFileUploaderType('file')} className={[style.uploadIcon, i18n.language == 'en' ? style.uploadIconEn : style.uploadIconAr].join(' ')}>
                    <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span onClick={() => { setShowModal(true); setFileUploaderType('input') }} className={[style.uploadIcon, i18n.language == 'en' ? style.signatureEn : style.signatureAr].join(' ')}>{icons.pencil}</span>
                </>
                :
                <span className={[style.uploadIcon, i18n.language == 'en' ? style.uploadIconEn : style.uploadIconAr].join(' ')}>
                  <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M24 24V42" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
            }
          </div>

          <Form.Control
            className={style.file}
            onChange={onChange}
            type={fileUploaderType}
            onClick={
              item.Department_Service_File_Signature == 1 && item.Department_Service_File_Signature_Attachment == 0 ?
                () => { setFileUploaderType('input'); setShowModal(true) }
                // : item.Department_Service_File_Signature == 1 && item.Department_Service_File_Signature_Attachment == 1 ?
                //   () => setFileUploaderType('input')
                : () => setFileUploaderType('file')
            }
            // type="file"
            required={
              item.Department_Service_Detail_Required && !uploadedFiles?.length
            }
          />

          <Form.Text className={[styleNew.disclaimr, 'px-0 mx-0'].join(' ')}>
            {t("Required File Format")}{" "}
            {i18n.language === "ar"
              ? item.File_Content_Type_Name_AR
              : item.File_Content_Type_Name_EN}
          </Form.Text>
          {item?.Department_Service_File_Max_Count == 1 && item.Department_Service_File_Signature == 1 &&
            <Form.Text className={[styleNew.disclaimr, 'px-0 mx-0'].join(' ')}>
              <span>{t("fileMaxCount")}</span>
            </Form.Text>
          }
          {item.Department_Service_File_Signature == 0 &&
            <Form.Text className={[styleNew.disclaimr, 'px-0 mx-0'].join(' ')}>
              <span> {t("fileMaxNo")}{item?.Department_Service_File_Max_Count}</span>
            </Form.Text>
          }
          {item?.Department_Service_File_Max_Size != 0 &&
            <Form.Text className={[styleNew.disclaimr, 'px-0 mx-0'].join(' ')}>
              <span>{t("fileMaxSize")}</span>
            </Form.Text>
          }

          {uploadedFiles?.length > 0 && (
            <div className={style.files}>
              {uploadedFiles.map((uploadedFile) => (
                <div
                  key={uploadedFile.File_Code}
                  className="d-flex justify-content-between mb-1"
                >
                  <div className="d-flex align-items-center">
                    {getFileIcon(uploadedFile.File_Content)}
                    <span
                      onClick={handleToggleEllipsis}
                      className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""} mx-1`}
                    >
                      {uploadedFile.File_Name}
                    </span>
                  </div>

                  <div className="d-flex align-items-end">
                    <span className={`${style.fileContent} mx-1`}>
                      {uploadedFile.File_Size}
                    </span>
                    <span className={style.fileIcon} onClick={() => download(uploadedFile)}>
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786"
                          stroke="#1B2124"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.51562 9L8.50032 11.9847L11.485 9"
                          stroke="#1B2124"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.5 11.9847V1.23981"
                          stroke="#1B2124"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className={style.fileIcon} onClick={() => removeFile(uploadedFile)}>
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.2513 15.7083C1.2513 16.7625 2.1138 17.625 3.16797 17.625H10.8346C11.8888 17.625 12.7513 16.7625 12.7513 15.7083V4.20833H1.2513V15.7083ZM13.7096 1.33333H10.3555L9.39714 0.375H4.60547L3.64714 1.33333H0.292969V3.25H13.7096V1.33333Z" fill="#E03137" />
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <PopUp
          show={showModal}
          handleClose={handleClose}
          title={t('signature')}
          smallModal={true}
        >
          <Container>
            <Row>
              <Col>
                <canvas
                  ref={canvasRef}
                  onPointerDown={startDrawing}
                  onPointerMove={draw}
                  onPointerUp={stopDrawing}
                  onPointerLeave={stopDrawing}
                  style={{
                    border: '1px dashed #00000040',
                    width: '100%',
                    touchAction: 'none',
                    borderRadius: '20px',
                    marginBottom: '1rem'
                  }}
                >
                </canvas>
              </Col>
            </Row>

            <Row>
              <Col>
                <button
                  type={"button"}
                  onClick={setToErase}
                  className={`mainBtn ${styleNew.stepsButton}`}
                >{t('eraseSignature')}</button>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                  type={"button"}
                  onClick={saveImageToLocal}
                  className={`mainBtn ${styleNew.saveButton}`}
                >{t('save')}</button>
              </Col>
            </Row>
          </Container>
        </PopUp>
      </Col>

      {/* {isUploading && <LoadingOverlay />} */}
    </>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};

import React, { useState, useEffect } from "react";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import style from "./../Dashboard.module.scss";
import { Row } from "react-bootstrap";

const NewRequestsGrid = (props) => {
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    const getNewRequestsList = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_NEW_REQUESTS_LIST`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          PageIndex: "1",
          PageCount: "1000",
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      ).then(data => {
        setData(data.data.Data.Data)
        props.dataIsReady(false)
      })
    };
    getNewRequestsList();
  }, []);

  let departmentName = "Department_Name_EN",
    serviceName = "Service_Name_EN",
    statusName = "Request_Status_Name_EN",
    dealingTypeName = "Dealing_Type_Name_EN",
    areaName = "Area_Name_EN";

  if (i18n.language === "ar") {
    departmentName = "Department_Name_AR";
    serviceName = "Service_Name_AR";
    statusName = "Request_Status_Name_AR";
    dealingTypeName = "Dealing_Type_Name_AR";
    areaName = "Area_Name_AR";
  }

  return (
    <>
      <Row className="justify-content-center"></Row>

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={false}
          deleteEnabled={false}
          viewEnabled={true}
          onView={({ data }) => {
            // props.handleShow(data.Request_Id)
            history.push(`/request-info/${data?.Request_Id}`);
          }}
          onDelete={({ data }) => { }}
        >
          {/* visible={false} */}
          <Column
            caption={t("request_no")}
            alignment="center"
            dataField="Request_No"
            dataType="string"
            width={200}
          />
          <Column
            caption={t("request_date")}
            alignment="center"
            dataField="Request_Date"
            dataType="date"
            width={150}
            format="yyyy-MM-dd"
          />
          <Column
            caption={t("department_name")}
            alignment="center"
            dataField={departmentName}
            dataType="string"
            width={150}
            visible={false}
          />
          <Column
            caption={t("service_name")}
            alignment="center"
            dataField={serviceName}
            dataType="string"
            width={140}
          />
          <Column
            caption={t("service_issuance")}
            alignment="center"
            dataField="Department_Service_Version_Name"
            dataType="string"
            width={130}
            visible={false}
          />
          <Column
            caption={t("request_status")}
            alignment="center"
            dataField={statusName}
            dataType="string"
            width={120}
          />
          <Column
            caption={t("owner")}
            alignment="center"
            dataField="Owner_Name"
            dataType="string"
            width={140}
          />
          <Column
            caption={t("plot_no")}
            alignment="center"
            dataField="Plot_No"
            dataType="string"
            width={140}
          />
          <Column
            caption={t("area_name")}
            alignment="center"
            dataField={areaName}
            dataType="string"
            width={140}
          />
          <Column
            caption={t("project_no")}
            alignment="center"
            dataField="Project_No"
            dataType="string"
            width={140}
          />
        </Grid>
      </Row>
    </>
  );
};

export default NewRequestsGrid;

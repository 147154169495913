import React from 'react'
import Header from './../../UIComponents/Header/Header'
import DepartmentContactUs from './../../UIComponents/DepartmentContactUS/DepartmentContactUs'
import Footer from './../../UIComponents/Footer/Footer'
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont"
import { useTranslation } from 'react-i18next'

const ContactUS = () => {
    const { t, i18n } = useTranslation('common')

    return (
        <>
            <Header />
            <InnerTitleCont
                breadCrumbs={
                    <>
                        <span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
                            </svg>
                        </span>
                        <span>
                            <svg
                                style={{
                                    margin: '0 8px',
                                    transform: i18n.language == 'ar' && 'scale(-1)'
                                }}
                                width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <span>{t("contactUs")}</span>
                    </>
                }
                title={t("contactUs")}
                startBtn={false}
                leaveBtn={false}
            />
            <DepartmentContactUs municipalPage={false} />
            <Footer />
        </>
    )
}

export default ContactUS

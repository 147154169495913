import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/UIComponents/Header/Header';
import Footer from 'components/UIComponents/Footer/Footer';
import style from './Dashboard.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Drafts from './Drafts/Drafts';
import NewRequests from './NewRequests/NewRequests';
import DuePayments from './DuePayments/DuePayments';
import ReturnRequests from './ReturnRequests/ReturnRequests';
import UnderProcessRequests from './UnderProcessRequests/UnderProcessRequests';
import CompletedRequests from './CompletedRequests/CompletedRequests';
import ActiveLicenses from './ActiveLicenses/ActiveLicenses';
import RejectedRequests from './RejectedRequests/RejectedRequests';
import Payments from './Payments/Payments';
import ServicesSupport from './ServicesSupport/ServicesSupport';
import Suggestions from './Suggestions/Suggestions';
import InspectionDates from './InspectionDates/InspectionDates';
import { useSelector } from 'react-redux';
import ScrollButton from '../../UIComponents/ScrollToTopButton';
import LoadingOverlay from 'components/UIComponents/LoadingOverlay';
import ViolationsSuspensions from './../../UIComponents/ViolationsSuspensions/ViolationsSuspensions';
import { post } from '../../../api/base.js';
import InnerTitleCont from 'components/UIComponents/InnerTitleCont/InnterTitleState';
import {
	Draft,
	NewRequest,
	UnderProccess,
	PaymentRequest,
	FeedBack,
	Inspection,
} from 'global/icons/index.jsx';

const Dashboard = () => {
	const { t, i18n } = useTranslation('common');
	const [data, setData] = useState();
	const [activeGrid, setActiveGrid] = useState();
	const { user, clientProfiles } = useSelector((state) => state.user);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		const getDashboardStatistics = async () => {
			const res = await post(
				`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_STATISTICS`,
				{
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${user?.access_token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setData(res.Data[0]);
		};
		getDashboardStatistics();
		const interval = setInterval(getDashboardStatistics, 120000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (!isReady && activeGrid) {
			window.scrollTo({
				top: document.querySelector('.gridCon').offsetTop - 160,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [isReady]);

	const list = [
		{
			title: t('drafts'),
			count: `${data && data.Drafts_Requests_Count}`,
			icon: <Draft />,
		},
		{
			title: t('new_requests'),
			count: `${data && data.New_Requests_Count}`,
			icon: <NewRequest />,
		},
		{
			title: t('due_payment_requests'),
			count: `${data && data.Due_Payments_Count}`,
			icon: <PaymentRequest />,
		},
		{
			title: t('returned_requests'),
			count: `${data && data.Return_Requests_Count}`,
			icon: <UnderProccess />,
		},
		{
			title: t('under_process_requests'),
			count: `${data && data.Under_Process_Requests_Count}`,
			icon: <UnderProccess />,
		},
		{
			title: t('completed_requests'),
			count: `${data && data.Compeleted_Requests_Count}`,
			icon: <Inspection />,
		},
		{
			title: t('rejected_requests'),
			count: `${data && data.Rejected_Requests_Count}`,
			icon: <Inspection />,
		},
		{
			title: t('insurance_fees_fines'),
			count: `${data && data.Payments_Count}`,
			icon: <PaymentRequest />,
		},
		// {
		// 	title: t('technical_support_requests'),
		// 	count: `${data && data.Services_Support_Count}`,
		// 	icon: <FeedBack />,
		// },
		// {
		// 	title: t('complaints_suggestions'),
		// 	count: `${data && data.Suggestions_Count}`,
		// 	icon: <FeedBack />,
		// },
		// {
		// 	title: t('inspection_dates'),
		// 	count: `${data && data.Inspection_Visits_Count}`,
		// 	icon: <Inspection />,
		// },
		{
			title: t('activeLicenses'),
			count: `${data && data.Active_Licenses_Count}`,
			icon: <Draft />
		}
	];

	const dataIsReady = (data) => setIsReady(data)

	const showGrid = (item) => setActiveGrid(item.title)

	return (
		<>
			<Header />
			<InnerTitleCont
				breadCrumbs={
					<>
						<span>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z"
									fill="white"
								/>
							</svg>
						</span>
						<span>
							<svg
								style={{
									margin: '0 8px',
									transform:
										i18n.language == 'ar' && 'scale(-1)',
								}}
								width="6"
								height="10"
								viewBox="0 0 6 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 1L5 5L1 9"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>
						<span>{t('Dashboard')}</span>
					</>
				}
				title={t("Dashboard")}
				leaveBtn={false}
			/>
			<Container className="pageContainer dashboard-container">
				<div className={style.Dashboard_card_Wrapper}>
					<Container>
						{data ? (
							<Row
								className="justify-content-center"
								gutters={[0, 5]}
							>
								<Col xl={12}>
									<p className={style.title}>
										{t('Dashboard')}
									</p>
								</Col>
								{list.map((item) => {
									return (
										<Col
											key={item.title}
											className={'pb-2'}
											xl={4}
											md={4}
										>
											<div
												className={style.container}
												onClick={() => {
													setIsReady(activeGrid == item.title ? false : true);
													showGrid(item);
												}}
											>
												<div className="d-flex justify-content-center align-items-center">
													<span
														className={
															style.dashboardIcon
														}
													>
														{item.icon}
													</span>
													<h5>{item.title}</h5>
												</div>

												{/* <div
														className="text-center align-items-center justify-content-center"
													>
													</div> */}
												<h6>{item.count}</h6>
											</div>
										</Col>
									);
								})}
							</Row>
						) : null}

						<ScrollButton />
					</Container>
				</div>
				<div className={style.Dashboard_card_Wrapper}>
					<Container>
						<Row className="gridCon">
							<Col>
								{(() => {
									if (activeGrid === t('drafts')) {
										return (
											<Drafts dataIsReady={dataIsReady} />
										)
									} else if (activeGrid === t('new_requests')) {
										return (
											<NewRequests
												dataIsReady={dataIsReady}
											/>
										)
									} else if (activeGrid === t('due_payment_requests')) {
										return (
											<DuePayments
												dataIsReady={dataIsReady}
											/>
										)
									} else if (activeGrid === t('returned_requests')) {
										return (
											<ReturnRequests
												dataIsReady={dataIsReady}
											/>
										)
									} else if (activeGrid === t('under_process_requests')) {
										return (
											<UnderProcessRequests
												dataIsReady={dataIsReady}
											/>
										)
									} else if (activeGrid === t('completed_requests')) {
										return (
											<CompletedRequests
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('rejected_requests')) {
										return (
											<RejectedRequests
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('insurance_fees_fines')) {
										return (
											<Payments
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('technical_support_requests')) {
										return (
											<ServicesSupport
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('complaints_suggestions')) {
										return (
											<Suggestions
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('inspection_dates')) {
										return (
											<InspectionDates
												dataIsReady={dataIsReady}
											/>
										);
									} else if (activeGrid === t('activeLicenses')) {
										return (
											<ActiveLicenses
												dataIsReady={dataIsReady}
											/>
										);
									}
								})()}
							</Col>
						</Row>

						<ScrollButton />
					</Container>
				</div>
			</Container>

			<Footer />

			<ViolationsSuspensions />

			{isReady && <LoadingOverlay />}
		</>
	);
};

export default Dashboard;

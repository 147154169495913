import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./ServicesList.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { departmentSelected } from "features/service/serviceMunicipSlice";

const ServicesList = props => {
  const { i18n } = useTranslation("common");
  const dispatch = useDispatch();

  return (
    <>
      {props.serviceList?.map((service) => (
        <Col md={6} xs={12} className={style.service} key={service.Service_Id}>
          <NavLink
            to={{
              pathname: `../../service-card/${service.Service_Id}`,
            }}
            onClick={() => {
              dispatch(departmentSelected(props.municipalId));
            }}
            exact>
            <span>
              {service.Service_Logo_URL ? (
                <img className={style.serviceImg} src={service.Service_Logo_URL} />
              ) : (
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M44.5355 40.0858C44.949 33.4643 45.0698 26.8277 44.8976 20.1955C44.867 18.8504 44.4253 17.5469 43.6318 16.4603C40.5967 12.3284 38.1825 9.7589 34.1989 6.68586C33.1013 5.8448 31.7634 5.37707 30.3809 5.3511C28.921 5.32016 27.4608 5.30522 26.0007 5.30626C21.3204 5.30626 17.5438 5.44422 13.6395 5.71669C12.0336 5.82479 10.5219 6.51315 9.386 7.65346C8.25012 8.79377 7.56767 10.3082 7.46584 11.9145C7.17411 16.604 7.02918 21.3015 7.03127 26.0001C7.03127 30.8287 7.17958 35.54 7.46584 40.0858C7.67278 43.4106 10.3147 46.056 13.6395 46.2836C17.5438 46.5561 21.3204 46.694 26.0007 46.694C30.6809 46.694 34.4576 46.5561 38.3618 46.2836C39.9677 46.1755 41.4795 45.4871 42.6153 44.3468C43.7512 43.2065 44.4337 41.6921 44.5355 40.0858Z" fill="#8AD3FF" fill-opacity="0.08" />
                  <path d="M44.5355 40.0858C44.949 33.4643 45.0698 26.8277 44.8976 20.1955C44.867 18.8504 44.4253 17.5469 43.6318 16.4603C40.5967 12.3284 38.1825 9.7589 34.1989 6.68586C33.1013 5.8448 31.7634 5.37707 30.3809 5.3511C28.921 5.32016 27.4608 5.30522 26.0007 5.30626C21.3204 5.30626 17.5438 5.44422 13.6395 5.71669C12.0336 5.82479 10.5219 6.51315 9.386 7.65346C8.25012 8.79377 7.56767 10.3082 7.46584 11.9145C7.17411 16.604 7.02918 21.3015 7.03127 26.0001C7.03127 30.8287 7.17958 35.54 7.46584 40.0858C7.67278 43.4106 10.3147 46.056 13.6395 46.2836C17.5438 46.5561 21.3204 46.694 26.0007 46.694C30.6809 46.694 34.4576 46.5561 38.3618 46.2836C39.9677 46.1755 41.4795 45.4871 42.6153 44.3468C43.7512 43.2065 44.4337 41.6921 44.5355 40.0858Z" stroke="#0F2980" stroke-width="3.71429" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19.4297 26.0554H32.5772" stroke="#0F2980" stroke-width="3.71429" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19.4297 36.0782H32.5358M19.4297 15.6533H27.3623" stroke="#0F2980" stroke-width="3.71429" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              )}
            </span>
            <p>{i18n.language === "en" ? service.Service_Name_EN : service.Service_Name_AR}</p>
          </NavLink>
        </Col>
      ))}
    </>
  );
};

export default ServicesList;

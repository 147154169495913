import React from "react";
import ShMunAnimatedGif from "global/images/ShMunAnimatedGif-inside.gif";
import STRIPAnimatedGif from "global/imagesTemp/STRIPAnimatedGif-inside.gif";
import styles from "./Overlay.module.scss";

const LoadingOverlay = () => {
	return (
		<div className={styles.overlay}>
			<img
				src={
					process.env.REACT_APP_IMG_SRC == 2
						? STRIPAnimatedGif
						: ShMunAnimatedGif
				}
			/>
		</div>
	);
};

export default LoadingOverlay;

import React, { useState } from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import style from './UserServicesSearch.module.scss'
import ServicesGrid from './ServicesGrid'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PopUp from '../../UIComponents/PopUp/PopUp'
import { Formik, Form, Field } from "formik"
import moment from 'moment'
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";

const UserServicesSearch = () => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()

    const handleClose = () => setShow(false)
    const handleShow = data => {
        setShow(true)
        setModalData(data)
    }

    return (
        <>
            <Header />

            <InnerTitleCont
                breadCrumbs={
                    <>
                        <span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z" fill="white" />
                            </svg>
                        </span>
                        <span>
                            <svg
                                style={{
                                    margin: '0 8px',
                                    transform: i18n.language == 'ar' && 'scale(-1)'
                                }}
                                width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <span>{t("services_search")}</span>
                    </>
                }
                title={t("services_search")}
                startBtn={false}
                leaveBtn={false}
            />

            <Container fluid='lg'>
                {/* <Row>
                    <Col xl={12} className='text-center'>
                        <p className={style.title}>{t('services_search')}</p>
                    </Col>
                </Row> */}

                <Row className='mt-3 mb-5'>
                    <ServicesGrid handleShow={handleShow} />
                </Row>
            </Container>

            <Footer />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('services_search')}
                >
                    <Container>

                        <Formik
                            initialValues={{
                                request_no: modalData.Request_No,
                                request_date: moment(modalData?.Request_Date).lang("en").format('YYYY-MM-DD'),
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                request_status: i18n.language === 'en' ? modalData?.Request_Status_Name_EN : modalData?.Request_Status_Name_AR,
                                service_issuance: modalData.Department_Service_Version_Name
                            }}
                        >
                            <Form className={style.service}>
                                <Row style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('request_no')}</p>
                                        <Field className={style.serviceField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('request_date')}</p>
                                        <Field className={style.serviceField} name="request_date" type="text" readOnly />
                                    </Col>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('department_name')}</p>
                                        <Field className={style.serviceField} name="department_name" type="text" readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.serviceField].join(' ')} readOnly />
                                    </Col>
                                    <Col sm={6}>
                                        <p className={style.name}>{t('request_status')}</p>
                                        <Field className={style.serviceField} name="request_status" type="text" readOnly />
                                    </Col>
                                    <Col sm={6}>
                                        <p className={style.name}>{t('service_issuance')}</p>
                                        <Field className={style.serviceField} name="service_issuance" type="text" readOnly />
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>

                    </Container>
                </PopUp>
                : null}
        </>
    )
}

export default UserServicesSearch

import React, { useState, useEffect, useRef, useCallback } from "react";
import style from "./Request.module.scss";
import styleNew from "./NewRequest.module.scss";
import styleApply from "./applyService.module.scss";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Accordion, Card } from "react-bootstrap";
import * as icons from "./icons";
import {
  Attachment,
  Csv,
  Doc,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import Grid from "./../../components/UIComponents/Grid/index";
import { Column } from "devextreme-react/data-grid";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";
import Alert from "react-bootstrap/Alert";
import { setError } from "./serviceRequestSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  downloadDraftFileData,
  deleteDraftFileData,
  saveDraftFileData,
  downloadAttachment,
  saveDraftNotes
} from "api/serviceAPI";
import debounce from "lodash.debounce";

const NewClientRequestInfo = (props) => {

  const { t, i18n } = useTranslation("common")
  const [inputsDataList, setInputsDataList] = useState([])
  const [stepsArr, setStepsArr] = useState(props.steps)
  const [isTextExpanded, setIsTextExpanded] = useState({})
  const [activeStep, setActiveStep] = useState("0")

  useEffect(() => {
    let inputsArray = [];
    let inputsIsExpandedObject = {};

    props.inputsDataValue.map((ele) => {
      let inputData = props?.inputsData.filter(
        (ele1) =>
          ele1.Department_Service_Detail_Id === ele.Department_Service_Detail_Id
      )

      let stepNum = inputData[0].Department_Service_Detail_Form_No
      let typeId = inputData[0].Department_Service_Detail_Type_Id
      let title =
        i18n.language === "ar"
          ? inputData[0]?.Department_Service_Detail_Title_AR
          : inputData[0]?.Department_Service_Detail_Title_EN;

      let value
      if (typeId != 3) {
        value = i18n.language === "ar" ? ele.Service_Detail_Value_AR : ele.Service_Detail_Value_EN;
      } else {
        value = ele.Files_Data;
      }

      inputsIsExpandedObject[title] = false;
      return inputsArray.push({ title: title, value: value, stepNum: stepNum, typeId: typeId });
    });

    if (props.requestFees) {
      setStepsArr(prevState => [
        ...prevState, {
          name: t('serviceFees')
        }
      ])
    }

    setIsTextExpanded({ ...inputsIsExpandedObject });
    setInputsDataList(inputsArray);
  }, [
    props.inputsDataValue,
    props.inputsData,
    props.requestFees
  ]);

  const handleExpandLabel = (title) => {
    setIsTextExpanded((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const mapUploadedFiles = (files = [], title) => {
    return files.map((uploadedFile) => (
      <div
        key={uploadedFile.File_Code}
        className={[style.fileDetails, "d-flex justify-content-between my-1"].join(' ')}
      >
        <div className="d-flex flex-wrap justify-content-between  w-100">
          <div style={{ width: 'calc(100% - 50px)' }}>
            {getFileIcon(uploadedFile.File_Content)}
            <span
              onClick={() => handleExpandLabel(title)}
              style={{ color: "black", width: '90%' }}
              className={`${styleNew.fileContent}  ${!isTextExpanded[title] ? styleNew.clientRequestInfo : ""
                } mx-1`}
            >
              {uploadedFile.File_Name}
            </span>
          </div>
          <div className="mx-1">
            {/* <FontAwesomeIcon
              className="mx-1"
              size="1x"
              color={"#1e90ff"}
              icon={faDownload}
              onClick={() => download(uploadedFile)}
            /> */}
            <span onClick={() => download(uploadedFile)}>
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.51562 9L8.50032 11.9847L11.485 9" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.5 11.9847V1.23981" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    ));
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f?.File_Code,
    })
      .then(function (response) {
        const blob = new Blob([response]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f?.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  console.log('hereeeee', props)
  console.log('stepsssssssssArr', stepsArr)

  let invoiceTypeName = 'Invoice_Type_Name_AR'
  if (i18n.language == 'en') {
    invoiceTypeName = 'Invoice_Type_Name_EN'
  }

  return (
    <>
      <Container fluid="lg" className="pageContainer">
        <Row className="mb-5">
          <Col md={12}>
            <div className={`${styleNew.container}`}>
              {stepsArr?.map((step, index) => (
                // {props?.steps?.map((step, index) => (
                <>
                  <Row>
                    <Col>
                      <Accordion defaultActiveKey="0">
                        <Card className={style.accCard}>
                          <Card.Header className={style.accHeader}>
                            <Accordion.Toggle
                              eventKey={index + ''}
                              className={style.accBtn}
                              onClick={(e) => setActiveStep(index)}
                            >
                              <div className={styleNew.titleContainer}>
                                <div className={styleNew.title}>
                                  <div className={style.completed}>
                                    {index + 1}
                                  </div>
                                  <div className={style.stepHeader}>
                                    <span className={style.stepTitle}>
                                      {step?.props?.children ? step?.props?.children : step?.name}
                                    </span>
                                  </div>
                                </div>
                                <span
                                  className={[
                                    style.accArrow,
                                  ].join(" ")}
                                >
                                  {icons.numberDown}
                                </span>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>

                          <Accordion.Collapse eventKey={index + ''}>
                            <Row>
                              <Card.Body className="pt-0 w-100">
                                <Col
                                  xs={12}
                                  className={style.contentContainer}
                                >
                                  <Row>
                                    <div className={[style.itemsContainer].join(' ')}>
                                      {inputsDataList.length > 0 && inputsDataList.filter(input => input.stepNum == index + 1)
                                        .slice(
                                          0,
                                          inputsDataList.length % 2 !== 0
                                            ? inputsDataList.length / 2 + 1
                                            : inputsDataList.length / 2
                                        )
                                        .map((ele) => {
                                          if (ele.typeId != 3) {
                                            return (
                                              <div className="mb-4">
                                                <span
                                                  onClick={() =>
                                                    handleExpandLabel(ele.title)
                                                  }
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.title}
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    handleExpandLabel(ele.title)
                                                  }
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.value}
                                                </span>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div className="mb-4 d-block">
                                                <span
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.title}
                                                </span>
                                                <span className="flex-column">
                                                  {mapUploadedFiles(
                                                    ele.value,
                                                    ele.title
                                                  )}
                                                </span>
                                              </div>
                                            )
                                          }
                                        })
                                      }
                                    </div>

                                    <div
                                      className={[style.itemsContainer, i18n.language == 'en' ? style.contEN : style.contAR].join(' ')}
                                    >
                                      {inputsDataList.length > 0 && inputsDataList.filter(input => input.stepNum == index + 1)
                                        .slice(
                                          inputsDataList.length % 2 !== 0
                                            ? inputsDataList.length / 2 + 1
                                            : inputsDataList.length / 2,
                                          inputsDataList.length
                                        )
                                        .map((ele) => {
                                          if (ele.typeId != 3) {
                                            return (
                                              <div className="mb-4">
                                                <span
                                                  onClick={() =>
                                                    handleExpandLabel(ele.title)
                                                  }
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.title}
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    handleExpandLabel(ele.title)
                                                  }
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.value}
                                                </span>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div className="mb-4 d-block">
                                                <span
                                                  className={
                                                    isTextExpanded[ele.title]
                                                      ? styleNew.textLabel
                                                      : styleNew.textExpandedLabel
                                                  }
                                                >
                                                  {ele.title}
                                                </span>
                                                <span className="flex-column">
                                                  <>
                                                    {mapUploadedFiles(
                                                      ele.value,
                                                      ele.title
                                                    )}
                                                  </>
                                                </span>
                                              </div>
                                            )
                                          }
                                        })
                                      }
                                    </div>

                                    {props.requestFees && index + 1 == stepsArr.length &&
                                      <div>
                                        <Row>
                                          <Grid
                                            sourceData={props?.requestFees?.Invoice_Items}
                                            addEnabled={false}
                                            editEnabled={false}
                                            deleteEnabled={false}
                                            viewEnabled={false}
                                            SearchPanelEnable={false}
                                            ColumnChooserEnable={false}
                                          >
                                            <Column caption={t("item_name")} alignment="center" dataField={invoiceTypeName} dataType="string" width="auto" allowFiltering={false} />
                                            <Column caption={t("amount")} alignment="center" dataField="Invoice_Type_Qty" dataType="string" width="auto" allowFiltering={false} />
                                            <Column caption={t("value")} alignment="center" dataField="Invoice_Type_Value" dataType="string" width="auto" allowFiltering={false} />
                                            <Column caption={t("discount")} alignment="center" dataField="Invoice_Type_Discount" dataType="string" width="auto" allowFiltering={false} />
                                            <Column caption={t("tax")} alignment="center" dataField="Invoice_Type_Tax" dataType="string" width="auto" allowFiltering={false} />
                                            <Column caption={t("grandTotalVal")} alignment="center" dataField="Invoice_Type_Grand_Total_Value" dataType="string" width="auto" allowFiltering={false} />
                                          </Grid>
                                        </Row>

                                        <Row>
                                          <Col className={styleNew.totalVal}>
                                            {t('invoiceGrandTotalVal')}: {props?.requestFees?.Invoice_Value}
                                          </Col>
                                        </Row>
                                      </div>
                                    }

                                    {!stepsArr[stepsArr.length - 1]?.hasOwnProperty('name') &&
                                      < div className={[style.itemsContainer].join(' ')}>
                                        {inputsDataList.filter(input => input.stepNum == index + 1).length == 0 ?
                                          <p style={{ fontWeight: 'bold' }}>{t('There is no data')}</p>
                                          : null}
                                      </div>
                                    }

                                  </Row>
                                </Col>
                              </Card.Body>
                            </Row>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Col>
                  </Row >
                  <div>{icons.horizontalLine}</div>
                </>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Textarea defaultValue={props?.requestNotes} />
          </Col>

          <Col md={6}>
            <FileUpload
              defaultValue={props?.requestFiles}
              item={props?.requestFiles}
            />
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default NewClientRequestInfo;

const Textarea = ({ defaultValue }) => {
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId } = useSelector(
    (state) => state.service.serviceRequest
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const { t, i18n } = useTranslation("common")
  const [validationError, setValidationError] = useState(false);

  const onKeyPress = (e) => {
    var ew = e.which;
    if (ew != 8) {
      if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
        setValidationError(true);
        e.preventDefault();
        return false;
      }
    }
    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    if (e.target.value) {
      await saveDraftNotes({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Draft_Id: requestId,
        Notes: e.target.value,
      }).catch((err) => {
        dispatch(setError(err));
      });
      setValue(e.target.value);
    }
  }, 500);

  return (
    <>
      <Form.Label
        className={[styleApply.textLabel].join(' ')}
      >
        {t('notes')}
      </Form.Label>

      <Form.Control
        name={"notes"}
        defaultValue={value}
        // onKeyPress={onKeyPress}
        onChange={handleChangeWithDebounce}
        required={true}
        as="textarea"
        className={style.customInput}
        rows={3}
      />
      {/* {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )} */}
    </>
  );
};

const FileUpload = ({ item, defaultValue }) => {
  const { t, i18n } = useTranslation("common")
  const [uploadedFiles, setUploadedFiles] = useState(defaultValue ? defaultValue : [])
  const { clientProfiles } = useSelector((state) => state.user)
  const { requestId } = useSelector((state) => state.service.serviceRequest)
  const dispatch = useDispatch()
  const [isUploading, setIsUploading] = useState(false)
  const [fileUploadingData, setFileUploadingData] = useState({})
  const [isEllipsis, setIsEllipsis] = useState(true)
  const [progressValue, setProgressValue] = useState(0)
  const [isTextExpanded, setIsTextExpanded] = React.useState(false)
  const [alertMsg, setAlertMsg] = useState(false)

  const onChange = async (e) => {
    var files = e.target.files[0];

    if (files) {
      const data = new FormData();

      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Draft_Id: requestId,
        })
      );

      setFileUploadingData({
        name: e?.target?.files[0]?.name,
        size: (e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2),
        type: e?.target?.files[0]?.type
      })
      setIsUploading(true);
      data.append("Attached_File", e.target.files[0]);

      const response = await saveDraftFileData(data, handleProgress)
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setFileUploadingData({})
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles(response?.Data);
      }
      e.target.value = null;
    }
  }

  const removeFile = async (f) => {
    const response = await deleteDraftFileData({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: requestId,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  }

  const download = async (f) => {
    await downloadDraftFileData({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: requestId,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  }

  const handleToggleEllipsis = () => {
    // setIsEllipsis(!isEllipsis);
    setIsTextExpanded((prev) => !prev);
  }

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  }

  const handleExpandLabel = (setState, title, ref) => {
    setIsTextExpanded((prev) => !prev);
  }

  return (
    <div>
      <Col xs={12}>
        <div>

          <div className={[styleApply.fileUploaderCont, 'mt-4'].join(' ')}>
            <div className={styleApply.labelCont}>

              <span>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M24 24V42" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M40.7789 36.78C42.7296 35.7166 44.2706 34.0338 45.1587 31.9973C46.0468 29.9608 46.2314 27.6865 45.6834 25.5334C45.1353 23.3803 43.8859 21.4711 42.1323 20.1069C40.3786 18.7428 38.2207 18.0015 35.9989 18H33.4789C32.8736 15.6585 31.7453 13.4847 30.1788 11.642C28.6124 9.79933 26.6486 8.33573 24.4351 7.36124C22.2216 6.38676 19.816 5.92675 17.3992 6.01579C14.9823 6.10484 12.6171 6.74063 10.4813 7.87536C8.34552 9.01009 6.49477 10.6142 5.06819 12.5672C3.64161 14.5202 2.67632 16.7711 2.2449 19.1508C1.81348 21.5305 1.92715 23.9771 2.57737 26.3066C3.22759 28.636 4.39743 30.7878 5.99894 32.6" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M32 32L24 24L16 32" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>

              <div>
                <Form.Label
                  onClick={handleExpandLabel}
                  className={[isTextExpanded ? styleApply.textLabel : styleApply.textExpandedLabel, 'mb-0'].join(' ')}
                >
                  {t('extraAttachments')}
                </Form.Label>
              </div>

              <div className={[styleApply.fileButton, i18n.language == 'en' ? styleApply.fileBtnEN : styleApply.fileBtnAR].join(' ')}>
                <label className={styleApply.fileContainer}>
                  <button className={styleApply.fileButton} type="button">
                    <div>{t("chooseFile")}</div>
                    <div>{icons.upload}</div>
                  </button>
                </label>

                <Form.Control
                  className={styleApply.file}
                  type="file"
                  onChange={onChange}
                  required={false}
                />
              </div>
            </div>
          </div>

          {isUploading && (
            <div className={styleApply.files}>
              <div className="d-flex justify-content-between align-items-center px-2 py-1">
                <div className="d-flex">
                  {/* {getFileIcon(fileUploadingData?.type)} */}
                  {/* <span
                    onClick={handleToggleEllipsis}
                    // style={{ width: '90%', maxWidth: 'unset' }}
                    className={`${styleApply.fileContent}  ${!isTextExpanded ? styleApply.clientRequestInfo : ""
                      } mx-1`}
                  >
                    {fileUploadingData?.name}
                  </span> */}
                </div>

                <div className={styleApply.progressBarContainer}>
                  <ProgressBar value={progressValue} color="#D0B166" />
                </div>

                {/* <div className="d-flex align-items-center">
                  <span className={`${styleApply.fileContent} mx-1`}>
                    {fileUploadingData?.size ? fileUploadingData?.size + ' MB ' : null}
                  </span>
                </div> */}
              </div>
            </div>
          )}

          {uploadedFiles?.length > 0 && (
            <div className={styleApply.files}>
              {uploadedFiles?.map((uploadedFile) => (
                <div
                  key={uploadedFile?.File_Code}
                  className="d-flex justify-content-between px-2 py-1"
                >
                  <div className="d-flex" style={{ width: 'calc(100% - 130px)' }}>
                    {getFileIcon(uploadedFile?.File_Content)}
                    <span
                      onClick={handleToggleEllipsis}
                      style={{ width: '90%', maxWidth: 'unset' }}
                      className={`${styleApply.fileContent}  ${!isTextExpanded ? styleApply.clientRequestInfo : ""
                        } mx-1`}
                    >
                      {uploadedFile?.File_Name}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <OverlayTrigger
                      overlay={<Tooltip id='button-tooltip'>{t('download')}</Tooltip>}
                    >
                      <span onClick={() => download(uploadedFile)}>
                        <svg width="17" height="16" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.738281 13.1786V14.3725C0.738281 15.0057 0.989848 15.613 1.43764 16.0608C1.88543 16.5087 2.49277 16.7602 3.12604 16.7602H13.8709C14.5042 16.7602 15.1115 16.5087 15.5593 16.0608C16.0071 15.613 16.2587 15.0057 16.2587 14.3725V13.1786" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5.51562 9L8.50032 11.9847L11.485 9" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.5 11.9847V1.23981" stroke="#1B2124" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={<Tooltip id='button-tooltip'>{t('delete')}</Tooltip>}
                    >
                      <span className='mx-1' onClick={() => removeFile(uploadedFile)}>
                        <svg width="14" height="15" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.2513 15.7083C1.2513 16.7625 2.1138 17.625 3.16797 17.625H10.8346C11.8888 17.625 12.7513 16.7625 12.7513 15.7083V4.20833H1.2513V15.7083ZM13.7096 1.33333H10.3555L9.39714 0.375H4.60547L3.64714 1.33333H0.292969V3.25H13.7096V1.33333Z" fill="#E03137" />
                        </svg>
                      </span>
                    </OverlayTrigger>

                    <span className={`${styleApply.fileContent} ${styleApply.fileType}`}>
                      {uploadedFile.File_Size}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>

      <Col xs={12}>
        <Alert show={!!alertMsg} variant={'danger'} className="d-flex justify-content-between align-items-center" transition={true}>
          {t("fileMaxSize")}

          <span className={style.dismiss} onClick={() => setAlertMsg(null)}>
            <svg height="311pt" viewBox="0 0 311 311.07733" width="311pt" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
              <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
            </svg>
          </span>
        </Alert>
      </Col>

      {isUploading && <LoadingOverlay />}
    </div>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './Footer.module.scss'
import axios from 'axios'
import { useLocation, useParams, NavLink } from 'react-router-dom'

const ContactUs = () => {

    const { t, i18n } = useTranslation('common')
    const [departmentData, setDepartmentData] = useState([])
    const [loading, setLoading] = useState(false)
    let { municipalId } = useParams()

    return (
        <>
            <Row>
                <Col>
                    <ul className={style.socialMedia}>

                        <li>
                            <a
                                target="_blank"
                                href={'https://twitter.com/srtipark'}
                            >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_119_657)">
                                            <path d="M17.7603 8.28039C17.6803 8.20039 17.6003 8.20039 17.5203 8.24039C17.4003 8.28039 17.2403 8.36039 17.0803 8.44039C17.2403 8.20039 17.3603 7.92039 17.4003 7.68039C17.4403 7.60039 17.4003 7.52039 17.3203 7.48039C17.2403 7.44039 17.1603 7.44039 17.0803 7.48039C16.6803 7.72039 16.1203 7.92039 15.9203 7.96039C15.4803 7.60039 14.9203 7.40039 14.4003 7.40039C13.7203 7.40039 13.0803 7.68039 12.5603 8.16039C12.0803 8.64039 11.8003 9.28039 11.8003 10.0004C9.8403 9.80039 8.6803 8.60039 7.7603 7.48039C7.7203 7.44039 7.6403 7.40039 7.6003 7.40039C7.5203 7.40039 7.4803 7.44039 7.4403 7.48039C6.8803 8.40039 7.0003 9.32039 7.1203 9.76039C7.2003 10.0804 7.3203 10.3204 7.4803 10.5604C7.2803 10.4804 7.1203 10.4004 6.9603 10.2404C6.9203 10.2004 6.8003 10.2004 6.7603 10.2004C6.6803 10.2404 6.6403 10.3204 6.6403 10.4004C6.6403 11.5604 7.4403 12.3204 8.0803 12.7204C7.9603 12.6804 7.8403 12.6804 7.6803 12.6004C7.6003 12.5604 7.5203 12.6004 7.4803 12.6404C7.4403 12.6804 7.4003 12.8004 7.4403 12.8404C7.8003 13.6804 8.6003 14.3204 9.4803 14.4804C8.6803 14.9604 7.4003 15.2404 6.4403 15.1604C6.3603 15.1604 6.2403 15.2004 6.2403 15.2804C6.2003 15.3604 6.2403 15.4804 6.3203 15.5204C7.5203 16.2004 8.8003 16.5604 10.0403 16.5604C12.0003 16.5604 13.9603 15.6804 15.3203 14.1204C16.5203 12.8004 17.1603 11.1204 17.0403 9.64039C17.2403 9.48039 17.3603 9.20039 17.5203 8.92039C17.6003 8.80039 17.6803 8.64039 17.7603 8.52039C17.8003 8.44039 17.8003 8.32039 17.7603 8.28039Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_119_657">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </a>
                        </li>

                        <li>
                            <a
                                target="_blank"
                                href={'https://www.facebook.com/sharjahrtipark'}
                            >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_119_653)">
                                            <path d="M13.6008 8H15.0008C15.1208 8 15.2008 7.92 15.2008 7.8V6.2C15.2008 6.08 15.1208 6 15.0008 6H13.4008C11.9208 6 10.8008 7.12 10.8008 8.6V10.4H9.00078C8.88078 10.4 8.80078 10.48 8.80078 10.6V12.2C8.80078 12.32 8.88078 12.4 9.00078 12.4H10.8008V17.8C10.8008 17.92 10.8808 18 11.0008 18H12.6008C12.7208 18 12.8008 17.92 12.8008 17.8V12.4H14.6008C14.6808 12.4 14.7608 12.32 14.8008 12.24L15.2008 10.64C15.2008 10.6 15.2008 10.52 15.1608 10.48C15.1208 10.44 15.0808 10.4 15.0008 10.4H12.8008V8.8C12.8008 8.36 13.1608 8 13.6008 8Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_119_653">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </a>
                        </li>

                        <li>
                            <a
                                target="_blank"
                                href={'https://ae.linkedin.com/company/srtipark'}
                            >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_119_661)">
                                            <g clip-path="url(#clip1_119_661)">
                                                <path d="M9.35031 16.4998H7.17344V9.48961H9.35031V16.4998ZM8.2607 8.53336C7.56461 8.53336 7 7.9568 7 7.2607C7 6.92634 7.13282 6.60568 7.36925 6.36925C7.60568 6.13282 7.92634 6 8.2607 6C8.59506 6 8.91573 6.13282 9.15215 6.36925C9.38858 6.60568 9.52141 6.92634 9.52141 7.2607C9.52141 7.9568 8.95656 8.53336 8.2607 8.53336ZM17.4977 16.4998H15.3255V13.0873C15.3255 12.274 15.3091 11.231 14.1937 11.231C13.0619 11.231 12.8884 12.1146 12.8884 13.0287V16.4998H10.7139V9.48961H12.8017V10.4459H12.8322C13.1228 9.89508 13.8327 9.31383 14.8919 9.31383C17.095 9.31383 17.5 10.7646 17.5 12.649V16.4998H17.4977Z" fill="white" />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_119_661">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_119_661">
                                                <rect width="10.5" height="12" fill="white" transform="translate(7 6)" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </span>
                            </a>
                        </li>

                        <li>
                            <a
                                target="_blank"
                                href={'https://www.instagram.com/shjrtipark'}
                            >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_119_665)">
                                            <g clip-path="url(#clip1_119_665)">
                                                <path d="M12.0027 9.77589C10.2992 9.77589 8.92506 11.15 8.92506 12.8536C8.92506 14.5571 10.2992 15.9312 12.0027 15.9312C13.7063 15.9312 15.0804 14.5571 15.0804 12.8536C15.0804 11.15 13.7063 9.77589 12.0027 9.77589ZM12.0027 14.8545C10.9018 14.8545 10.0018 13.9571 10.0018 12.8536C10.0018 11.75 10.8992 10.8527 12.0027 10.8527C13.1063 10.8527 14.0036 11.75 14.0036 12.8536C14.0036 13.9571 13.1036 14.8545 12.0027 14.8545ZM15.9242 9.65C15.9242 10.0491 15.6027 10.3679 15.2063 10.3679C14.8072 10.3679 14.4884 10.0464 14.4884 9.65C14.4884 9.25357 14.8099 8.93214 15.2063 8.93214C15.6027 8.93214 15.9242 9.25357 15.9242 9.65ZM17.9626 10.3786C17.917 9.41696 17.6974 8.56518 16.9929 7.86339C16.2911 7.16161 15.4393 6.94196 14.4777 6.89375C13.4867 6.8375 10.5161 6.8375 9.52506 6.89375C8.56613 6.93929 7.71434 7.15893 7.00988 7.86071C6.30541 8.5625 6.08845 9.41429 6.04023 10.3759C5.98398 11.367 5.98398 14.3375 6.04023 15.3286C6.08577 16.2902 6.30541 17.142 7.00988 17.8438C7.71434 18.5455 8.56345 18.7652 9.52506 18.8134C10.5161 18.8696 13.4867 18.8696 14.4777 18.8134C15.4393 18.7679 16.2911 18.5482 16.9929 17.8438C17.6947 17.142 17.9143 16.2902 17.9626 15.3286C18.0188 14.3375 18.0188 11.3696 17.9626 10.3786ZM16.6822 16.392C16.4733 16.917 16.0688 17.3214 15.5411 17.533C14.7509 17.8464 12.8759 17.7741 12.0027 17.7741C11.1295 17.7741 9.25184 17.8438 8.46434 17.533C7.93934 17.3241 7.53488 16.9196 7.32327 16.392C7.00988 15.6018 7.0822 13.7268 7.0822 12.8536C7.0822 11.9804 7.01256 10.1027 7.32327 9.31518C7.5322 8.79018 7.93666 8.38571 8.46434 8.17411C9.25452 7.86071 11.1295 7.93304 12.0027 7.93304C12.8759 7.93304 14.7536 7.86339 15.5411 8.17411C16.0661 8.38304 16.4706 8.7875 16.6822 9.31518C16.9956 10.1054 16.9233 11.9804 16.9233 12.8536C16.9233 13.7268 16.9956 15.6045 16.6822 16.392Z" fill="white" />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_119_665">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_119_665">
                                                <rect width="12" height="13.7143" fill="white" transform="translate(6 6)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </a>
                        </li>

                        {/* <li>
                            <a
                                target="_blank"
                            >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_119_669)">
                                            <g clip-path="url(#clip1_119_669)">
                                                <path d="M17.5009 10.9198C16.469 10.9222 15.4625 10.6004 14.6234 9.99984V14.1886C14.6232 14.9644 14.386 15.7216 13.9438 16.359C13.5015 16.9964 12.8752 17.4836 12.1486 17.7554C11.422 18.0273 10.6297 18.0708 9.87764 17.8802C9.12562 17.6896 8.44971 17.2739 7.9403 16.6888C7.43088 16.1037 7.11224 15.377 7.02699 14.6059C6.94173 13.8348 7.09391 13.056 7.4632 12.3737C7.83248 11.6915 8.40125 11.1382 9.09347 10.7879C9.78568 10.4376 10.5683 10.307 11.3368 10.4135V12.5203C10.9852 12.4097 10.6075 12.413 10.2579 12.5298C9.90827 12.6466 9.60448 12.8709 9.38991 13.1707C9.17535 13.4704 9.06099 13.8303 9.06316 14.1989C9.06533 14.5676 9.18392 14.9261 9.402 15.2233C9.62008 15.5205 9.9265 15.7412 10.2775 15.8538C10.6285 15.9665 11.0061 15.9654 11.3564 15.8507C11.7067 15.7359 12.0118 15.5134 12.2282 15.2149C12.4445 14.9164 12.5609 14.5572 12.5609 14.1886V6H14.6234C14.622 6.17417 14.6366 6.34811 14.667 6.51961C14.7387 6.90246 14.8877 7.26666 15.105 7.58994C15.3222 7.91322 15.6031 8.18878 15.9305 8.39977C16.3963 8.70776 16.9425 8.87191 17.5009 8.8718V10.9198Z" fill="white" />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_119_669">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                            <clipPath id="clip1_119_669">
                                                <rect width="10.5" height="12" fill="white" transform="translate(7 6)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </a>
                        </li> */}
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ContactUs

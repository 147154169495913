import { post } from "./base";
import qs from "qs";

export async function registerIndividualClient(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REGISTRATION/CLIENT_INDIVIDUAL_PROFILE_Registration"}`;
  const res = await post(url, param, cancelToken);
  return res;
}
export async function registerConsultativeClient(param, cancelToken) {
  const url = `${"/PORTAL/CLIENTS/REGISTRATION/CLIENT_CONSULTATIVE_PROFILE_Registration"}`;
  const res = await post(url, param, cancelToken);
  return res;
}

export async function registerContratorClient(param, cancelToken) {
  const url = `${"/PORTAL/CLIENTS/REGISTRATION/CLIENT_CONTRACTOR_PROFILE_Registration"}`;
  const res = await post(url, param, cancelToken);
  return res;
}
export async function registerGovClient(param, cancelToken) {
  const url = `${"/PORTAL/CLIENTS/REGISTRATION/ADD_CLIENT_GOVERNMENTENTITY_PROFILE"}`;
  const res = await post(url, param, cancelToken);
  return res;
}
export async function registerotherClients(param, cancelToken) {
  const url = `${"/PORTAL/CLIENTS/REGISTRATION/CLIENT_OTHERS_PROFILE_Registration"}`;
  const res = await post(url, param, cancelToken);
  return res;
}
export async function checkLoginName(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REGISTRATION/CHECK_LOGIN_NAME"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function checkPasswordPower(param, cancelToken) {
  const url = `${"PORTAL/CLIENTS/REGISTRATION/CHECK_PASSWORD_POWER"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}

import React, { lazy } from "react";
import { useLocation, Redirect } from "react-router-dom";
import RenderMachine from "components/UIComponents/StateMachine/RenderMachine";
import Header from "components/UIComponents/Header/Header";
import LoginForm from "features/user/LoginForm";
import login from "./styles.module.css";
import { useSelector } from "react-redux";
import InnerTitleCont from 'components/UIComponents/InnerTitleCont/InnterTitleState';

const LoginStates = [
  {
    name: "Login",
    Component: LoginForm,
    IsSuspended: false,
  },
  {
    name: "Register",
    Component: lazy(() => import("features/user/RegisterForm")),
    IsSuspended: true,
  },
  {
    name: "Forgot",
    Component: lazy(() => import("features/user/ForgotForm")),
    IsSuspended: true,
  },
  {
    name: "OTP",
    Component: lazy(() => import("features/user/OTPForm")),
    IsSuspended: true,
  },
];

const Login = () => {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);

  if (isAuthenticated) {
    return <Redirect to={location.state?.from?.pathname || "/dashboard"} />;
  }

  return (
    <div className={login.wrapper}>
      <Header />
      <InnerTitleCont
           leaveBtn={false}
          />
      <div className={login.container}>
        <LoginBox />
      </div>
    </div>
  );
};

export default Login;

const LoginBox = () => {
  return (
    <div>
      <RenderMachine states={LoginStates} machineInstance={"login.userMachine"} />
    </div>
  );
};
